import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { installerRegister } from "services/installerServices";
import {
  systemTierSchema,
  validationSchemaRegisterStep1,
  validationSchemaRegisterStep2,
} from "validation-schema/installer";
import {
  goBackStep,
  setFormStep1,
  setFormStep2,
  updateGeneralInformation,
} from "../../../redux/actions";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BusinessRegisterDetails from "./BusinessRegisterDetails";
import EquipmentListRegisterForm from "./EquipmentListRegisterForm";
import PersonalDetailsRegisterForm from "./PersonalDetailsRegisterForm";
import { useEffect, useState } from "react";

const InstallerRegisterForm = () => {
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false);
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.installerForm);
  const navigate = useNavigate();
  const [oldStepForm, setOldStepForm] = useState(formData.currentStep);

  const getValidationSchema = (currentStep) => {
    switch (currentStep) {
      case 0:
        return validationSchemaRegisterStep1;
      case 1:
        return validationSchemaRegisterStep2;
      case 2:
        return systemTierSchema;
      default:
        return null;
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    resolver: yupResolver(getValidationSchema(formData.currentStep)),
    defaultValues: formData,
    mode: "onChange",
  });

  useEffect(() => {
    setOldStepForm(formData.currentStep);
    // this logic to fix the disabled next button issue
    if (oldStepForm > formData.currentStep) {
      trigger();
    }
  }, [formData.currentStep]);

  const onSubmit = async (data) => {
    switch (formData.currentStep) {
      case 0:
        dispatch(setFormStep1(data));
        break;
      case 1:
        dispatch(setFormStep2(data));
        break;
      case 2:
        try {
          const res = await installerRegister({
            ...data,
            inverter: data.inverter.id,
            racking: data.racking.id,
            solar_panels: data.solar_panels.id,
            battery: data.battery.id,
          });
          toast.success(res.message);
          localStorage.setItem("token", res.token);
          if (res.token) {
            dispatch(updateGeneralInformation(res));
            localStorage.setItem("USER", "installer");
            window.location.href = "/dashboard-installer";
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
        break;
      default:
        break;
    }
  };
  const renderCurrentStep = () => {
    switch (formData.currentStep) {
      case 0:
        return (
          <PersonalDetailsRegisterForm
            control={control}
            errors={errors}
            watch={watch}
          />
        );
      case 1:
        return <BusinessRegisterDetails control={control} errors={errors} />;
      case 2:
        return (
          <EquipmentListRegisterForm
            control={control}
            errors={errors}
            watch={watch}
            reset={reset}
            isTermsAgreed={isTermsAgreed}
            isPrivacyAgreed={isPrivacyAgreed}
            setIsPrivacyAgreed={setIsPrivacyAgreed}
            setIsTermsAgreed={setIsTermsAgreed}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      {renderCurrentStep()}
      <div className="flex flex-col-reverse md:flex-row justify-start gap-x-3 gap-y-3 py-6">
        {formData.currentStep > 0 && (
          <button
            onClick={() => {
              dispatch(goBackStep());
            }}
            className="py-3 px-9 border-1 border-[#112532] rounded font-semibold flex-grow md:flex-grow-0"
          >
            Back
          </button>
        )}
        <button
          onClick={handleSubmit(onSubmit)}
          className={`py-3 px-10 text-[white] bg-[#112532] rounded font-semibold flex-grow md:flex-grow-0 ${!isValid || (!isPrivacyAgreed && formData.currentStep === 2) || (!isTermsAgreed && formData.currentStep === 2) ? "opacity-50" : ""}`}
          disabled={
            !isValid ||
            (!isPrivacyAgreed && formData.currentStep === 2) ||
            (!isTermsAgreed && formData.currentStep === 2)
          }
        >
          {formData.currentStep === 2 ? "Join Spark" : "Next"}
        </button>
      </div>
    </>
  );
};

export default InstallerRegisterForm;
