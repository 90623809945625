import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { handleKeyDown } from "utils/formHelpers";
import { USAstates } from "utils/constant";
import InstallerTermsAndConditions from "components/installer/InstallerTermsAndConditions";
import InstallerPrivacyPolicy from "components/installer/InstallerPrivacyPolicy";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const PartnersRegisterForm = () => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false);
  const [showPassword, setShowPassword] = useState({
    new: false,
    confirm: false,
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    // resolver: yupResolver(getValidationSchema(formData.currentStep)),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
      business_name: "",
      mobile: "",
      country: "United States of America (USA)",
      state: "",
      city: "",
      post_code: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    console.log(data);
  };

  return (
    <div className="p-[120px] h-screen overflow-y-auto scrollbar-hide">
      <InstallerTermsAndConditions
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        onAgree={() => setIsTermsAgreed(true)}
      />
      <InstallerPrivacyPolicy
        isOpen={isPrivacyModalOpen}
        onClose={() => setIsPrivacyModalOpen(false)}
        onAgree={() => setIsPrivacyAgreed(true)}
      />
      <Link to={"/auth/partners-login"}>
        <h1 className="flex gap-x-2 items-center text-[#42474c] font-normal mb-6">
          <img src="/icons/chevron_left.svg" alt="" /> Sign In
        </h1>
      </Link>
      <h2 className="text-[#244255] text-[28px] font-semibold mb-10">
        Join Spark <span className="text-[#f88f08]">Partners</span>
      </h2>
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col md:flex-row gap-4">
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  height: "fit-content",
                }}
                {...field}
                error={errors.first_name && true}
                helperText={errors.first_name && errors.first_name.message}
                label="First Name"
                variant="outlined"
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  height: "fit-content",
                }}
                {...field}
                error={errors.last_name && true}
                helperText={errors.last_name && errors.last_name.message}
                label="Last Name"
                variant="outlined"
              />
            )}
          />
        </div>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              sx={{
                borderRadius: "8px",
                backgroundColor: "white",
              }}
              {...field}
              error={errors.email && true}
              helperText={errors.email && errors.email.message}
              label="Email"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              error={errors.password && true}
              {...field}
              helperText={
                errors?.password?.type === "required" && errors.password.message
              }
              sx={{
                borderRadius: "8px",
                backgroundColor: "white",
              }}
              className="w-full"
              label="Password"
              type={showPassword.new ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword.new ? (
                      <VisibilityOffIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setShowPassword({
                            ...showPassword,
                            new: false,
                          });
                        }}
                      />
                    ) : (
                      <VisibilityIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setShowPassword({ ...showPassword, new: true });
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <TextField
              error={errors.confirmPassword && true}
              {...field}
              helperText={
                errors.confirmPassword && errors.confirmPassword.message
              }
              sx={{
                borderRadius: "8px",
                backgroundColor: "white",
              }}
              className="w-full"
              label="Confirm Password"
              type={showPassword.confirm ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword.confirm ? (
                      <VisibilityOffIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setShowPassword({
                            ...showPassword,
                            confirm: false,
                          });
                        }}
                      />
                    ) : (
                      <VisibilityIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setShowPassword({
                            ...showPassword,
                            confirm: true,
                          });
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <h2 className="text-xl md:text-[23px] font-semibold">
          Business Details
        </h2>
        <Controller
          name="business_name"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                height: "fit-content",
              }}
              {...field}
              error={errors.business_name && true}
              helperText={errors.business_name && errors.business_name.message}
              label="Business Name"
              variant="outlined"
            />
          )}
        />

        <Controller
          name="mobile"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.mobile}
              helperText={errors.mobile && errors.mobile.message}
              onKeyDown={handleKeyDown}
              onWheel={(e) => e.target.blur()}
              type="number"
              id="outlined-basic"
              label="Phone Number"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <div className="pl-2 pr-3 mr-[10px] border-r-[1px] border-[#C7CACE]">
                    +1
                  </div>
                ),
              }}
            />
          )}
        />
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <TextField
              disabled
              {...field}
              error={!!errors.country}
              helperText={errors.country && errors.country.message}
              id="outlined-basic"
              label="Country"
              variant="outlined"
              value={"United States of America (USA)"}
              sx={{ backgroundColor: "#E0E4EA", borderRadius: "10px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img loading="lazy" src="/icons/search.svg" alt="" />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          name="state"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              fullWidth
              value={value}
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              id="controllable-states-demo"
              options={USAstates}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: "flip",
                      enabled: false,
                    },
                  ],
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="State"
                  error={!!errors.state}
                  helperText={errors.state && errors.state.message}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img loading="lazy" src="/icons/search.svg" alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}
        />

        <div className="flex gap-x-3">
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.city}
                helperText={errors.city && errors.city.message}
                id="outlined-basic"
                label="City"
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Controller
            name="post_code"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.post_code}
                helperText={errors.post_code && errors.post_code.message}
                onKeyDown={(e) => {
                  if (
                    !/[0-9]/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight" &&
                    e.key !== "Delete" &&
                    e.key !== "Tab"
                  ) {
                    e.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 9,
                }}
                type="text"
                id="outlined-basic"
                label="Post Code"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <FormGroup>
          <div className="flex items-center mt-6">
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <Checkbox
                  onClick={(e) => {
                    e.preventDefault();
                    setIsTermsModalOpen(true);
                  }}
                  checked={isTermsAgreed}
                />
              }
              label="I Agree to"
            />
            &nbsp;
            <p
              className="text-[#287192] underline cursor-pointer"
              onClick={() => setIsTermsModalOpen(true)}
            >
              Terms and Conditions
            </p>
          </div>
          <div className="flex items-center">
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <Checkbox
                  onClick={(e) => {
                    e.preventDefault();
                    setIsPrivacyModalOpen(true);
                  }}
                  checked={isPrivacyAgreed}
                />
              }
              label="I Agree to"
            />
            &nbsp;
            <p
              className="text-[#287192] underline cursor-pointer"
              onClick={() => setIsPrivacyModalOpen(true)}
            >
              Privacy Policy
            </p>
          </div>
        </FormGroup>
        <button
          onClick={handleSubmit(onSubmit)}
          className={`py-3 px-10 text-[white] bg-[#112532] rounded font-semibold flex-grow md:flex-grow-0`}
          disabled={!isValid || !isPrivacyAgreed || !isTermsAgreed}
        >
          Create Account
        </button>
      </div>
    </div>
  );
};

export default PartnersRegisterForm;
