// Chat.jsx
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import ReactDropZone from "./ReactDropZone";
import useResponsive from "hooks/useResponsive";
import { setChatList } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import { POSThomeOwnerChatAttachments } from "../../services/homeOwnerServices";
import { POSTinstallerChatAttachments } from "../../services/installerServices";
import GalleryCarousel from "./GalleryCarousel";

// Constants
const MAX_SIZE_MB = 100; // 100 MB limit
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; // Convert MB to bytes

const MessageAttachment = ({ attachments }) => {
  const [indexToStart, setIndexToStart] = useState(null);

  const onCloseAfter = () => setIndexToStart(null);

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "application/pdf":
        return "/icons/PDF.svg";
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "/icons/Doc.svg";
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "/icons/XLS.svg";
      default:
        return "/icons/Doc.svg";
    }
  };

  const videos = attachments.filter((att) =>
    att.file_type.startsWith("video/")
  );
  const images = attachments.filter((att) =>
    att.file_type.startsWith("image/")
  );
  const files = attachments.filter(
    (att) =>
      !att.file_type.startsWith("image/") && !att.file_type.startsWith("video/")
  );

  console.log(attachments);

  return (
    <div className="flex flex-col gap-2">
      {/* WhatsApp-style Grid Layout */}
      <GalleryCarousel
        onCloseAfter={onCloseAfter}
        indexToStart={indexToStart}
        imageData={images}
        variant="chat"
        isRight={false}
      />
      {(videos.length > 0 || images.length > 0) && (
        <div
          className="grid gap-1 rounded-lg overflow-hidden"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "auto",
          }}
        >
          {[...videos, ...images].slice(0, 4).map((attachment, index) => (
            <div
              key={attachment.id}
              className={`relative overflow-hidden cursor-pointer ${
                videos.length + images.length === 1
                  ? "col-span-2 row-span-2"
                  : videos.length + images.length === 3 && index === 2
                    ? "col-span-2"
                    : ""
              }`}
              style={{
                height: videos.length + images.length === 1 ? "200px" : "100px",
              }}
            >
              {attachment.file_type.startsWith("video/") ? (
                <video
                  src={attachment.file_url}
                  className="w-full h-full object-cover rounded-lg"
                  controls
                />
              ) : (
                <img
                  src={attachment.file_url}
                  alt={attachment.file_name}
                  className="w-full h-full object-cover rounded-lg"
                  loading="lazy"
                  onClick={() => setIndexToStart(index)}
                />
              )}

              {index === 3 && videos.length + images.length > 4 && (
                <div
                  className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white text-lg font-semibold"
                  onClick={() => setIndexToStart(index)}
                >
                  +{videos.length + images.length - 4}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {/* File Attachments (Shown at the Bottom) */}
      {files.length > 0 && (
        <div className="mt-2 ">
          {files.map((attachment) => (
            <a
              key={attachment.id}
              href={attachment.file_url}
              target="_blank"
              rel="noopener noreferrer"
              className="block mb-2"
            >
              <div className="max-w-full max-h-16 flex items-center gap-x-3 bg-white px-4 py-3 rounded-lg hover:bg-gray-50 transition-colors">
                <img
                  src={getFileIcon(attachment.file_type)}
                  alt=""
                  className="w-8 h-8"
                  loading="lazy"
                />
                <div className="overflow-hidden">
                  <p className="text-[#112532] text-sm font-medium truncate">
                    {attachment.file_name}
                  </p>
                  <p className="text-[#64748B] text-xs">
                    {(attachment.file_size / 1024).toFixed(0)} KB
                  </p>
                </div>
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

// ChatMessage Component
const ChatMessage = ({
  message,
  userImage,
  isCurrentUser,
  isLastMessage,
  isSendingAttachment,
}) => {
  const messageTime = dayjs(message.createdAt).format("h:mm A");
  const messageAlignment = isCurrentUser ? "items-end" : "items-start";
  const messageBg = isCurrentUser ? "bg-[#FCF4E2]" : "bg-[#F7F6F5]";

  return (
    <div className={`flex flex-col gap-y-2 ${messageAlignment} w-full`}>
      <span className="text-[10px] text-[#94847B]">{messageTime}</span>

      <div className={`flex items-start gap-x-4 max-w-[95%] md:max-w-[50%]`}>
        {!isCurrentUser && (
          <img
            src={userImage}
            alt="User Avatar"
            className="w-8 h-8 rounded-full"
            loading="lazy"
          />
        )}
        <div
          className={`flex flex-col p-4 rounded-md max-w-full ${messageBg} ${isLastMessage && isSendingAttachment && "opacity-70"}`}
        >
          {/* Render attachments using the updated component */}
          {message.ChatAttachments?.length > 0 && (
            <MessageAttachment attachments={message.ChatAttachments} />
          )}

          {message.message && (
            <p className="text-[#112532] break-words whitespace-pre-wrap mt-2">
              {message.message}
            </p>
          )}
        </div>
      </div>

      {isLastMessage && isSendingAttachment && (
        <div className="flex items-center gap-x-3">
          <CircularProgress size="15px" sx={{ color: "#F59E0B" }} />{" "}
          <span className=" text-xs font-normal">Sending....</span>
        </div>
      )}

      {isCurrentUser && message.seenby_installer && (
        <span className="text-[10px] text-[#94847B]">seen</span>
      )}
    </div>
  );
};

// FilePreview Component
const renderFileDesign = (file) => {
  const renderDocumentDesign = (iconSrc, fileName, fileSizeKB) => (
    <div className="w-72 max-h-16 flex items-center gap-x-3 bg-white px-4 py-3 rounded-lg">
      <img loading="lazy" src={iconSrc} alt="" className="w-8 h-8" />
      <div className="overflow-hidden">
        <p className="text-[#112532] text-[14px] font-medium truncate">
          {fileName}
        </p>
        <p className="text-[#64748B] text-[12px]">{fileSizeKB} KB</p>
      </div>
    </div>
  );

  const fileSizeKB = (file.size / 1024).toFixed(0);

  if (file.type.startsWith("image/")) {
    return (
      <div className="w-16 h-16 ">
        <img
          loading="lazy"
          className="w-full h-full object-cover rounded-lg truncate"
          src={URL.createObjectURL(file)}
          alt={file.name}
        />
      </div>
    );
  } else if (file.type.startsWith("video/")) {
    return (
      <div className="w-16 h-16 relative">
        <video
          className="w-full h-full object-cover rounded-lg"
          src={URL.createObjectURL(file)}
        />
        <img
          loading="lazy"
          className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
          src="/icons/videoPlay.svg"
          alt=""
        />
      </div>
    );
  } else if (file.type === "application/pdf") {
    return renderDocumentDesign("/icons/PDF.svg", file.name, fileSizeKB);
  } else if (
    file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    file.type === "application/msword"
  ) {
    return renderDocumentDesign("/icons/Doc.svg", file.name, fileSizeKB);
  } else if (
    file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    file.type === "application/vnd.ms-excel"
  ) {
    return renderDocumentDesign("/icons/XLS.svg", file.name, fileSizeKB);
  }
};

// Main Chat Component
const Chat = ({ chatInfo, setChatInfo }) => {
  // States
  const [filesArray, setFilesArray] = useState([]);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [isSendingAttachment, setIsSendingAttachment] = useState(false);

  // Hooks
  const { isSm } = useResponsive();
  const generalData = useSelector((state) => state.generalInformation);
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("USER");
  const token = localStorage.getItem("token");
  const typingTimeout = useRef(null);
  const chatRef = useRef(null);
  const isValid = message.length > 0 || filesArray.length > 0;

  // Scroll arrows visibility effect
  useEffect(() => {
    const container = document.getElementById("scrollable-container");

    const updateArrowsVisibility = () => {
      if (container) {
        setCanScrollLeft(container.scrollLeft > 0);
        setCanScrollRight(
          container.scrollWidth > container.clientWidth &&
            container.scrollLeft + container.clientWidth < container.scrollWidth
        );
      }
    };

    updateArrowsVisibility();
    if (container) {
      container.addEventListener("scroll", updateArrowsVisibility);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", updateArrowsVisibility);
      }
    };
  }, [filesArray]);

  // Socket connection effect
  useEffect(() => {
    const role = userRole;
    const newSocket = io(config.socketUrl, {
      query: { token, role },
      reconnection: true,
    });
    setSocket(newSocket);

    newSocket.on("typing", ({ sender, typing }) => {
      setIsTyping(typing);
    });

    newSocket.emit("openChat", chatInfo.chatId, chatInfo.receiverId, {
      limit: 50,
      offset: 0,
    });

    newSocket.on("existingMessages", (existingMessages) => {
      setMessages(existingMessages);
    });

    newSocket.on("receiveMessage", (message) => {
      if (message.chat_id === chatInfo.chatId) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
      newSocket.emit("updateChatList", generalData.id, userRole);
    });

    newSocket.on("chatListUpdate", (chatList) => {
      dispatch(setChatList(chatList));
    });

    return () => {
      newSocket.disconnect();
    };
  }, [chatInfo]);

  // Scroll to bottom effect
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages, isTyping]);

  // Message handlers
  const handleRemoveFile = (index) => {
    setFilesArray((prev) => prev.filter((_, i) => i !== index));
  };
  const handleChange = (e) => {
    const message = e.target.value;
    socket.emit("typing", chatInfo.chatId, chatInfo.receiverId);
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      socket.emit("stopTyping", chatInfo.chatId, chatInfo.receiverId);
    }, 300);
    setMessage(message);
  };
  const calculateMessageSize = () => {
    const textSize = new Blob([message]).size;
    const filesSize = filesArray.reduce((total, file) => total + file.size, 0);
    return textSize + filesSize;
  };

  const handleSend = async () => {
    const totalSize = calculateMessageSize();
    if (totalSize > MAX_SIZE_BYTES) {
      setErrorMessage(
        `Message exceeds the 100MB limit. Current size: ${(totalSize / (1024 * 1024)).toFixed(2)} MB`
      );
      return;
    }

    setErrorMessage("");

    if (socket) {
      const newMessage = {
        sender: userRole,
        message: message,
        ChatAttachments: filesArray.map((file) => {
          return {
            id: Math.random().toString(36).substring(2, 21),
            file_name: file.name,
            file_url: URL.createObjectURL(file),
            file_type: file.type,
            file_size: file.size,
            sender: userRole,
            sender_id: generalData.id,
            createdAt: dayjs().toISOString(),
            updatedAt: dayjs().toISOString(),
          };
        }),
        createdAt: dayjs().toISOString(),
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);

      let attachment_uuid;

      if (filesArray?.length > 0) {
        const formData = new FormData();
        formData.append("token", token);
        filesArray.forEach((file) => {
          formData.append("files", file);
        });
        formData.append("chatID", chatInfo?.chatId);
        try {
          setIsSendingAttachment(true);
          if (userRole === "homeOwner") {
            const res = await POSThomeOwnerChatAttachments(formData);
            attachment_uuid = res?.attachment_uuid;
          } else {
            const res = await POSTinstallerChatAttachments(formData);

            attachment_uuid = res?.attachment_uuid;
          }
        } catch (error) {
          console.error("Error sending message:", error);
        } finally {
          setIsSendingAttachment(false);
        }
      }

      socket.emit("sendMessage", {
        message,
        chatId: chatInfo.chatId,
        receiverId: chatInfo.receiverId,
        attachment_uuid: attachment_uuid || "",
      });

      socket.emit("updateChatList", generalData.id, userRole);
      setMessage("");
      setFilesArray([]);
    }
  };

  return (
    <div className="flex flex-col gap-y-8 h-dvh md:h-full md:border border-[#D9D3CF] p-5 md:rounded-[10px] md:shadow">
      {/* Header */}
      <div
        className={`flex items-center gap-x-2 ${
          isSm
            ? "fixed top-0 bg-white w-full py-4 h-[70px] z-50"
            : "relative pb-4"
        }`}
      >
        {isSm && (
          <img
            className="cursor-pointer"
            src="/icons/arrow_back.svg"
            alt=""
            onClick={() => setChatInfo(null)}
          />
        )}
        <img
          loading="lazy"
          className="w-10 h-10 rounded-full"
          src={chatInfo.userData.image}
          alt=""
        />
        <h1 className="text-[23px] font-bold">{chatInfo.userName}</h1>
        <hr className="w-[calc(100%+40px)] absolute bottom-[0px] left-[-20px] border border-[#D9D3CF]" />
      </div>

      {/* Messages Container */}
      <div
        className="flex flex-col gap-y-10 h-full overflow-y-auto w-full p-5 scrollbar-hide"
        id="chat"
        ref={chatRef}
      >
        {messages.map((msg, index) => (
          <ChatMessage
            key={index}
            message={msg}
            userImage={chatInfo.userData.image}
            isCurrentUser={msg.sender === userRole}
            isLastMessage={index === messages?.length - 1}
            isSendingAttachment={isSendingAttachment}
          />
        ))}
      </div>

      {/* Typing Indicator */}
      {isTyping && (
        <div className="flex items-center gap-x-2 ml-12 my-2">
          <div className="flex space-x-1">
            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-100"></div>
            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></div>
          </div>
        </div>
      )}

      {/* Hidden DropZone */}
      <div className="hidden">
        <ReactDropZone
          isInputOpen={isInputOpen}
          setFilesArray={setFilesArray}
          setIsInputOpen={setIsInputOpen}
          variant="chat"
        />
      </div>

      {/* Input Area */}
      <div className="mt-auto w-full p-3 bg-[#F7F6F5] rounded-lg">
        {filesArray.length > 0 && (
          <div className="relative w-full mt-auto">
            {canScrollLeft && (
              <button
                className="absolute left-0 top-1/2 translate-y-[-50%] z-10"
                onClick={() => {
                  const container = document.getElementById(
                    "scrollable-container"
                  );
                  container.scrollBy({ left: -150, behavior: "smooth" });
                }}
              >
                <img
                  loading="lazy"
                  className="rotate-180"
                  src="/icons/rightCarousel.svg"
                  alt=""
                />
              </button>
            )}
            {canScrollRight && (
              <button
                className="absolute right-0 top-1/2 translate-y-[-50%] z-10"
                onClick={() => {
                  const container = document.getElementById(
                    "scrollable-container"
                  );
                  container.scrollBy({ left: 150, behavior: "smooth" });
                }}
              >
                <img loading="lazy" src="/icons/rightCarousel.svg" alt="" />
              </button>
            )}

            <div
              id="scrollable-container"
              className="flex gap-4 overflow-x-auto py-2 scrollbar-hide"
            >
              {filesArray.map((file, index) => (
                <div
                  key={index}
                  className="relative flex-shrink-0 group max-h-16 rounded-lg mb-2"
                >
                  {renderFileDesign(file)}
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="opacity-0 group-hover:opacity-100"
                  >
                    <img
                      loading="lazy"
                      src="/icons/xIcon.svg"
                      className="w-5 h-5 absolute top-[-5px] right-[-5px]"
                    />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Error Message */}
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}

        {/* Text Input */}
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Write something..."
          value={message}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && isValid) {
              handleSend();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <img
                  loading="lazy"
                  className="cursor-pointer mr-5"
                  src="/icons/attach_file.svg"
                  alt="attach file icon"
                  onClick={() => setIsInputOpen((state) => !state)}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" disablePointerEvents={!isValid}>
                <img
                  loading="lazy"
                  className="cursor-pointer"
                  src={isValid ? "/icons/ActiveSend.svg" : "/icons/Send.svg"}
                  alt="send icon"
                  onClick={handleSend}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              border: "none",
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
            "& .MuiOutlinedInput-input": {
              outline: "none",
              boxShadow: "none",
            },
          }}
        />
      </div>
    </div>
  );
};

export default Chat;
