import React, { useEffect, useState } from "react";
import Header from "components/shared/Header";
import BreadCrumb from "components/shared/BreadCrumb";
import { toast } from "react-toastify";
import { Rating, TextField } from "@mui/material";
import GalleryCarousel from "components/shared/GalleryCarousel";
import { useSelector } from "react-redux";
import ConfirmationDialog from "components/shared/DeleteConfirmationDialog";
import InstallerPortfolioSkeleton from "components/skeleton/shared/InstallerPortfolioSkeleton";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Comment from "components/shared/Comment";
import {
  DELETEportfolioImageById,
  getPortfolioList,
} from "services/installerServices";
import {
  DELETEcomment,
  GETinstallerProfile,
  POSTcomment,
  PUTcomment,
} from "services/homeOwnerServices";

const InstallerProfileShared = () => {
  const dashboardSidebar = useOutletContext();
  const isInstaller = localStorage.getItem("USER") === "installer";
  const [portfolioData, setPortfolioData] = useState({});
  const [indexToStart, setIndexToStart] = useState(null);
  const [review, setReview] = useState({ comment: "", stars: null });
  const [showInput, setShowInput] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [visibleReviews, setVisibleReviews] = useState(10);

  const navigate = useNavigate();

  const generalData = useSelector((state) => state.generalInformation);

  const deleteComment = async () => {
    try {
      const res = await DELETEcomment();
      toast.success(res.message);
    } catch (error) {
      toast.error(error.data.message);
    } finally {
      fetchData();
    }
  };

  const handleRemoveImage = async (id) => {
    try {
      const res = await DELETEportfolioImageById(id);
      toast.success(res.message);
      fetchData();
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  const postNewComment = async (review) => {
    try {
      const res = isEditing
        ? await PUTcomment(review)
        : await POSTcomment(review);
      if (res) toast.success("Review submitted successfully");
    } catch (error) {
      toast.error(error.data.message);
    } finally {
      fetchData();
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = isInstaller
        ? await getPortfolioList()
        : await GETinstallerProfile();
      setPortfolioData(res);
      if (!isInstaller) {
        setShowInput(!!!res?.data?.userReviews[0]);
        setReview({
          comment: res?.data?.userReviews[0]?.comment,
          stars: res?.data?.userReviews[0]?.stars,
        });
      }
    } catch (error) {
      toast.error(error.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseAfter = () => setIndexToStart(null);

  const handleViewMore = () => {
    setVisibleReviews((prev) => prev + 10);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const installerImages = isInstaller
    ? portfolioData?.portfolio_images
    : portfolioData?.installerPortfolio;

  const reviewData = isInstaller
    ? portfolioData?.reviewSummary
    : portfolioData?.data?.reviewSummary;

  const reviews = isInstaller
    ? portfolioData?.InstallerReviews
    : portfolioData?.data?.otherReviews;

  const userReview = !isInstaller && portfolioData?.data?.userReviews[0];

  if (isLoading) {
    return (
      <>
        {!isInstaller && <Header />}
        <div className="flex-1">
          <InstallerPortfolioSkeleton />
        </div>
      </>
    );
  }

  return (
    <>
      <ConfirmationDialog
        open={isDialogOpen || !!imageToDelete}
        onClose={() => {
          setIsDialogOpen(false);
          setIsEditing(false);
          setShowInput(false);
          setImageToDelete(null);
        }}
        onConfirm={() => {
          if (imageToDelete) {
            handleRemoveImage(imageToDelete);
            setImageToDelete(null);
          } else {
            setIsDialogOpen(false);
            deleteComment();
            setReview({ comment: "", stars: null });
            setIsEditing(false);
            setShowInput(false);
          }
        }}
        title={imageToDelete ? "Delete Image" : "Delete Review?"}
        subTitle={
          imageToDelete
            ? "Are you sure you want to delete this image?"
            : "are you sure you want to delete this review?"
        }
        cancelText="Cancel"
        confirmText="Delete"
      />
      {!isInstaller && <Header />}
      {isInstaller && (
        <div className="flex items-center gap-x-2 p-6">
          <div className="lg:hidden">{dashboardSidebar}</div>
          <h1 className="text-[23px] md:text-[28px] font-semibold py-3">
            My Portfolio
          </h1>
        </div>
      )}
      <div className="flex-1 p-4 md:p-6">
        {!isInstaller && (
          <BreadCrumb
            paths={
              isInstaller
                ? [
                    { name: "Dashboard", pathLink: "/dashboard-installer" },
                    {
                      name: "My Portfolio",
                      pathLink: "/dashboard-installer/portfolio",
                    },
                  ]
                : [
                    { name: "Home", pathLink: "/dashboard" },
                    {
                      name: "Installer Details",
                      pathLink: `/dashboard/installer-profile`,
                    },
                  ]
            }
            isShowConfirmationDialog={isEditing}
            onConfirm={() => {
              navigate("/dashboard");
            }}
            title="Return to Dashboard?"
            subTitle={
              "Some changes have not been saved. Do you wish to proceed?"
            }
            confirmText="Yes"
            cancelText="No"
          />
        )}
        <div className="flex flex-col gap-y-4">
          {/* Profile Header */}
          <div className="px-4 md:px-8 py-4 md:py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
            <div className="flex items-center gap-4">
              <img
                loading="lazy"
                className="w-12 h-12 md:w-16 md:h-16 rounded-full"
                src={
                  isInstaller
                    ? generalData.logo
                    : portfolioData?.allOffersDeals?.Installer?.logo ||
                      "/images/defaultLogo.svg"
                }
                alt=""
              />
              <h1 className="text-[#112532] text-xl md:text-[33px] font-semibold">
                {isInstaller
                  ? generalData.business_name
                  : portfolioData?.allOffersDeals?.Installer.business_name}
              </h1>
            </div>

            <div className="flex flex-col gap-y-4 mt-4 md:ml-20 md:mt-0">
              <div className="flex items-center gap-x-2">
                {reviewData?.totalReviews === 0 ? (
                  <div className="flex gap-x-2 items-center">
                    <img src="/icons//off-star.svg" alt="" />
                    <h1 className="text-[#112532] text-lg md:text-[23px]">
                      Not Rated
                    </h1>
                  </div>
                ) : (
                  <>
                    <h1 className="text-[#112532] text-xl md:text-[28px] font-bold">
                      {reviewData?.averageRating}
                      <span className="text-lg md:text-[23px] font-semibold">
                        /
                      </span>
                      <span className="text-base md:text-[20px] font-normal">
                        5
                      </span>
                    </h1>
                    <img loading="lazy" src="/icons/star.svg" alt="star icon" />
                  </>
                )}
                <span className="text-[#5e514e] text-sm md:text-base">
                  ({reviewData?.totalReviews} Reviews)
                </span>
              </div>
              <div className="flex flex-wrap gap-2 md:gap-x-[10px]">
                {portfolioData.installerTags?.map((tag) => (
                  <span
                    key={tag.id}
                    className="px-3 md:px-4 py-1 bg-[#F0FAFB] border-1 border-[#D8F0F5] font-semibold text-sm md:text-base"
                  >
                    {tag.tag}
                  </span>
                ))}
                {/* {!isInstaller && (
                  <>
                    <span className="px-3 md:px-4 py-1 bg-[#F0FAFB] border-1 border-[#D8F0F5] font-semibold text-sm md:text-base">
                      Fast installation
                    </span>
                    <span className="px-3 md:px-4 py-1 bg-[#F0FAFB] border-1 border-[#D8F0F5] font-semibold text-sm md:text-base">
                      Excellent Warranty
                    </span>
                  </>
                )} */}
              </div>
            </div>
          </div>
          {/* Projects Section */}
          <div className="px-4 md:px-8 py-4 md:py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
            <div className="flex justify-between gap-4 md:gap-0 mb-2">
              <h2 className="text-[#112532] text-xl md:text-[23px] font-semibold">
                Projects
              </h2>
              {isInstaller && installerImages?.length > 0 && (
                <Link
                  to="/dashboard-installer/portfolio/add-image"
                  className="flex gap-x-2 px-4 py-2 bg-[#112532] text-[#FFFEFB] text-[14px] md:text-[16px] font-bold items-center rounded  justify-center md:justify-start"
                >
                  <img loading="lazy" src="/icons/plus.svg" alt="plus icon" />{" "}
                  Add Image
                </Link>
              )}
            </div>

            {installerImages?.length > 0 && (
              <GalleryCarousel
                onCloseAfter={onCloseAfter}
                indexToStart={indexToStart}
                imageData={installerImages}
                variant="portfolio"
                deleteProjectById={isInstaller ? handleRemoveImage : undefined}
                isDelete={isInstaller}
                isRight={!isInstaller}
              />
            )}

            {installerImages?.length > 0 ? (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-5">
                {installerImages.map((project, index) => (
                  <div key={project.id} className="group relative">
                    <img
                      loading="lazy"
                      src={project.img}
                      alt=""
                      className="h-48 md:h-64 w-full object-contain"
                    />
                    <div className="p-1 w-8 h-8 flex md:hidden group-hover:flex flex-col gap-y-2 justify-center items-center absolute right-0 top-[20px]">
                      <img
                        loading="lazy"
                        onClick={() => setIndexToStart(index)}
                        src="/icons/full-screen.svg"
                        className="w-full h-full cursor-pointer"
                        alt="icon"
                      />
                      {isInstaller && (
                        <button
                          className="w-8 h-8 justify-center items-center bg-[#ffffffd2] text-white"
                          onClick={() => setImageToDelete(project.id)}
                        >
                          <img
                            loading="lazy"
                            className="w-full"
                            src="/icons/trash.svg"
                            alt=""
                          />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className=" w-full flex flex-col items-center gap-y-5 py-4 md:py-6 px-4 md:px-8">
                <div className="w-full flex flex-col items-center gap-y-4">
                  <img
                    className="w-12 h-12 md:w-14 md:h-14"
                    src="/icons/gallery-library.svg"
                    alt=""
                  />
                  <h2 className="text-[#112532] text-lg md:text-[19px] font-semibold text-center">
                    No projects yet!
                  </h2>
                  {isInstaller && (
                    <p className="text-sm md:text-base text-center">
                      You can add up to 15 images.
                    </p>
                  )}
                </div>
                {isInstaller && (
                  <Link
                    to="/dashboard-installer/portfolio/add-image"
                    className="w-full md:w-fit flex gap-x-2 px-4 py-2 bg-[#112532] text-[#FFFEFB] text-[14px] md:text-[16px] font-bold items-center rounded justify-center"
                  >
                    <img loading="lazy" src="/icons/plus.svg" alt="plus icon" />{" "}
                    Add Image
                  </Link>
                )}
              </div>
            )}
          </div>

          {/* Reviews Section */}
          <div className="px-4 md:px-8 py-4 md:py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
            <div className="flex gap-x-4 md:gap-x-20 gap-y-6">
              <div className="flex flex-col justify-evenly items-start md:items-center gap-y-4">
                <h1 className="text-[#112532] text-xl md:text-[23px] font-semibold">
                  Reviews
                </h1>
                {reviewData?.totalReviews === 0 ? (
                  <div className="flex items-center gap-x-2">
                    <h1 className="text-[#112532] text-lg md:text-xl">
                      Not Rated
                    </h1>
                    <img
                      className="h-6 md:h-8"
                      src="/icons//off-star.svg"
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="flex gap-x-1">
                    <span className="text-2xl md:text-[39px] font-bold text-[#112532]">
                      {reviewData?.averageRating}
                    </span>
                    <img
                      loading="lazy"
                      className="w-6 md:w-9"
                      src="/icons/star.svg"
                      alt=""
                    />
                  </div>
                )}
                <span className="text-[#5e514e] text-sm md:text-base">
                  ({reviewData?.totalReviews} Reviews)
                </span>
              </div>
              <div className="w-full md:w-auto space-y-2">
                {[5, 4, 3, 2, 1].map((rating) => (
                  <div key={rating} className="flex items-center">
                    <span className="text-gray-600 w-6 md:w-8 text-sm md:text-base">
                      {rating}
                    </span>
                    <div className="w-full md:w-60 h-1 bg-gray-300 relative rounded">
                      <span
                        className="block absolute h-full bg-[#F3B33E] z-10 rounded"
                        style={{
                          width: `${reviewData?.starPercentages[rating] || 0}%`,
                        }}
                      ></span>
                    </div>
                    <span className="text-[#C0B8B1] ml-2 min-w-[40px] text-sm md:text-base">
                      {Math.round(+reviewData?.starPercentages[rating]) || 0}%
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <hr className="my-4 md:my-6 border-[2px] border-[#ECECEE]" />
            <div className="flex flex-col gap-y-8 md:gap-y-14">
              <h1 className="text-[#112532] text-xl md:text-[23px] font-semibold">
                All Reviews {`(${reviewData?.totalReviews})`}
              </h1>
              {!isInstaller && showInput && (
                <div className="flex flex-col gap-y-2">
                  <div className="flex items-center gap-x-3">
                    <img
                      loading="lazy"
                      className="w-8 h-8 md:w-10 md:h-10 rounded-full"
                      src={generalData.profile_img}
                      alt=""
                    />
                    <Rating
                      name="simple-controlled"
                      size="medium"
                      value={review.stars}
                      onChange={(event, newValue) => {
                        setReview((state) => ({
                          ...state,
                          stars: newValue,
                        }));
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-y-4 ml-8 md:ml-14">
                    <TextField
                      fullWidth
                      sx={{
                        borderRadius: "8px",
                        backgroundColor: "white",
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#f3f4f4",
                          padding: "3px 14px 0px",
                          margin: 0,
                        },
                      }}
                      value={review.comment}
                      onChange={(e) =>
                        setReview((state) => ({
                          ...state,
                          comment: e.target.value,
                        }))
                      }
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Write a review..."
                      multiline
                      rows={2}
                      InputProps={{
                        startAdornment: (
                          <img
                            loading="lazy"
                            className="self-start mr-1"
                            src="/icons/edit-pen.svg"
                            alt=""
                          />
                        ),
                      }}
                    />
                    <div className="flex flex-row justify-between gap-4 md:gap-0">
                      <div className="flex gap-x-2 w-full md:w-auto">
                        {isEditing && (
                          <button
                            onClick={() => {
                              setIsEditing(false);
                              setShowInput(false);
                            }}
                            className="px-6 py-2 rounded-[5px] border border-[#112532] text-[#112532] text-sm md:text-base"
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          onClick={() => {
                            postNewComment(review);
                            setIsEditing(false);
                          }}
                          className={`text-[#fffefb] font-bold px-7 py-2 bg-[#112532] rounded-[5px] text-sm md:text-base flex-1 md:flex-none ${
                            review.stars
                              ? "opacity-100"
                              : "opacity-50 cursor-not-allowed"
                          }`}
                          disabled={!review.stars}
                        >
                          Post
                        </button>
                      </div>
                      {isEditing && (
                        <button
                          onClick={() => {
                            setIsDialogOpen(true);
                          }}
                          className="flex items-center justify-center md:justify-start text-red-700 text-sm md:text-base"
                        >
                          <img
                            loading="lazy"
                            className="w-10 h-10 md:w-6 md:h-6 mr-2"
                            src="/icons/trash.svg"
                            alt=""
                          />
                          <span className="hidden md:inline">
                            Delete Review
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {!isInstaller && userReview && (
                <Comment
                  reviewer={userReview}
                  isMyReview={true}
                  setIsEditing={setIsEditing}
                  setShowInput={setShowInput}
                />
              )}
              {reviews?.slice(0, visibleReviews).map((reviewer) => (
                <Comment key={reviewer.id} reviewer={reviewer} />
              ))}
              {reviews && visibleReviews < reviews.length && (
                <div className="flex justify-center mt-4">
                  <button
                    onClick={handleViewMore}
                    className="px-6 py-2 bg-[#112532] text-white rounded-[5px] hover:bg-opacity-90 transition-colors"
                  >
                    View More Reviews
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstallerProfileShared;
