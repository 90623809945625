import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import AuthLayout from "layout/AuthLayout";
import { toast } from "react-toastify";
import { installerResetPassword } from "services/installerServices";
import { ACCESS_TOKEN } from "utils/constant";
import { forgetPasswordSchema } from "validation-schema/homeOwner";
import { homeOwnerResetPassword } from "services/homeOwnerServices";
import Header from "components/shared/Header";

const ForgetPassword = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isInstaller =
    window.location.pathname === "/auth/installer-forget-password";

  const onSubmit = async (data) => {
    if (isInstaller) {
      try {
        setIsSubmitting(true);
        const res = await installerResetPassword(data.email);
        setIsEmailSent(true);
      } catch (err) {
        if (err.status === 401) toast.error("incorrect email");
        toast.error(err.response.data.message);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        setIsSubmitting(true);
        const res = await homeOwnerResetPassword(data.email);
        setIsEmailSent(true);
      } catch (err) {
        if (err.status === 401) toast.error("incorrect email");
        toast.error(err.response.data.message);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(forgetPasswordSchema),
    defaultValues: {
      email: "",
    },
    mode: "onChange",
  });

  if (isEmailSent) {
    return (
      <>
        <Header />
        <AuthLayout>
          <div className="flex flex-col items-center gap-y-8 flex-1 px-6 py-14 md:py-5 md:px-[120px] bg-white w-full absolute top-[300px] md:static md:rounded-none rounded-tl-[20px] rounded-tr-[20px]">
            <img
              loading="lazy"
              className="w-24 mt-10"
              src="/icons/ic_outline-mark-email-unread.svg"
              alt=""
            />
            <h1 className=" md:text-[28px] text-[#244255] font-semibold">
              Reset password email sent!
            </h1>

            <p className="text-[#757C84]">
              Check your email to reset your password
            </p>
          </div>
        </AuthLayout>
      </>
    );
  }

  return (
    <>
      <Header />
      <AuthLayout>
        <div className="flex flex-col gap-y-8 flex-1 px-6 py-14 md:py-5 md:px-[120px] bg-white w-full absolute top-[300px] md:static md:rounded-none rounded-tl-[20px] rounded-tr-[20px]">
          <div className="">
            <h1 className="mb-3 text-[28px] text-[#244255] font-semibold">
              Forgot Password?
            </h1>

            <p className="text-[#757C84]">
              Confirm email to reset your password
            </p>
          </div>
          <div className="">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  error={errors.email && true}
                  {...field}
                  autoFocus
                  helperText={errors.email && errors.email.message}
                  className="w-full"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  sx={{
                    "& label.Mui-focused": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#66a1c7",
                      },
                    },
                  }}
                />
              )}
            />
          </div>
          <button
            className="w-full px-[18px] py-3 rounded-[5px] bg-[#112532] :hover:bg-[#112532] text-[#fffefb] font-bold"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
          >
            Reset Password
          </button>
          <Link
            className="text-[#287192] text-center font-semibold"
            to={
              window.location.pathname === "/forget-password"
                ? "auth/login"
                : "/auth/installer-login"
            }
          >
            Go Back to Sign In
          </Link>
        </div>
      </AuthLayout>
    </>
  );
};

export default ForgetPassword;
