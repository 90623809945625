import Offer from "../../shared/Offer";

const SolarOffers = ({ onBack, offers, onSubmit, setValue, isLoading }) => {
  return (
    <div className="flex flex-col gap-y-4 w-full">
      <div
        className="flex items-center gap-x-2 py-2 w-fit cursor-pointer"
        onClick={onBack}
      >
        <img src="/icons/arrow_back.svg" alt="" />
        <h1 className="text-sm font-semibold">Back to Your Solar Design</h1>
      </div>
      <div className="">
        <h1 className="text-[#112532] text-lg font-bold md:text-[33px]">
          Solar offers
        </h1>
        <p className="text-[#112532] text-[13px]">
          Based on your house calculations. Here are three offers to you{" "}
        </p>
      </div>
      <p className="text-[#112532] text-[13px]">
        System cost prices include all taxes and fees
      </p>
      <div className="w-full flex flex-col items-center md:flex-row md:gap-x-6 gap-y-4">
        {offers?.map((offer, index) => (
          <Offer
            key={offer.offerId || index}
            offer={offer}
            onSubmit={onSubmit}
            setValue={setValue}
            isLoading={isLoading}
          />
        ))}
      </div>
    </div>
  );
};

export default SolarOffers;
