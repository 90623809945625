import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Table,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import CustomizedTables from "components/installer/CustomizedTables";
import { useDebounce } from "hooks/useDebounce";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  fetchAllDeals,
  fetchHomeownerDealSummary,
} from "services/installerServices";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import ExportStyledPDF from "components/installer/ExportStyledPDF";
import ExcelExport from "components/installer/ExcelExport";
import TableSkeleton from "components/skeleton/shared/TableSkeleton";
import { useOutletContext } from "react-router-dom";
import { formatNumberFixed, formatPrice } from "utils/formatNumber";
import { customDateValidate, dealStatus } from "utils/constant";
import { POSTdealsByInstallerId } from "services/AdminServices";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: "500",
  },
}));

const columns = [
  {
    header: "ID",
    key: "id",
    width: 10,
  },
  {
    header: "Name",
    key: "User.first_name",
    width: 20,
    transform: (value, row) =>
      `${row.User?.first_name || ""} ${row.User?.last_name || ""}`,
  },
  {
    header: "Deal Type",
    key: "homeOwner_contract_signed",
    width: 15,
    transform: (value) => (value ? "Contract" : "Lead"),
  },
  {
    header: "Deal Status",
    key: "status",
    width: 15,
    transform: (value) => dealStatus(value),
  },
  {
    header: "Tiers",
    key: "system_type",
    width: 15,
  },
  {
    header: "Location",
    key: "User.location",
    width: 40,
    transform: (value, row) => row.User?.location || "",
  },
  {
    header: "Date",
    key: "createdAt",
    width: 15,
    format: "date",
  },
  {
    header: "Price",
    key: "User_solar_insight.finalPrice",
    width: 15,
    format: "currency",
    transform: (value, row) => row.User_solar_insight?.finalPrice || 0,
  },
];

const filterLabels = {
  deal_type: {
    contract: "Contract",
    lead: "Lead",
  },
  status: {
    0: "Active",
    1: "Closed Won",
    2: "Closed Lost",
  },
  system_type: {
    Basic: "Basic",
    Standard: "Standard",
    Premium: "Premium",
  },
};

const MyDeals = ({ admin, userId }) => {
  const dashboardSidebar = useOutletContext();
  const [filters, setFilters] = useState({
    used: false,
    system_type: [],
    status: [],
    date: "",
    from_date: "",
    to_date: "",
    deal_type: [],
    search: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortedRows, setSortedRows] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [dateRadio, setDateRadio] = useState();
  const [data, setdata] = useState();
  const [isLoading, setIsLoading] = useState();
  const [state, setState] = useState({
    right: false,
  });

  // Functions
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const searchDebounce = useDebounce(searchData, 1500);

  const updateFilters = (newFilterValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilterValues,
      used: true,
    }));
  };

  const handleCheckboxChange = (e, filterKey, value) => {
    const { checked } = e.target;
    updateFilters({
      [filterKey]: checked
        ? [...filters[filterKey], value]
        : filters[filterKey].filter((item) => item !== value),
    });
  };

  const handleSearchChange = (e) => {
    searchDebounce(e);
  };

  function searchData(e) {
    sessionStorage.removeItem("myDealsPageNumber");
    updateFilters({
      search: e.target.value === "" ? undefined : e.target.value,
    });
  }

  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (admin) {
        const res = await POSTdealsByInstallerId(userId, filters);
        setdata({
          res: res.deals,
          dealSummary: {
            totalDeals: res?.totals.allDeals || 0,
            activeDeals: res?.totals.activeDeals || 0,
            closedWonDeals: res?.totals.closedWon || 0,
            closedLostDeals: res?.totals.closedLost || 0,
          },
        });
      } else {
        const res = await fetchAllDeals(filters);
        const dealSummary = await fetchHomeownerDealSummary();
        setdata({ res, dealSummary });
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("myDealsFilters");
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      setFilters({ ...parsedFilters, search: "" });
      setAppliedFilters(parsedFilters);
    }
  }, []);

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("myDealsFilters");
    if (appliedFilters.used || filters.search || !savedFilters) {
      fetchData();
    }
  }, [appliedFilters, filters.search]);
  // Part 2 - Continue after Part 1

  const getActiveFilters = () => {
    const activeFilters = [];

    if (appliedFilters.deal_type.length > 0) {
      appliedFilters.deal_type.forEach((type) => {
        activeFilters.push(filterLabels.deal_type[type] || type);
      });
    }

    if (appliedFilters.status.length > 0) {
      appliedFilters.status.forEach((status) => {
        activeFilters.push(filterLabels.status[status] || status);
      });
    }

    if (appliedFilters.system_type.length > 0) {
      appliedFilters.system_type.forEach((systemType) => {
        activeFilters.push(filterLabels.system_type[systemType] || systemType);
      });
    }

    if (
      appliedFilters.date === "custom" &&
      appliedFilters.from_date &&
      appliedFilters.to_date
    ) {
      activeFilters.push(
        `${appliedFilters.from_date} - ${appliedFilters.to_date}`
      );
    } else if (appliedFilters.date !== "custom" && appliedFilters.date) {
      activeFilters.push(appliedFilters.date.split("_").join(" "));
    }

    return activeFilters;
  };

  const removeFilter = (filterToRemove) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters.deal_type = updatedFilters.deal_type.filter(
        (item) => filterLabels.deal_type[item] !== filterToRemove
      );
      updatedFilters.status = updatedFilters.status.filter(
        (item) => filterLabels.status[item] !== filterToRemove
      );
      updatedFilters.system_type = updatedFilters.system_type.filter(
        (item) => filterLabels.system_type[item] !== filterToRemove
      );
      if (
        `${updatedFilters.from_date} - ${updatedFilters.to_date}` ===
        filterToRemove
      ) {
        updatedFilters.from_date = "";
        updatedFilters.to_date = "";
      }
      if (updatedFilters.date.split("_").join(" ") === filterToRemove) {
        updatedFilters.date = "";
      }
      sessionStorage.setItem("myDealsFilters", JSON.stringify(updatedFilters));
      return updatedFilters;
    });

    setAppliedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters.deal_type = updatedFilters.deal_type.filter(
        (item) => filterLabels.deal_type[item] !== filterToRemove
      );
      updatedFilters.status = updatedFilters.status.filter(
        (item) => filterLabels.status[item] !== filterToRemove
      );
      updatedFilters.system_type = updatedFilters.system_type.filter(
        (item) => filterLabels.system_type[item] !== filterToRemove
      );
      if (
        `${updatedFilters.from_date} - ${updatedFilters.to_date}` ===
        filterToRemove
      ) {
        updatedFilters.from_date = "";
        updatedFilters.to_date = "";
      }
      if (updatedFilters.date.split("_").join(" ") === filterToRemove) {
        updatedFilters.date = "";
      }
      sessionStorage.setItem("myDealsFilters", JSON.stringify(updatedFilters));
      return updatedFilters;
    });
  };

  const clearAllFilters = () => {
    const emptyFilters = {
      used: false,
      system_type: [],
      status: [],
      date: "",
      from_date: "",
      to_date: "",
      deal_type: [],
      search: "",
    };
    setFilters(emptyFilters);
    setAppliedFilters(emptyFilters);
    setDateRadio(null);
    sessionStorage.removeItem("myDealsFilters");
    sessionStorage.removeItem("myDealsPageNumber");
  };

  const list = () => (
    <Box
      sx={{
        width: { xs: "100vw", sm: "400px", md: "480px" },
        // height: { xs: "80%", md: "100%" },
      }}
      role="presentation"
    >
      <div className="p-4 md:p-10 flex flex-col gap-y-5 pb-[120px] md:pb-10">
        <div className="sticky top-0 bg-white z-10 py-3 border-b border-[#C7CACE]">
          <div className="flex justify-between items-center ">
            <h1 className="text-[23px] text-[#112532] font-semibold py-2 md:py-4">
              Apply Filters
            </h1>
            <CloseIcon
              className="cursor-pointer md:hidden"
              onClick={toggleDrawer("right", false)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-6">
          <FormGroup>
            <h1 className="mb-1 text-[#112532] font-semibold">Deal Type</h1>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.deal_type.includes("contract")}
                  onChange={(e) =>
                    handleCheckboxChange(e, "deal_type", "contract")
                  }
                />
              }
              label="Contract"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.deal_type.includes("lead")}
                  onChange={(e) => handleCheckboxChange(e, "deal_type", "lead")}
                />
              }
              label="Lead"
            />
          </FormGroup>

          <FormGroup>
            <h1 className="mb-1 text-[#112532] font-semibold">Deal Status</h1>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.status.includes("0")}
                  onChange={(e) => handleCheckboxChange(e, "status", "0")}
                />
              }
              label="Active"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.status.includes("1")}
                  onChange={(e) => handleCheckboxChange(e, "status", "1")}
                />
              }
              label="Closed Won"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.status.includes("2")}
                  onChange={(e) => handleCheckboxChange(e, "status", "2")}
                />
              }
              label="Closed Lost"
            />
          </FormGroup>

          <FormGroup>
            <h1 className="mb-1 text-[#112532] font-semibold">Tier</h1>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.system_type.includes("Basic")}
                  onChange={(e) =>
                    handleCheckboxChange(e, "system_type", "Basic")
                  }
                />
              }
              label="Basic"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.system_type.includes("Standard")}
                  onChange={(e) =>
                    handleCheckboxChange(e, "system_type", "Standard")
                  }
                />
              }
              label="Standard"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.system_type.includes("Premium")}
                  onChange={(e) =>
                    handleCheckboxChange(e, "system_type", "Premium")
                  }
                />
              }
              label="Premium"
            />
          </FormGroup>

          <FormControl>
            <h1 className="mb-1 text-[#112532] font-semibold">Date</h1>
            <RadioGroup
              onChange={(e) => {
                setDateRadio(e.target.value);
                updateFilters({
                  date: e.target.value,
                  from_date: "",
                  to_date: "",
                });
              }}
              value={filters.date || null}
            >
              <FormControlLabel
                value="last_7_days"
                control={<Radio />}
                label="Last 7 Days"
              />
              <FormControlLabel
                value="last_30_days"
                control={<Radio />}
                label="Last Month"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </FormControl>

          <div
            className={`flex flex-col md:flex-row gap-3 ${dateRadio === "custom" ? "" : "opacity-50"}`}
          >
            <div className="flex-1">
              <h1 className="mb-2 text-sm md:text-base">From</h1>
              <DatePicker
                disabled={dateRadio !== "custom"}
                value={filters.from_date ? dayjs(filters.from_date) : null}
                onChange={(newValue) => {
                  updateFilters({
                    from_date: newValue ? newValue.format("YYYY-MM-DD") : "",
                  });
                }}
                sx={{ width: "100%" }}
                maxDate={filters.to_date ? dayjs(filters.to_date) : dayjs()}
              />
            </div>
            <div className="flex-1">
              <h1 className="mb-2 text-sm md:text-base">To</h1>
              <DatePicker
                disabled={dateRadio !== "custom"}
                value={filters.to_date ? dayjs(filters.to_date) : null}
                onChange={(newValue) => {
                  updateFilters({
                    to_date: newValue ? newValue.format("YYYY-MM-DD") : "",
                  });
                }}
                sx={{ width: "100%" }}
                minDate={filters.from_date ? dayjs(filters.from_date) : null}
                maxDate={dayjs()}
              />
            </div>
          </div>

          <div className="fixed md:relative bottom-0 left-0 right-0 bg-white border-t border-gray-200 md:border-0">
            <div className="flex justify-center md:justify-end gap-3 p-6 md:p-0 md:mt-4">
              <button
                onClick={(event) => {
                  toggleDrawer("right", false)(event);
                  clearAllFilters();
                }}
                className="py-3 px-10 text-[#112532] border border-[#112532] rounded text-sm md:text-base w-full lg:w-auto font-bold"
              >
                Cancel
              </button>
              <button
                disabled={
                  !customDateValidate(
                    dateRadio,
                    filters.from_date,
                    filters.to_date
                  )
                }
                onClick={(event) => {
                  setAppliedFilters(filters);
                  sessionStorage.removeItem("myDealsPageNumber");
                  sessionStorage.setItem(
                    "myDealsFilters",
                    JSON.stringify(filters)
                  );
                  toggleDrawer("right", false)(event);
                }}
                className="py-3 px-10 text-[#FFFEFB] bg-[#112532] rounded text-sm md:text-base w-full lg:w-auto disabled:opacity-50"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
  const nodata = () => {
    if (getActiveFilters().length === 0 && !filters.search) {
      return (
        <div className="flex justify-center items-center flex-col bg-white p-9 gap-y-5 mt-4">
          <img loading="lazy" src="/icons/rockets.svg" alt="No deals" />
          <h1 className="font-semibold text-[#244255] text-[20px]">
            No deals yet
          </h1>
          <p className="text-[#112532]">You're just getting started!</p>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center items-center gap-x-2 text-[#767F89] p-5 bg-white mt-4">
          <img loading="lazy" src="/icons/search.svg" alt="search" />
          No results found
        </div>
      );
    }
  };
  // Part 3 - Final part with render method

  return (
    <div className="px-2 md:px-5 flex-1">
      {/* Header */}
      <div className="flex items-center gap-x-2 p-6 pl-0">
        <div className="lg:hidden">{dashboardSidebar}</div>
        <h1 className="text-[23px] md:text-[28px] font-semibold">My Deals</h1>
      </div>

      {/* Deals Status Cards - Responsive Grid */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-4 my-6">
        {/* All Deals Card */}
        <div className="bg-white p-3 md:px-4 md:py-2 rounded-md border-[0.5px] border-[#E0E4EA] shadow">
          <p className="flex justify-between items-center">
            <span className="text-xl md:text-[28px] font-semibold flex-grow">
              {data?.dealSummary?.totalDeals !== undefined &&
              data?.dealSummary?.totalDeals !== null ? (
                data.dealSummary.totalDeals
              ) : (
                <Skeleton width="10%" height={40} />
              )}
            </span>
            <img
              loading="lazy"
              src="/icons/deals-Hand-shake.svg"
              alt=""
              className=" md:w-auto"
            />
          </p>
          <p className="mt-2 md:my-3 text-sm md:text-base">All Deals</p>
        </div>

        {/* Active Deals Card */}
        <div className="bg-white p-3 md:px-4 md:py-2 rounded-md border-[0.5px] border-[#E0E4EA] shadow">
          <p className="flex justify-between items-center">
            <span className="text-xl md:text-[28px] font-semibold flex-grow">
              {data?.dealSummary?.activeDeals !== undefined &&
              data?.dealSummary?.activeDeals !== null ? (
                data.dealSummary.activeDeals
              ) : (
                <Skeleton width="10%" height={40} />
              )}
            </span>
            <img
              loading="lazy"
              src="/icons/Active.svg"
              alt=""
              className=" md:w-auto"
            />
          </p>
          <p className="mt-2 md:my-3 text-sm md:text-base">Active Deals</p>
        </div>

        {/* Closed Won Card */}
        <div className="bg-white p-3 md:px-4 md:py-2 rounded-md border-[0.5px] border-[#E0E4EA] shadow">
          <p className="flex justify-between items-center">
            <span className="text-xl md:text-[28px] font-semibold flex-grow">
              {data?.dealSummary?.closedWonDeals !== undefined &&
              data?.dealSummary?.closedWonDeals !== null ? (
                data.dealSummary.closedWonDeals
              ) : (
                <Skeleton width="10%" height={40} />
              )}
            </span>
            <img
              loading="lazy"
              src="/icons/rise.svg"
              alt=""
              className=" md:w-auto"
            />
          </p>
          <p className="mt-2 md:my-3 text-sm md:text-base">Closed Won</p>
        </div>

        {/* Closed Lost Card */}
        <div className="bg-white p-3 md:px-4 md:py-2 rounded-md border-[0.5px] border-[#E0E4EA] shadow">
          <p className="flex justify-between items-center">
            <span className="text-xl md:text-[28px] font-semibold flex-grow">
              {data?.dealSummary?.closedLostDeals !== undefined &&
              data?.dealSummary?.closedLostDeals !== null ? (
                data.dealSummary.closedLostDeals
              ) : (
                <Skeleton width="10%" height={40} />
              )}
            </span>
            <img
              loading="lazy"
              src="/icons/down.svg"
              alt=""
              className=" md:w-auto"
            />
          </p>
          <p className="mt-2 md:my-3 text-sm md:text-base">Closed Lost</p>
        </div>
      </div>

      {/* Header and Export Section */}
      <div className="flex justify-between items-center md:items-center gap-y-2 mb-4">
        <h1 className="text-[20px] md:text-[23px] font-semibold">All Deals</h1>
        {isLoading ? (
          <div>LOADING...</div>
        ) : (data?.res?.length ?? 0) === 0 ? (
          <div className="text-[#767F89] font-bold border-1 border-[#767F89] py-2 px-4 rounded opacity-50 cursor-not-allowed w-[25%] md:w-[9%] text-center">
            Export
          </div>
        ) : (
          <FormControl className="w-[25%] md:w-[9%] rounded" size="small">
            <InputLabel
              shrink={false}
              id="demo-simple-select-label"
              sx={{
                color: "#112532",
                fontWeight: "bold",
                "&.Mui-focused": {
                  color: "#112532",
                },
              }}
            >
              Export
            </InputLabel>
            <Select
              IconComponent={KeyboardArrowDownOutlinedIcon}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{
                color: "#112532",
                borderColor: "#112532",
                "& .MuiSelect-icon": {
                  color: "#112532",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#112532",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#112532",
                  borderWidth: "1px",
                },
              }}
            >
              <div className="flex flex-col">
                <ExportStyledPDF
                  variant={"deals"}
                  title="my deals"
                  cellWidth={[40, 90, 60, 70, 170, 80, 60]}
                  activeFilters={getActiveFilters()}
                  data={
                    selectedRows.length > 0
                      ? selectedRows
                      : sortedRows.length > 0
                        ? sortedRows
                        : data?.res
                  }
                />
                <hr />
                <ExcelExport
                  filters={getActiveFilters()}
                  filename="users_export"
                  columns={columns}
                  data={
                    selectedRows.length > 0
                      ? selectedRows
                      : sortedRows.length > 0
                        ? sortedRows
                        : data?.res
                  }
                />
              </div>
            </Select>
          </FormControl>
        )}
      </div>

      {/* Search and Filter Section */}
      <div className="flex flex-col gap-y-3 justify-between gap-x-6 mb-3 bg-white border border-[#d9d3cf]">
        <TextField
          sx={{
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              border: "none",
              "& fieldset": {
                border: "none",
              },
            },
          }}
          fullWidth
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  loading="lazy"
                  src={
                    filters.search
                      ? "/icons//search-active.svg"
                      : "/icons/search.svg"
                  }
                  alt="search icon"
                  className="w-5 md:w-auto"
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <img
                  loading="lazy"
                  onClick={toggleDrawer("right", true)}
                  className="cursor-pointer w-auto"
                  src={
                    getActiveFilters().length > 0
                      ? "/icons/Filter-active.svg"
                      : "/icons/Filter.svg"
                  }
                  alt="filter icon"
                />
              </InputAdornment>
            ),
          }}
          placeholder="Search ID, name or location..."
        />

        <Drawer
          anchor="right"
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          sx={{
            "& .MuiBackdrop-root": {
              background: "rgba(17, 37, 50, 0.5)",
            },
          }}
          PaperProps={{
            sx: {
              width: { xs: "100%", sm: "400px", md: 500 },
              height: { xs: "85%", md: "100%" },
              bottom: 0,
              top: "auto",
              "& > div:first-of-type": {
                height: "100%",
              },
            },
          }}
        >
          {list()}
        </Drawer>

        {getActiveFilters().length > 0 && (
          <div className="flex justify-between pb-4 px-2 md:px-4">
            <ul className="flex flex-wrap gap-2 mb-2 md:mb-0">
              {getActiveFilters().map((filter, index) => (
                <li
                  key={index}
                  className="px-2 md:px-3 py-1 bg-[#FFF8F1] border border-[#FBB667] rounded text-xs md:text-sm flex items-center font-semibold"
                >
                  {filter}
                  <CloseIcon
                    sx={{ fontSize: { xs: "16px", md: "20px" } }}
                    className="ml-1 md:ml-2 cursor-pointer"
                    onClick={() => removeFilter(filter)}
                  />
                </li>
              ))}
            </ul>
            <button
              className="min-w-[100px] flex items-center justify-end gap-x-1 rounded text-[#244255] font-semibold text-sm md:text-base mt-2 md:mt-0"
              onClick={clearAllFilters}
            >
              <CloseIcon sx={{ fontSize: { xs: "16px", md: "20px" } }} />
              Clear All
            </button>
          </div>
        )}
      </div>

      {/* Table Section */}
      <div className="overflow-x-auto">
        {isLoading ? (
          <TableSkeleton />
        ) : (data?.res?.length ?? 0) === 0 ? (
          <div className=" overflow-hidden">
            <Table
              sx={{ minWidth: 700, tableLayout: "fixed" }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell align="left">Name</StyledTableCell>
                  <StyledTableCell align="left">
                    Deal Type&nbsp;
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Deal Status&nbsp;
                  </StyledTableCell>
                  <StyledTableCell align="left">Tiers&nbsp;</StyledTableCell>
                  <StyledTableCell align="left">Location&nbsp;</StyledTableCell>
                  <StyledTableCell align="left">Date&nbsp;</StyledTableCell>
                  <StyledTableCell align="left">Price&nbsp;</StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
            {nodata()}
          </div>
        ) : (
          <div className="mb-10">
            <CustomizedTables
              setSortedRows={setSortedRows}
              recordsArray={data?.res}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              admin={admin}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyDeals;
