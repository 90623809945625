const initialState = {
  id: null,
  fullName: "",
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  profile_img: "/images/default-profile.svg",
  logo: "/images/defaultLogo.svg",
  business_name: "",
};

const generalInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_GENERAL_INFORMATION":
      const { first_name, last_name, profile_img, logo, ...otherData } =
        action.payload;

      return {
        ...state,
        ...otherData,
        first_name: first_name || state.first_name,
        last_name: last_name || state.last_name,
        fullName:
          `${first_name || state.first_name} ${last_name || state.last_name}`.trim(),
        profile_img:
          profile_img === null
            ? initialState.profile_img
            : profile_img
              ? profile_img
              : state.profile_img,
        logo: logo || state.logo,
      };

    default:
      return state;
  }
};

export default generalInformationReducer;
