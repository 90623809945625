import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GEThomeOwnerContractInfo,
  PATCHhomeOwnerContractSign,
} from "services/homeOwnerServices";
import {
  GETinstallerContractInfo,
  PATCHinstallerContractSign,
} from "services/installerServices";
import { contractFormSchema } from "services/shared";
import { formatPrice } from "utils/formatNumber";

const SignContract = () => {
  const [contractData, setContractData] = useState([]);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(contractFormSchema),
    defaultValues: {
      signature: "",
    },
    mode: "onChange",
  });
  const navigate = useNavigate();
  const { userId } = useParams();

  const userRole = localStorage.getItem("USER");
  const homeOwnerFullName = `${contractData?.User?.first_name} ${contractData?.User?.last_name} `;

  const onSubmit = async (data) => {
    try {
      if (userRole === "homeOwner") {
        const res = await PATCHhomeOwnerContractSign(data);
        toast.success(res?.message);
        navigate("/dashboard");
      } else if (userRole === "installer") {
        const res = await PATCHinstallerContractSign(userId, data);
        toast.success(res?.message);
        navigate(`/dashboard-installer/Mydeals/${userId}`);
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
    }
  };

  const fetchData = async () => {
    try {
      const res =
        userRole === "homeOwner"
          ? await GEThomeOwnerContractInfo()
          : userRole === "installer"
            ? await GETinstallerContractInfo(userId)
            : null;
      setContractData(res);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="md:h-[calc(100vh+300px)] md:w-full flex flex-col items-center pt-6 md:p-8">
        <h1 className="text-[#112532] text-[32px] font-bold pb-5">
          Sign Contract{" "}
        </h1>
        <div className="h-screen w-full p-6 md:w-10/12 flex flex-col gap-y-8 bg-white border border-[#c0b8b1] border-r-0 rounded-lg">
          <div className="text-gray-900 h-screen overflow-y-scroll">
            <div className=" md:max-w-4xl md:p-10 md:pl-16 rounded-lg ">
              <h1 className="text-3xl font-bold mb-4">
                Home Improvement Contract for Solar Installation
              </h1>

              <p className="mb-4">
                This Home Improvement Contract (the “Contract”) is entered into
                on this &nbsp;
                <span className="border-b border-gray-500 font-bold">
                  {dayjs(contractData?.createdAt).format("MMMM D, YYYY")}
                </span>
                &nbsp; (the “Effective Date”) by and between:
                {/* ***************** */}
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">Homeowner:</h2>
              <ul className="list-disc pl-8">
                <li>
                  <strong>Name:</strong>
                  <span className="border-b border-gray-500">
                    &nbsp;{homeOwnerFullName}
                    &nbsp;
                  </span>
                </li>
                <li>
                  <strong>Address:</strong>
                  <span className="border-b border-gray-500">
                    &nbsp;{contractData?.User?.location}
                    &nbsp;
                  </span>
                </li>
                <li>
                  <strong>Phone Number:</strong>
                  <span className="border-b border-gray-500">
                    &nbsp;{contractData?.User?.mobile}
                    &nbsp;
                  </span>
                </li>
              </ul>

              <h2 className="text-2xl font-semibold mt-8 mb-2">Installer:</h2>
              <ul className="list-disc pl-8">
                <li>
                  <strong>Business Name:</strong>
                  <span className="border-b border-gray-500">
                    &nbsp;{contractData?.Installer?.business_name}
                    &nbsp;
                  </span>
                </li>
                <li>
                  <strong>Business Address:</strong>
                  <span className="border-b border-gray-500">
                    &nbsp;{contractData?.Installer?.business_address}
                    &nbsp;
                  </span>
                </li>
                <li>
                  <strong>Phone Number:</strong>
                  <span className="border-b border-gray-500">
                    &nbsp;{contractData?.Installer?.mobile}
                    &nbsp;
                  </span>
                </li>
              </ul>

              <p className="mt-8 mb-4">
                Collectively referred to as the "Parties."
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">
                1. Scope of Work
              </h2>
              <p className="mb-4">
                The Installer agrees to install a solar energy system{" "}
                <span className="font-bold">{contractData?.system_type}</span>{" "}
                on the Homeowner's property located at the above-stated address.
                The System will include the following specifications and
                components:
              </p>

              <h3 className="text-xl font-semibold mt-6 mb-2">
                1.1 System Specifications
              </h3>
              <ul className="list-disc pl-8 mb-4">
                <li>
                  <strong>Average Monthly Electrical Bill:</strong> $
                  {contractData?.User?.avg_bill}
                </li>
                <li>
                  <strong>Solar System Size:</strong>{" "}
                  {formatPrice(contractData?.User_solar_insight?.system_size)}
                  kW
                </li>
                <li>
                  <strong>Number of Solar Panels:</strong>{" "}
                  {contractData?.panels_number}
                </li>
                <li>
                  <strong>System Production Capacity:</strong>{" "}
                  {formatPrice(
                    contractData?.User_solar_insight?.totalProductionFromPanels
                  )}
                  kWh per month
                </li>
                <li>
                  <strong>System Tier:</strong> {contractData?.system_type}
                </li>
                <li>
                  <strong>Estimated Offset:</strong>{" "}
                  {formatPrice(
                    contractData?.User_solar_insight?.estimated_offset * 100
                  )}
                  %
                </li>
                <li>
                  <strong>Old Monthly Electric Bill:</strong> ${" "}
                  {formatPrice(contractData?.User?.avg_bill)}
                </li>
                <li>
                  <strong>Leftover Monthly Bill Amount:</strong> ${" "}
                  {formatPrice(
                    contractData?.User_solar_insight?.left_over_elec_bill
                  )}
                </li>
              </ul>

              <h3 className="text-xl font-semibold mt-6 mb-2">
                1.2 Roof Details
              </h3>
              <ul className="list-disc pl-8 mb-4">
                <li>
                  <strong>Roof Type:</strong> {contractData?.User?.roof_type}
                </li>
                <li>
                  <strong>Roof Condition:</strong>{" "}
                  {contractData?.User?.roof_condition}
                </li>
                <li>
                  <strong>Estimated Credit Score of Homeowner:</strong>{" "}
                  {contractData?.User?.credit_score}
                </li>
              </ul>

              <h3 className="text-xl font-semibold mt-6 mb-2">
                1.3 System Components
              </h3>
              <ul className="list-disc pl-8 mb-4">
                <li>
                  <strong>Solar Panel Name:</strong>{" "}
                  {contractData?.Data_panel?.description}
                </li>
                <li>
                  <strong>Inverter Name:</strong>{" "}
                  {contractData?.Data_inverter?.description}
                </li>
                <li>
                  <strong>Racking Name:</strong>{" "}
                  {contractData?.Data_racking?.description}
                </li>
                <li>
                  <strong>Battery Name:</strong>{" "}
                  {contractData?.Data_battery?.description}
                </li>
              </ul>

              <h2 className="text-2xl font-semibold mt-8 mb-2">
                2. Price and Payment Terms
              </h2>
              <p className="mb-4">
                The total price for the System installation shall be ${" "}
                <span className="font-semibold">
                  {formatPrice(contractData?.User_solar_insight?.finalPrice)}{" "}
                </span>
                payable in full by cash upfront prior to the start of the
                installation. No installment or financing options are provided
                under this Contract unless otherwise agreed in writing by the
                Parties.
              </p>

              <h3 className="text-xl font-semibold mt-6 mb-2">
                2.1 Payment Details
              </h3>
              <p className="mb-4">
                Payment shall be made in full upon signing this Contract or no
                later than 30 days from the Effective Date. Failure to make the
                payment by the due date may result in termination of the
                Contract and additional fees for expenses incurred.
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">3. Warranty</h2>
              <p className="mb-4">
                The Installer warrants the System for a period of{" "}
                {contractData?.system_type === "Basic"
                  ? "15"
                  : contractData?.system_type === "Standard"
                    ? "20"
                    : "25"}{" "}
                years from the date of installation. This warranty covers both
                parts and labor for defects in materials or workmanship but does
                not cover damages caused by external factors such as weather,
                Homeowner negligence, or modifications made by unauthorized
                personnel.
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">
                4. Governing Law and Dispute Resolution
              </h2>
              <p className="mb-4">
                This Contract shall be governed by the laws of the State of{" "}
                {contractData?.Installer?.state}. Any disputes arising out of or
                relating to this Contract shall be resolved in the state or
                federal courts located {/* ########################## */}
                in {contractData?.Installer?.state}.
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">
                5. Termination
              </h2>
              <p className="mb-4">
                In the event either Party breaches this Contract the
                non-breaching Party shall have the right to terminate this
                Contract by providing written notice to the breaching Party. If
                the Homeowner terminates this Contract without cause after
                signing the Homeowner shall be liable for any costs incurred by
                the Installer up to the date of termination including but not
                limited to the cost of materials and labor.
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">
                6. Access to Property
              </h2>
              <p className="mb-4">
                The Homeowner agrees to provide full access to the property and
                roof during the scheduled installation period. The Installer
                shall make reasonable efforts to complete the installation
                within the agreed time but delays due to weather or unforeseen
                circumstances may extend the installation timeline.
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">
                7. System Performance Disclaimer
              </h2>
              <p className="mb-4">
                The Homeowner acknowledges that the System’s actual performance
                may vary due to environmental factors such as shading, roof
                angle, and local weather conditions. The Installer makes no
                guarantees regarding specific electricity savings.
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">
                8. System Ownership and Use
              </h2>
              <p className="mb-4">
                The System once installed and fully paid for shall become the
                property of the Homeowner. The Homeowner assumes full
                responsibility for maintaining the System in proper working
                condition according to the guidelines provided by the Installer.
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">
                9. Amendments
              </h2>
              <p className="mb-4">
                This Contract may only be amended in writing and signed by both
                Parties. Verbal agreements or modifications will not be
                enforceable.
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">
                10. Entire Agreement
              </h2>
              <p className="mb-4">
                This Contract constitutes the entire agreement between the
                Parties and supersedes any prior negotiations, discussions, or
                understandings regarding the subject matter herein.
              </p>

              <h2 className="text-2xl font-semibold mt-8 mb-2">Signatures</h2>
              <p className="mb-4">
                <strong>Homeowner Signature:</strong>
                <span className="border-b border-gray-500">
                  {" "}
                  {contractData?.homeOwner_signature_name
                    ? contractData?.homeOwner_signature_name
                    : "__________________________"}
                </span>
                <br />
                <strong>Date:</strong>
                <span className="border-b border-gray-500">
                  &nbsp;
                  {contractData?.homeOwner_signature_date
                    ? dayjs(contractData?.homeOwner_signature_date).format(
                        "MM/DD/YY"
                      )
                    : "__________________________"}
                </span>
              </p>

              <p className="mb-4">
                <strong>Installer Signature:</strong>
                <span className="border-b border-gray-500">
                  {" "}
                  {contractData?.installer_signature_name
                    ? contractData?.installer_signature_name
                    : "__________________________"}
                </span>
                <br />
                <strong>Date:</strong>
                <span className="border-b border-gray-500">
                  {contractData?.installer_signature_date
                    ? dayjs(contractData?.installer_signature_date).format(
                        "MM/DD/YY"
                      )
                    : "__________________________"}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-10/12 gap-y-5 pt-5">
          <p className="text-[#112532] text-lg">
            Sign the contract below with your full name
          </p>
          <Controller
            name="signature"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Write your full name"
                variant="outlined"
                error={errors.signature}
                helperText={errors.signature && errors.signature.message}
              />
            )}
          />

          <div className="flex flex-col md:flex-row gap-x-2 gap-y-3 pb-12">
            <button
              className="bg-[#112532] px-[18px] py-3 rounded-[5px]  hover:bg-[#D97706] text-white disabled:hover:bg-[#112532] disabled:opacity-50"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
            >
              Sign Contract
            </button>

            <button
              className="py-3 px-[18px] rounded-[5px] border border-black text-[#0d0f11] text-sm font-semibold"
              onClick={
                userRole === "homeOwner"
                  ? () => navigate(`/dashboard`)
                  : () => navigate(`/dashboard-installer/Mydeals/${userId}`)
              }
            >
              Sign Later
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignContract;
