import React from "react";
import AuthenticatedHeader from "components/shared/AuthenticatedHeader";
import { useAuth } from "hooks/useAuth";
import { Outlet, useLocation } from "react-router-dom";
import DashboardSidebar from "../../components/installer/DashboardSidebar";
import MainAuthGuard from "pages/auth/MainAuthGuard";

export default function DashboardInstaller() {
  const isAuth = useAuth();
  const location = useLocation();

  const shouldHideSidebar =
    /^\/dashboard-installer\/Mydeals\/\d+\/contract$/.test(location.pathname);

  return (
    <MainAuthGuard>
      <div className="bg-[#F9F8F8] w-full flex flex-col min-h-screen">
        {isAuth && <AuthenticatedHeader />}
        <div className="flex w-full flex-1 md:p-6">
          {!shouldHideSidebar && (
            <div className="hidden lg:block lg:min-w-[20%] lg:max-w-[20%]">
              <DashboardSidebar />
            </div>
          )}
          <div className="w-full lg:ml-6 ">
            <Outlet context={<DashboardSidebar />} />
          </div>
        </div>
      </div>
    </MainAuthGuard>
  );
}
