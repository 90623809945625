import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormHelperText,
  styled,
  TextField,
} from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { forwardRef, useId } from "react";
import { Controller, useForm } from "react-hook-form";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { contactUsSchema } from "validation-schema/contactUs";
import Footer from "../../pages/Footer";
import { POSTcontactUs } from "services/general";
import { toast } from "react-toastify";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const location = {
  lat: 34.8477271,
  lng: -82.3944341,
};

const ContactUs = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(contactUsSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      mobile: "",
      email: "",
      subject: "",
      message: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      const res = await POSTcontactUs(data);
      toast.success(res.message);
    } catch (error) {}
  };

  return (
    <div className="flex flex-col bg-[#f3f4f4]">
      {/* CONTACT SALES SECTION */}
      <div className="flex flex-col items-center justify-center gap-y-8 px-4 md:px-16 pt-12">
        <div className="flex flex-col items-center gap-y-5 text-center">
          <h1 className="text-[#112532] text-2xl md:text-4xl font-semibold mt-10 md:mt-20">
            Contact <span className="text-amber-600">Us</span>
          </h1>
          <p className="text-[#112532] text-base md:text-xl md:text-center">
            We're here to answer your solar questions and help you get started
            <br />
            with Spark Solar. Reach out for more info or to schedule a
            consultation.
          </p>
        </div>
        <div className="w-full md:w-3/5 flex flex-col md:flex-row justify-center gap-y-4 md:gap-x-5">
          <div className="flex flex-col gap-y-3 items-center justify-center px-6 md:px-12 py-6 bg-white rounded-lg shadow border border-[#c7cace] md:flex-1">
            <h2 className="flex items-center gap-x-2 text-[#112532] text-xl md:text-2xl font-medium">
              <img src="/icons/email-stepper.svg" alt="email circle" />
              Send Email
            </h2>
            <a
              href="mailto:info@sparksolar.io"
              className="text-[#112532] text-base md:text-xl font-normal underline"
            >
              info@sparksolar.io
            </a>
          </div>
          <div className="flex flex-col gap-y-3 items-center justify-center px-6 md:px-12 py-6 bg-white rounded-lg shadow border border-[#c7cace] md:flex-1">
            <h2 className="flex items-center gap-x-2 text-[#112532] text-xl md:text-2xl font-medium">
              <img src="/icons/location-stepper.svg" alt="email circle" />
              Address
            </h2>
            <p className="text-[#112532] text-base md:text-xl font-normal">
              United States, Greenville
            </p>
          </div>
        </div>
      </div>

      {/* CONTACT FORM SECTION */}
      <div className="flex flex-col gap-y-5 px-4 md:px-16 py-12">
        <h2 className="text-[#112532] text-2xl md:text-4xl font-semibold">
          Get in <span className="text-amber-600">Touch</span>
        </h2>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col gap-y-6 w-full md:w-5/12 mb-6 md:mb-0">
            <div className="flex flex-col md:flex-row md:gap-x-3 w-full">
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      height: "fit-content",
                      "& .MuiFormHelperText-root": {
                        backgroundColor: "#f3f4f4",
                        padding: "3px 14px 0px",
                        margin: 0,
                      },
                      marginBottom: { xs: "1.5rem", md: 0 },
                    }}
                    {...field}
                    error={true && errors.first_name}
                    helperText={errors.first_name && errors.first_name.message}
                    id="first_name"
                    label="First Name"
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      height: "fit-content",
                      "& .MuiFormHelperText-root": {
                        backgroundColor: "#f3f4f4",
                        padding: "3px 14px 0px",
                        margin: 0,
                      },
                    }}
                    {...field}
                    error={true && errors.last_name}
                    helperText={errors.last_name && errors.last_name.message}
                    id="last_name"
                    label="Last Name"
                    variant="outlined"
                  />
                )}
              />
            </div>

            <Controller
              name="mobile"
              control={control}
              render={({ field }) => (
                <TextField
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    "& .MuiFormHelperText-root": {
                      backgroundColor: "#f3f4f4",
                      margin: "0",
                      padding: "3px 14px 0px",
                    },
                  }}
                  {...field}
                  error={!!errors.mobile}
                  helperText={errors.mobile && errors.mobile.message}
                  onKeyDown={(e) => {
                    if (
                      [
                        "-",
                        "+",
                        "e",
                        "E",
                        ".",
                        "ArrowUp",
                        "ArrowDown",
                      ].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  id="mobile"
                  label="Phone Number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <div className="pl-2 pr-3 mr-[10px] border-r-[1px] border-[#C7CACE]">
                        +1
                      </div>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "white",
                    "& .MuiFormHelperText-root": {
                      backgroundColor: "#f3f4f4",
                      padding: "3px 14px 0px",
                      margin: 0,
                    },
                  }}
                  {...field}
                  error={true && errors.email}
                  helperText={errors.email && errors.email.message}
                  id="email"
                  label="Email"
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <TextField
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "white",
                    "& .MuiFormHelperText-root": {
                      backgroundColor: "#f3f4f4",
                      padding: "3px 14px 0px",
                      margin: 0,
                    },
                  }}
                  {...field}
                  error={true && errors.subject}
                  helperText={errors.subject && errors.subject.message}
                  id="subject"
                  label="Subject"
                  variant="outlined"
                />
              )}
            />

            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "white",
                    "& .MuiFormHelperText-root": {
                      backgroundColor: "#f3f4f4",
                      padding: "3px 14px 0px",
                      margin: 0,
                    },
                  }}
                  {...field}
                  error={true && errors.message}
                  helperText={errors.message && errors.message.message}
                  id="message"
                  label="Message"
                  variant="outlined"
                  placeholder="Write a message..."
                  multiline
                  rows={5}
                />
              )}
            />
            <Button
              sx={{
                backgroundColor: "#112532",
                padding: "12px 18px",
                boxShadow: "none",
                ":hover": { backgroundColor: "#D97706" },
              }}
              variant="contained"
              className="w-full"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
            >
              Submit
            </Button>
          </div>
          <div className="w-full md:w-3/6 h-[400px] md:h-auto relative rounded-lg border border-[#c7cace] overflow-hidden">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={location}
              zoom={17}
              options={{
                fullscreenControl: false,
                streetViewControl: false,
                zoomControl: true,
                rotateControl: false,
                mapTypeControl: false,
              }}
            >
              <Marker position={location} />

              <div className="absolute bg-white px-3 py-4 flex flex-col gap-y-2 m-4">
                <h1 className="flex gap-x-2 items-center text-[#112532] font-semibold">
                  <img src="/icons/Location.svg" alt="" />
                  Spark Solar
                </h1>
                <p className="text-[#454955] text-xs">
                  500 East McBee Ave, Suite 100 #1251, Greenville,
                  <br /> SC 29601, United States
                </p>
              </div>
            </GoogleMap>
          </div>
        </div>
      </div>
      <Footer bgColor={"#f3f4f4"} />
    </div>
  );
};

export default ContactUs;
