import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  POSThomeowenersDashboardData,
  POSTinstallersDashboardData,
} from "services/AdminServices";
import { getDifferenceType } from "utils/constant";
import { formatPrice } from "utils/formatNumber";

const commonXAxisConfig = {
  interval: 0,
  tickLine: false,
  axisLine: false,
  tick: {
    fill: "#1C1C1C66",
    fontSize: 12,
    textAnchor: "middle",
    angle: 0,
    dx: 0,
    dy: 5,
  },
  tickFormatter: (tick) => dayjs(tick).format("MMM D"),
};

const commonYAxisConfig = {
  axisLine: false,
  tickLine: false,
  tick: {
    fill: "#1C1C1C66",
    fontSize: 12,
    dy: -3,
  },
  tickMargin: 16,
};

const chartMargin = {
  left: -10,
  right: 10,
  top: 10,
  bottom: 20,
};

const InstallersCharts = ({ installersChartsData, target }) => {
  return (
    <>
      <div className="flex justify-between gap-x-4">
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">
              {installersChartsData?.Counters?.installers}
            </span>
            <img src="/Admin-assets/tools.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">All Installers</h2>
        </div>
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">
              {installersChartsData?.Counters?.activeInstallers}
            </span>
            <img src="/Admin-assets/closed-won.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">Active Installers</h2>
        </div>
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">
              {installersChartsData?.Counters?.inactiveInstallers}
            </span>
            <img src="/Admin-assets/inactive.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">Inactive Installers</h2>
        </div>
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">
              {installersChartsData?.Counters?.deals}
            </span>
            <img src="/Admin-assets/shake.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">All Deals</h2>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-4">
        {/* Installers Line Chart */}
        <div className="flex-1 p-6 bg-white rounded shadow-md max-h-[400px]">
          <h3 className="text-lg font-semibold mb-4">Installers</h3>
          <ResponsiveContainer width="100%" height="90%">
            <LineChart
              data={installersChartsData?.chartData?.installersData}
              margin={chartMargin}
            >
              <CartesianGrid vertical={false} />
              <CartesianGrid horizontal={false} strokeDasharray="3 3" />
              <XAxis
                {...commonXAxisConfig}
                dataKey={target === "week" ? "weekName" : "week"}
              />
              <YAxis
                {...commonYAxisConfig}
                // tickFormatter={(tick) => (tick !== 0 ? `${tick}%` : tick)}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="registrations"
                stroke="#F59E0B"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* All Deals Bar Chart */}
        <div className="flex-1 p-6 bg-white rounded shadow-md max-h-[400px]">
          <h3 className="text-lg font-semibold mb-4">All Deals</h3>
          <ResponsiveContainer width="100%" height="90%">
            <BarChart
              data={installersChartsData?.chartData?.dealData}
              margin={chartMargin}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                {...commonXAxisConfig}
                dataKey={target === "week" ? "weekName" : "week"}
              />
              <YAxis {...commonYAxisConfig} tickFormatter={(tick) => tick} />
              <Tooltip cursor={{ fill: "#fdf0da" }} />
              <Bar dataKey="active" fill="#F59E0B" barSize={25} />
              <Bar dataKey="closedWon" fill="#2AB065" barSize={25} />
              <Bar dataKey="closedLost" fill="#F27661" barSize={25} />
            </BarChart>
          </ResponsiveContainer>
          <div className="flex justify-center gap-x-5">
            <p className="text-[9px] md:text-[11px] text-[#112532] font-semibold flex items-center gap-x-1">
              <span className="w-2.5 h-2.5  bg-[#F59E0B]" /> Active
            </p>
            <p className="text-[9px] md:text-[11px] text-[#112532] font-semibold flex items-center gap-x-1">
              <span className="w-2.5 h-2.5  bg-[#2AB065]" /> Closed Won
            </p>
            <p className="text-[9px] md:text-[11px] text-[#112532] font-semibold flex items-center gap-x-1">
              <span className="w-2.5 h-2.5  bg-[#F27661]" /> Closed Lost
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const HomeownersCharts = ({ homeownerChartsData, target }) => {
  return (
    <>
      <div className="flex justify-between gap-x-4">
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">
              {homeownerChartsData?.Counters?.homeOwners}
            </span>
            <img src="/Admin-assets/homeowners.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">All Home Owners</h2>
        </div>
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">
              {" "}
              {homeownerChartsData?.Counters?.Contracts}
            </span>
            <img src="/Admin-assets/contracts.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">Contracts</h2>
        </div>
        <div className="flex flex-col gap-y-3 p-4 bg-white rounded-[10px] shadow border border-[#d9d3cf] flex-grow">
          <div className="flex justify-between">
            <span className="text-[#112532] text-[28px] font-semibold">
              {homeownerChartsData?.Counters?.Leads}
            </span>
            <img src="/Admin-assets/leads.svg" alt="" />
          </div>
          <h2 className="text-[#112532] text-sm">Leads</h2>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-4">
        {/* Installers Line Chart */}
        <div className="flex-1 p-6 bg-white rounded shadow-md max-h-[400px]">
          <h3 className="text-lg font-semibold mb-4">Top Locations</h3>
          <ResponsiveContainer>
            <BarChart
              data={homeownerChartsData?.chartData?.stateData}
              layout="vertical"
              margin={{ top: 20, right: 0, left: 0, bottom: 20 }}
            >
              <XAxis type="number" {...commonXAxisConfig} hide />
              <YAxis
                type="category"
                dataKey="state"
                width={130}
                {...commonYAxisConfig}
                tick={{
                  fill: "#112532",
                  fontSize: 14,
                  dy: -3,
                  fontWeight: 500,
                }}
              />
              <Tooltip cursor={{ fill: "#fdf0da" }} />
              <Bar dataKey="userCount" fill="#FFA500" barSize={20}>
                <LabelList
                  dataKey="userCount"
                  position="right"
                  fill="#112532"
                  fontSize={14}
                  fontWeight={500}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* All Deals Bar Chart */}
        <div className="flex-1 p-6 bg-white rounded shadow-md max-h-[400px]">
          <h3 className="text-lg font-semibold mb-4">Average System Size</h3>
          <ResponsiveContainer width="100%" height="90%">
            <BarChart
              data={homeownerChartsData?.chartData?.systemSizeData}
              margin={chartMargin}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                {...commonXAxisConfig}
                dataKey={target === "week" ? "weekName" : "week"}
                tickFormatter={(tick) => tick}
              />
              <YAxis
                {...commonYAxisConfig}
                tickFormatter={(tick) => (tick !== 0 ? `${tick}%` : tick)}
              />
              <Tooltip
                cursor={{ fill: "#fdf0da" }}
                formatter={(tick) => formatPrice(tick)}
              />
              <Bar dataKey="averageSystemSize" fill="#F59E0B" barSize={25} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

const AdminDashboard = () => {
  const [dashboardType, setDashboardType] = useState("installers");
  const [homeownerChartsData, setHomeownerChartsData] = useState([]);
  const [installersChartsData, setInstallersChartsData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    from: dayjs().startOf("year"),
    to: dayjs(),
  });

  const target = getDifferenceType(dateFilter?.from, dateFilter?.to);

  const fetchData = async () => {
    try {
      const installersChartsData = await POSTinstallersDashboardData({
        fromDate: dayjs(dateFilter.from).format("YYYY-MM-DD"),
        toDate: dayjs(dateFilter.to).format("YYYY-MM-DD"),
        target,
      });
      const homeownerChartsData = await POSThomeowenersDashboardData({
        fromDate: dayjs(dateFilter.from).format("YYYY-MM-DD"),
        toDate: dayjs(dateFilter.to).format("YYYY-MM-DD"),
        target,
      });
      setInstallersChartsData(installersChartsData);
      setHomeownerChartsData(homeownerChartsData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dashboardType, dateFilter.from, dateFilter.to]);

  return (
    <div className="px-6 pb-6 flex flex-col gap-4 ">
      <div className="flex justify-between pt-4">
        <h1 className="text-black text-[28px] font-semibold ">Dashboard</h1>

        <div className="flex items-center gap-x-10">
          <div className="flex gap-x-3 items-center">
            <h1 className="mb-2 text-sm md:text-base">From</h1>
            <DatePicker
              value={dateFilter.from}
              onChange={(newValue) =>
                setDateFilter((prev) => ({ from: newValue, to: null }))
              }
              maxDate={dateFilter.to || dayjs()}
              slotProps={{ field: { clearable: true } }}
              sx={{
                width: "200px",
                "& .MuiInputBase-root": {
                  height: "40px",
                  fontSize: "14px",
                },
                "& .MuiInputBase-input": {
                  padding: "8px 14px",
                },
              }}
            />
          </div>

          <div className="flex gap-x-3 items-center">
            <h1 className="mb-2 text-sm md:text-base">To</h1>
            <DatePicker
              value={dateFilter.to}
              onChange={(newValue) =>
                setDateFilter((prev) => ({ ...prev, to: newValue }))
              }
              minDate={dateFilter.from || dayjs().startOf("year")}
              maxDate={dayjs(dateFilter.from).endOf("year") || dayjs()}
              slotProps={{ field: { clearable: true } }}
              sx={{
                width: "200px",
                "& .MuiInputBase-root": {
                  height: "40px",
                  fontSize: "14px",
                },
                "& .MuiInputBase-input": {
                  padding: "8px 14px",
                },
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-x-5">
        <div
          className={`text-[#112532] cursor-pointer ${dashboardType === "installers" ? "font-semibold border-b-4 border-[#F59E0B]" : ""}`}
          onClick={() => setDashboardType("installers")}
        >
          Installers
        </div>
        <div
          className={`text-[#112532] cursor-pointer ${dashboardType === "homeowners" ? "font-semibold border-b-4 border-[#F59E0B]" : ""}`}
          onClick={() => setDashboardType("homeowners")}
        >
          Homeowners
        </div>
      </div>
      {dashboardType === "installers" ? (
        <InstallersCharts
          installersChartsData={installersChartsData}
          target={target}
        />
      ) : (
        <HomeownersCharts homeownerChartsData={homeownerChartsData} />
      )}
    </div>
  );
};

export default AdminDashboard;
