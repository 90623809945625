import React from "react";
import ExcelJS from "exceljs";
import { format } from "date-fns";

const ExcelExport = ({
  data,
  filename = "export",
  columns,
  filters = [],
  transformData,
  customStyles = {},
}) => {
  const defaultStyles = {
    headerFont: { bold: true },
    headerAlignment: { horizontal: "left" },
    dataAlignment: { horizontal: "left" },
  };

  const mergedStyles = { ...defaultStyles, ...customStyles };

  // Helper function to get nested object value
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    // Set up columns with specific widths and alignments
    worksheet.columns = columns.map((col) => ({
      header: col.header,
      key: col.key,
      width: col.width || 15,
      style: { alignment: { horizontal: "left" } },
    }));

    // Clear any default rows
    worksheet.spliceRows(0, worksheet.rowCount);

    // Add filters row if provided
    if (filters.length > 0) {
      const filterRow = worksheet.getRow(1);
      filterRow.getCell(1).value = "Filters:";
      filterRow.getCell(1).font = { bold: true };
      filterRow.getCell(2).value = filters.join(", ");
      filterRow.getCell(2).alignment = { horizontal: "left" };
      worksheet.mergeCells(`B1:${worksheet.lastColumn.letter}1`);
      worksheet.addRow([]); // Empty row after filters
    }

    // Add headers
    const headerRow = worksheet.addRow(columns.map((col) => col.header));
    headerRow.font = mergedStyles.headerFont;
    headerRow.alignment = mergedStyles.headerAlignment;

    // Transform and add data rows
    const transformedData = transformData ? transformData(data) : data;
    transformedData.forEach((row) => {
      const rowData = columns.map((col) => {
        // Get value (handles nested paths)
        let value = getNestedValue(row, col.key);

        // Apply transform function if exists
        if (col.transform) {
          value = col.transform(value, row);
        }

        // Handle special formatting
        if (col.format && value !== null && value !== undefined) {
          if (col.format === "date") {
            return format(new Date(value), "yyyy-MM-dd");
          }
          if (col.format === "currency") {
            return Number(value);
          }
        }

        return value;
      });

      const dataRow = worksheet.addRow(rowData);

      // Apply column-specific formatting
      columns.forEach((col, index) => {
        if (col.format === "currency") {
          dataRow.getCell(index + 1).numFmt = '"$"#,##0.00';
        }
      });
    });

    // Generate buffer and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${filename}_${format(new Date(), "yyyy-MM-dd")}.xlsx`;
    link.click();

    window.URL.revokeObjectURL(url);
  };

  return (
    <button
      onClick={exportToExcel}
      className="flex items-center gap-x-2 pt-3 px-2"
    >
      <img loading="lazy" className="w-5 h-5" src="/icons/XLS.svg" alt="" />
      Excel
    </button>
  );
};

export default ExcelExport;
