import dayjs from "dayjs";
import React from "react";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
dayjs.extend(relativeTime);

const Notification = ({ notification }) => {
  const notificationDate = dayjs(notification.createdAt).fromNow();
  const time = dayjs(notification.createdAt).format("hh:mm A");
  const isNotificationRead = notification.isRead;

  const generalData = useSelector((state) => state.generalInformation);

  console.log(notification);

  return (
    <div className="">
      <h1 className="px-4 text-[12px] text-[#244255] mb-2">
        {notificationDate}
      </h1>
      <div
        className={`flex gap-x-4 p-5 border-y-[1px] border-[#F7F6F5] relative ${!isNotificationRead ? "bg-[#FEF9EC] hover:bg-[#FFF7E3]" : "bg-white hover:bg-[#F7F6F5]"}`}
      >
        <div className="relative h-fit">
          <img
            loading="lazy"
            className="min-w-8 min-h-8"
            src="/icons/messageIcon.svg"
            alt=""
          />
          {!isNotificationRead && (
            <span className="w-2 h-2 bg-[#F59E0B] rounded-full absolute top-1/2 left-[-8px] translate-x-[-50%] translate-y-[-50%]"></span>
          )}
        </div>
        <div className="w-full">
          <div className="flex justify-between">
            <h1 className="text-[#112532] font-semibold">
              {notification.body}
            </h1>
            <div className="flex gap-x-2 items-center">
              <p className="text-[10px] text-[#94847B]">{time}</p>
            </div>
          </div>
          {/* <p className="text-[14px] text-[#112532]">
            Congratulations !
            <span className="font-bold">{generalData.fullName}</span>
            signed the contract and our team will contact you soon !
          </p> */}
        </div>
      </div>
      <hr className="border-[#ECECEE]" />
    </div>
  );
};

export default Notification;
