import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useMotionValueEvent,
  useScroll,
  useTransform,
  motion,
} from "framer-motion";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import Solar from "pages/logged-in/Solar";
import Footer from "./Footer";
import useResponsive from "hooks/useResponsive";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
    zIndex: "-1",
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    borderRadius: 1,
    borderStyle: "dashed",
    borderWidth: "1px 0px 0px 0px",
    borderColor: "#B2BBC0",
    ...theme.applyStyles("dark", {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

const steps = [
  {
    label: "Identify your location",
    description: "Select your home location to personalize your solar journey",
    icons: {
      default: "/icons/identfy-location.svg",
      active: "/icons/identfy-location.svg",
      done: "/icons/location-done.svg",
    },
    bigIcon: "/icons/identfy-location.svg",
    frameRange: [1, 69],
  },
  {
    label: "Home Details",
    description:
      "Provide us with your billing details and solar equipment preference",
    icons: {
      default: "/icons/home-details.svg",
      active: "/icons/home-active.svg",
      done: "/icons/home-done.svg",
    },
    bigIcon: "/icons/big-home.svg",
    frameRange: [70, 137],
  },
  {
    label: "Solar Setup",
    description: "Edit your solar design system according to your preference",
    icons: {
      default: "/icons/solar.svg",
      active: "/icons/solar-active.svg",
      done: "/icons/solar-done.svg",
    },
    bigIcon: "/icons/big-solar.svg",
    frameRange: [137, 206],
  },
  {
    label: "Lock Deal",
    description: "Finalize your solar investment with confidence",
    icons: {
      default: "/icons/lock-deal.svg",
      active: "/icons/lock-active.svg",
      done: "/lock-done.svg",
    },
    bigIcon: "/icons/big-lock.svg",
    frameRange: [192, 275],
  },
];

function ColorlibStepIcon(props) {
  const { active, completed, icon } = props;
  const stepIndex = Number(icon) - 1;

  const getIconPath = (index) => {
    if (completed) {
      return steps[index].icons.done;
    }
    if (active) {
      return steps[index].icons.active;
    }
    return steps[index].icons.default;
  };

  return (
    <img
      loading="lazy"
      className="cursor-pointer"
      src={getIconPath(stepIndex)}
      alt={steps[stepIndex].label}
    />
  );
}

const NextComponent = () => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const lastScrollPosition = useRef(0);
  const { isSm } = useResponsive();
  const imagesPath = isSm ? "second-animation-mobile" : "second-animation";

  const images = useMemo(() => {
    const loadedImages = [];
    for (let i = 24; i <= 275; i++) {
      const img = new Image();
      img.src = `/assets/${imagesPath}/${i}.webp`;
      loadedImages.push(img);
    }
    return loadedImages;
  }, [isSm]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      const setCanvasSize = () => {
        if (images[0] && images[0].complete) {
          canvas.width = images[0].width;
          canvas.height = images[0].height;
          render(1);
        }
      };

      setCanvasSize();
      images[0].onload = setCanvasSize;
    }
  }, [images]);

  const render = useCallback(
    (index) => {
      if (images[index - 1] && canvasRef.current) {
        const context = canvasRef.current.getContext("2d");
        context?.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        context?.drawImage(images[index - 1], 0, 0);
      }
    },
    [images]
  );

  const { scrollYProgress } = useScroll({
    target: containerRef,
  });

  const currentIndex = useTransform(scrollYProgress, [0, 1], [1, 275]);

  const getScrollPositionForFrame = useCallback((frame) => {
    const containerHeight = 8000; // Height of the NextComponent's container
    const firstAnimationHeight = 5000; // Height of the Home component's container
    const transitionFrame = 193; // Last frame of first animation

    // Calculate the scroll position relative to the second animation's container
    const relativePosition =
      frame > 69
        ? ((frame + 39) / 275) * containerHeight
        : ((frame + 24) / 275) * containerHeight;

    // Add the height of the first animation to position it after
    return firstAnimationHeight + relativePosition;
  }, []);

  const handleStepClick = useCallback((stepIndex) => {
    const targetFrame = steps[stepIndex].frameRange[0];
    const scrollPosition = getScrollPositionForFrame(targetFrame);

    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  }, []);

  useMotionValueEvent(currentIndex, "change", (latest) => {
    const currentFrame = Math.round(latest);
    render(currentFrame);

    const newStep = steps.findIndex(
      (step) =>
        currentFrame >= step.frameRange[0] && currentFrame <= step.frameRange[1]
    );

    if (newStep !== -1 && newStep !== currentStep) {
      setCurrentStep(newStep);
    }

    if (currentFrame > lastScrollPosition.current) {
      if (currentFrame >= 265) {
        setIsAnimationComplete(true);
      }
    } else {
      if (currentFrame < 265) {
        setIsAnimationComplete(false);
      }
    }

    lastScrollPosition.current = currentFrame;
  });

  return (
    <>
      <motion.div
        className={`h-screen z-0 ${isAnimationComplete ? "invisible" : "visible"}`}
        initial={{ opacity: 1 }}
        animate={{ opacity: isAnimationComplete ? 0 : 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="fixed top-0 w-full z-[10]">
          <div className="pt-10 sm:pt-16 md:pt-20 w-full sm:w-3/4 md:w-1/2 mx-auto z-50 px-4">
            <Stepper
              alternativeLabel
              activeStep={currentStep}
              connector={<ColorlibConnector />}
              className="overflow-x-auto"
            >
              {steps.map((step, index) => (
                <Step key={index} onClick={() => handleStepClick(index)}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div className="pt-10 sm:pt-16 md:pt-20 flex flex-col justify-center items-center space-y-3 sm:space-y-4 px-4">
            <div className="flex flex-col items-center space-y-2 sm:space-y-3">
              <img
                loading="lazy"
                className="w-10 sm:w-12 md:w-[52px]"
                src={steps[currentStep].bigIcon}
                alt={steps[currentStep].label}
              />
              <h2 className="text-[#112532] text-2xl sm:text-3xl md:text-4xl text-center">
                {steps[currentStep].label}
              </h2>
            </div>
            <p className="text-[#42535e] text-xl sm:text-2xl md:text-2xl font-light text-center max-w-xl px-4">
              {steps[currentStep].description}
            </p>
          </div>
        </div>

        <motion.div className="fixed bottom-0 left-0 w-screen h-1/2 flex justify-center items-end">
          <canvas ref={canvasRef} className="w-full h-full object-contain" />
        </motion.div>

        <div ref={containerRef} className="h-[8000px]" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isAnimationComplete ? 1 : 0 }}
        transition={{ duration: 0.5 }}
        className={`fixed top-0 left-0 w-full h-screen z-50 overflow-y-auto scrollbar-hide ${
          isAnimationComplete ? "visible" : "invisible"
        }`}
        style={{
          height: "100dvh",
          overflowY: "auto",
          WebkitOverflowScrolling: "touch",
        }}
      >
        <div className="min-h-dvh pb-12">
          <Solar />
        </div>
      </motion.div>
    </>
  );
};

export default NextComponent;
