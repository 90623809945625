import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, InputAdornment, TextField } from "@mui/material";
import AuthLayout from "layout/AuthLayout";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { homeOwnerResetPassword } from "services/homeOwnerServices";
import { installerChangePassword } from "services/installerServices";
import { resetChangePasswordSchema } from "validation-schema/homeOwner";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState({
    new: false,
    confirm: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = useParams();
  const isInsttaler =
    window.location.pathname.split("/")[1] === "installer-change-password";

  const onSubmit = async (data) => {
    if (isInsttaler) {
      try {
        setIsSubmitting(true);
        const res = await installerChangePassword(
          params.resetToken,
          data.password
        );
        toast.success(res.message);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        setIsSubmitting(true);
        const res = await homeOwnerResetPassword(
          params.resetToken,
          data.password
        );
        toast.success(res.message);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(resetChangePasswordSchema),
    defaultValues: {
      password: "",
      ConfirmNewPassword: "",
    },
    mode: "onChange",
  });

  return (
    <AuthLayout>
      <div className="flex-1 py-5 md:px-[60px] lg:px-[120px] rounded bg-white w-full absolute top-1/4 md:static md:rounded-none">
        <h1 className="mb-12 text-[28px] text-[#244255] font-semibold">
          Reset Password
        </h1>
        <div className="flex flex-col gap-y-8">
          <div className="">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  error={errors.password && true}
                  {...field}
                  helperText={errors.password && errors.password.message}
                  sx={{
                    "& label.Mui-focused": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#66a1c7",
                      },
                    },
                  }}
                  className="w-full"
                  label="Password"
                  id="outlined-start-adornment"
                  type={showPassword.new ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword.new ? (
                          <VisibilityOffIcon
                            className="cursor-pointer"
                            onClick={() => {
                              setShowPassword({ ...showPassword, new: false });
                            }}
                          />
                        ) : (
                          <VisibilityIcon
                            className="cursor-pointer"
                            onClick={() => {
                              setShowPassword({ ...showPassword, new: true });
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              name="ConfirmNewPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  error={errors.ConfirmNewPassword && true}
                  {...field}
                  helperText={
                    errors.ConfirmNewPassword &&
                    errors.ConfirmNewPassword.message
                  }
                  sx={{
                    "& label.Mui-focused": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#66a1c7",
                      },
                    },
                  }}
                  className="w-full"
                  label="Confirm New Password"
                  id="outlined-start-adornment"
                  type={showPassword.confirm ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword.confirm ? (
                          <VisibilityOffIcon
                            className="cursor-pointer"
                            onClick={() => {
                              setShowPassword({
                                ...showPassword,
                                confirm: false,
                              });
                            }}
                          />
                        ) : (
                          <VisibilityIcon
                            className="cursor-pointer"
                            onClick={() => {
                              setShowPassword({
                                ...showPassword,
                                confirm: true,
                              });
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <Button
            sx={{
              backgroundColor: "#112532",
              boxShadow: "none",
              ":hover": { backgroundColor: "#112532" },
            }}
            variant="contained"
            className="w-full"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
          >
            Reset Password
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
