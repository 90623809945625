import { Radio } from "@mui/material";
import CustomStepper from "components/MUI/CustomStepper";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateHomeownerForm } from "../../../redux/actions";
import { tierImage } from "utils/constant";
import { formatPrice } from "utils/formatNumber";
import { Link } from "react-router-dom";

const YourSolarDesign = ({
  control,
  errors,
  onSubmit,
  onBack,
  currentStep,
  renderMap,
  isValid,
  tiers,
  solarInsights,
  isLoading,
}) => {
  const formData = useSelector((state) => state.homeownerForm);
  const dispatch = useDispatch();

  // Safe access to current solar insights with default values
  const getCurrentSolarInsights = () => {
    if (!solarInsights?.panelResults || !formData.panels_number) {
      return {
        system_size: 0,
        Estimated_Annual_production: 0,
        estimated_offset: 0,
        left_over_elec_bill: 0,
      };
    }
    const index = Math.min(
      Math.max(0, formData?.panels_number - 10),
      solarInsights?.panelResults?.length - 1
    );
    return solarInsights?.panelResults[index];
  };

  const currentSolarInsights = getCurrentSolarInsights();

  useEffect(() => {
    if (solarInsights?.panelResults?.length && formData.panels_number) {
      dispatch(
        updateHomeownerForm({
          systemSize: currentSolarInsights?.system_size,
        })
      );
    }
  }, [formData.panels_number, solarInsights]);

  useEffect(() => {
    if (solarInsights?.optimalPanelsCount && formData.panels_number === 10) {
      dispatch(
        updateHomeownerForm({
          panels_number: solarInsights.optimalPanelsCount,
        })
      );
    }
  }, [solarInsights]);

  const handlePanelDecrease = () => {
    if (formData.panels_number > 10) {
      dispatch({ type: "DECREASE_PANELS_NUMBER" });
    }
  };

  const handlePanelIncrease = () => {
    if (formData.panels_number < (solarInsights?.maxAllowablePanels || 0)) {
      dispatch({ type: "INCREASE_PANELS_NUMBER" });
    }
  };

  if (!solarInsights) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <p>Loading solar design data...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-col gap-y-2">
        <h1 className="text-[#112532] text-lg lg:text-[33px] font-bold">
          Your Solar Design
        </h1>
        <p className="text-[#112532] lg:min-w-[90%] lg:max-w-[90%]">
          Based on your location{" "}
          <span className="font-semibold">{formData.location?.address}</span> we
          recommended a base of{" "}
          <span className="font-semibold">
            {formatPrice(formData.systemSize || 0)}
          </span>{" "}
          kW to cover your current bill of{" "}
          <span className="font-semibold">${formData.bill_value || 0}</span>
        </p>
      </div>
      <div className="w-full h-[350px] rounded-lg shadow overflow-hidden md:hidden">
        {renderMap(false)}
      </div>
      <div className="overflow-x-scroll scrollbar-hide lg:min-w-full">
        <div className="flex flex-col gap-4">
          <div>
            <p className="flex items-center gap-x-2 text-[#112532] font-semibold">
              <img
                src="/icons/solar-energy-svgrepo-com.svg"
                alt="Solar Energy"
              />
              Solar design
            </p>
            <div className="flex gap-x-2 w-[554px] md:w-full">
              <div className="flex flex-col gap-y-2 px-4 py-3 bg-white rounded-lg border border-[#d9d3cf] lg:min-w-[30%] lg:max-w-[50%]">
                <div className="flex gap-x-3 w-fit">
                  <button
                    type="button"
                    onClick={handlePanelDecrease}
                    className="cursor-pointer disabled:opacity-50 disabled:cursor-default"
                    disabled={
                      !formData.panels_number || formData.panels_number <= 10
                    }
                  >
                    <img
                      className="md:w-6"
                      src="/icons/minus.svg"
                      alt="Decrease"
                    />
                  </button>
                  <p className="text-[#021b2a] text-[28px] font-bold w-12 text-center">
                    {formData.panels_number || 0}
                  </p>
                  <button
                    type="button"
                    onClick={handlePanelIncrease}
                    className="cursor-pointer disabled:opacity-50 disabled:cursor-default"
                    disabled={
                      !formData.panels_number ||
                      formData.panels_number >=
                        (solarInsights?.maxAllowablePanels || 0)
                    }
                  >
                    <img
                      className="md:w-6"
                      src="/icons/plus-orange.svg"
                      alt="Increase"
                    />
                  </button>
                </div>
                <p className="text-[#112532] text-sm">Number of Solar Panels</p>
              </div>
              <div className="flex flex-col gap-y-2 px-4 py-3 bg-white rounded-lg border border-[#d9d3cf] lg:min-w-[30%] lg:max-w-[30%]">
                <p className="text-[#112532] text-[28px] font-bold">
                  {formatPrice(currentSolarInsights?.system_size || 0)}
                  <span className="text-[#021b2a]/50 text-xs">kW</span>
                </p>
                <p className="text-[#112532] text-xs">System size</p>
              </div>
              <div className="flex flex-col gap-y-2 px-4 py-3 bg-white rounded-lg border border-[#d9d3cf] lg:min-w-[30%] lg:max-w-[50%]">
                <p className="text-[#112532] text-[28px] font-bold">
                  {formatPrice(
                    currentSolarInsights?.Estimated_Annual_production || 0
                  )}
                  <span className="text-[#021b2a]/50 text-sm">kWh</span>
                </p>
                <p className="text-[#112532] text-sm">
                  Estimate annual production
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="flex items-center gap-x-2 text-[#112532] font-semibold">
              <img src="/icons/transaction-svgrepo-com.svg" alt="Transaction" />
              Bills coverage
            </p>
            <div className="flex gap-x-2 w-[560px] md:w-full">
              <div className="flex flex-col gap-y-2 px-4 py-3 bg-white rounded-lg border border-[#d9d3cf] lg:min-w-[30%] lg:max-w-[50%]">
                <p className="text-[#021b2a] text-[28px] font-bold">
                  {formatPrice(
                    (currentSolarInsights?.estimated_offset || 0) * 100
                  )}
                  %
                </p>
                <p className="text-[#112532] text-sm">Estimated offset</p>
              </div>
              <div className="flex flex-col gap-y-2 px-4 py-3 bg-white rounded-lg border border-[#d9d3cf] lg:min-w-[30%] lg:max-w-[30%]">
                <p className="text-[#112532] text-[28px] font-bold">
                  ${formData.bill_value || 0}
                </p>
                <p className="text-[#112532] text-sm">Old bill</p>
              </div>
              <div className="flex flex-col gap-y-2 px-4 py-3 bg-white rounded-lg border border-[#d9d3cf] lg:min-w-[30%] lg:max-w-[50%]">
                <p className="text-[#112532] text-[28px] font-bold">
                  ${formatPrice(currentSolarInsights?.left_over_elec_bill || 0)}
                </p>
                <p className="text-[#112532] text-sm">
                  Leftover electricity bill amount
                </p>
              </div>
            </div>
          </div>
          <p
            className="text-right text-[#112532] text-sm underline cursor-pointer"
            onClick={() => {
              dispatch(
                updateHomeownerForm({
                  panels_number: solarInsights.optimalPanelsCount,
                })
              );
            }}
          >
            Reset changes
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-y-6">
        <h1 className="text-[#112532] text-lg font-bold">
          Continue Customizing Your Panels
        </h1>
        <CustomStepper
          width="calc(100%);"
          steps={["Home Details", "Personal Details", "System Tiers"]}
          activeStepSolar={currentStep}
          orientation="horizontal"
        />
        <div className="flex flex-col gap-y-2">
          <h2 className="text-[#112532] text-[19px] font-semibold">
            System Tiers
          </h2>
        </div>
        <div className="flex flex-col gap-y-4">
          {tiers?.length > 0 ? (
            <Controller
              name="tier"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <p className="text-[#112532] text-sm">
                    Choose the system that fits you the best
                  </p>
                  {tiers.map((tier) => (
                    <div
                      key={tier.system_type}
                      onClick={() => onChange(tier.system_type)}
                      className={`flex flex-col md:flex-row p-3 border border-[#c7cace] rounded-md cursor-pointer ${
                        value === tier.system_type
                          ? "bg-[#f0fafb] border-[#3881b0]"
                          : ""
                      }`}
                    >
                      <Radio
                        size="small"
                        className="-ml-2 self-start"
                        value={tier.system_type}
                        checked={value === tier.system_type}
                        onChange={() => onChange(tier.system_type)}
                      />
                      <div className="w-full">
                        <div className="flex items-center gap-x-2 font-semibold">
                          <img
                            src={tierImage(tier.system_type)}
                            alt={tier.system_type}
                          />
                          <span>{tier.system_type}</span>
                        </div>
                        <div className="flex justify-between w-full">
                          <p className="text-slate-500 text-xs">
                            {tier.description}
                          </p>
                          <div className="hidden lg:block text-center text-[#112532] font-bold px-2 py-1 rounded border border-[#eceae8]">
                            $
                            {formatPrice(tier?.minSystemPriceForMinPanels || 0)}{" "}
                            - $
                            {formatPrice(tier?.maxSystemPriceForMaxPanels || 0)}
                          </div>
                        </div>
                        <div className="flex gap-x-2">
                          <div className="flex items-center p-1 bg-[#f8fafc]">
                            <img
                              className="w-5 h-5"
                              src="/icons/solar-energy-svgrepo-com.svg"
                              alt="Solar Energy"
                            />
                            <span className="text-[11px]">{tier.slogan}</span>
                          </div>
                          <div className="flex items-center p-1 bg-[#f8fafc]">
                            <img src="/icons/shield.svg" alt="Warranty" />
                            <span className="text-[11px]">
                              <strong>{tier.years_warranty}</strong> years
                              warranty
                            </span>
                          </div>
                        </div>
                        <div className="block lg:hidden text-center text-[#112532] font-bold px-2 py-1 rounded border border-[#eceae8] w-fit">
                          ${formatPrice(tier?.minSystemPriceForMinPanels || 0)}{" "}
                          - $
                          {formatPrice(tier?.maxSystemPriceForMaxPanels || 0)}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            />
          ) : (
            <div className="flex flex-col items-center md:flex-row gap-8">
              <img
                src="/images/notiers.png"
                alt=""
                className="w-32 h-32 md:w-auto md:h-auto"
              />
              <div className="flex flex-col gap-y-6 text-center md:text-left">
                <h2 className="text-[#244255] text-sm md:text-xl font-semibold">
                  There are no systems available at the moment.
                </h2>
                <p className="text-[#112532] text-sm">
                  Please try again later or contact us for more information.
                </p>
                <Link
                  to={"/contact-us"}
                  className="w-full md:w-fit mx-auto md:mx-0 text-center text-slate-50 text-sm font-bold p-2 px-10 py-3 bg-[#112532] rounded-[5px]"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row-reverse justify-end gap-x-2 gap-y-4">
        <button
          onClick={onSubmit}
          disabled={!isValid || !tiers?.length || isLoading}
          className="px-10 py-3 bg-[#112532] rounded-[5px] text-[#fffefb] font-bold disabled:opacity-50"
        >
          Next
        </button>
        <button
          type="button"
          onClick={onBack}
          className="px-10 py-3 bg-[#fffefb] rounded-[5px] border border-[#112532] font-bold"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default YourSolarDesign;
