import React from "react";
import { Skeleton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const HomeOwnerDashboardSkeleton = () => {
  return (
    <div className="flex h-full">
      <div className="flex-1 flex flex-col gap-y-5 p-6 pt-0">
        {/* Solar Journey Accordion */}
        <div
          className="px-8 py-6 rounded-lg"
          style={{
            backgroundImage: `url('/images/accordion.png')`,
            backgroundSize: "cover",
            backgroundPosition: "top",
          }}
        >
          <div className="flex justify-between items-center">
            <Skeleton
              variant="text"
              width={200}
              height={40}
              sx={{ bgcolor: "rgba(255, 255, 255, 0.3)" }}
            />
            <ExpandMoreIcon
              sx={{
                fill: "rgba(255, 255, 255, 0.3)",
                width: "32px",
                height: "32px",
              }}
            />
          </div>

          <div className="flex justify-between gap-x-4 mt-4">
            {[1, 2, 3].map((step) => (
              <div
                key={step}
                className="flex flex-col gap-y-2 p-4 bg-[#FFFFFF0A] rounded-md w-1/3"
              >
                <Skeleton
                  variant="circular"
                  width={24}
                  height={24}
                  sx={{ bgcolor: "rgba(255, 255, 255, 0.3)", mb: 2 }}
                />
                <Skeleton
                  variant="text"
                  width="80%"
                  height={24}
                  sx={{ bgcolor: "rgba(255, 255, 255, 0.3)" }}
                />
                <Skeleton
                  variant="text"
                  width="100%"
                  height={20}
                  sx={{ bgcolor: "rgba(255, 255, 255, 0.3)" }}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Deal Overview Section */}
        <Skeleton variant="text" width={200} height={40} />

        <div className="flex gap-x-4">
          {/* Installer Details Card */}
          <div className="flex flex-col px-8 py-6 gap-y-5 bg-white border-[0.5px] border-[#D9D3CF] rounded-lg flex-grow">
            <Skeleton variant="text" width={150} height={30} />
            <div className="flex gap-x-3">
              <Skeleton variant="circular" width={56} height={56} />
              <div className="flex flex-col gap-y-3 flex-grow">
                <Skeleton variant="text" width={200} height={30} />
                <div className="flex items-center gap-x-2">
                  <Skeleton variant="circular" width={20} height={20} />
                  <Skeleton variant="text" width={60} height={24} />
                </div>
                <div className="flex gap-x-3">
                  <Skeleton variant="rounded" width={120} height={32} />
                  <Skeleton variant="rounded" width={140} height={32} />
                </div>
                <div className="flex flex-col gap-y-2">
                  <Skeleton variant="text" width="90%" height={24} />
                  <Skeleton variant="text" width="70%" height={24} />
                </div>
              </div>
            </div>
          </div>

          {/* System Details Card */}
          <div className="flex flex-col px-8 py-6 gap-y-5 bg-white border-[0.5px] border-[#D9D3CF] rounded-lg flex-grow">
            <Skeleton variant="text" width={150} height={30} />
            <div className="flex gap-x-3">
              <Skeleton variant="circular" width={56} height={56} />
              <div className="flex flex-col gap-y-3 flex-grow">
                <div className="flex justify-between">
                  <Skeleton variant="text" width={100} height={30} />
                  <Skeleton variant="rounded" width={100} height={32} />
                </div>
                <Skeleton variant="text" width="90%" height={24} />
                <div className="flex gap-x-3">
                  <Skeleton variant="text" width={140} height={24} />
                  <Skeleton variant="text" width={140} height={24} />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-3">
              <Skeleton variant="text" width={120} height={24} />
              <div className="py-4 px-6 bg-[#FEF9EC] flex justify-between items-center">
                <Skeleton variant="text" width={100} height={30} />
                <Skeleton variant="text" width={200} height={24} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* What's Next Section */}
      <div className="border-1 px-8 py-6 h-full rounded-lg w-[27%] bg-white mr-6">
        <Skeleton variant="text" width={150} height={30} className="mb-4" />
        <div className="flex gap-x-2 mb-4">
          {[1, 2, 3].map((tab) => (
            <Skeleton key={tab} variant="rounded" width={80} height={40} />
          ))}
        </div>
        <div className="flex flex-col gap-y-4">
          {[1, 2].map((item) => (
            <div
              key={item}
              className="flex flex-col gap-y-4 bg-[#F7F6F5] rounded-lg p-4"
            >
              <div className="flex items-center justify-between">
                <div className="flex gap-x-2">
                  <Skeleton variant="circular" width={24} height={24} />
                  <div className="flex flex-col gap-y-2">
                    <Skeleton variant="text" width={150} height={24} />
                    <Skeleton variant="text" width={200} height={20} />
                  </div>
                </div>
                <Skeleton variant="circular" width={24} height={24} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeOwnerDashboardSkeleton;
