import { useState, useEffect, useCallback } from "react";

const usePreventScroll = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const preventScrollKeys = useCallback((e) => {
    const keys = [
      "ArrowUp",
      "ArrowDown",
      "PageUp",
      "PageDown",
      "Space",
      "Home",
      "End",
    ];
    if (keys.includes(e.key)) {
      e.preventDefault();
    }
  }, []);

  const preventScroll = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      // Add event listeners to prevent scrolling
      window.addEventListener("wheel", preventScroll, { passive: false });
      window.addEventListener("touchmove", preventScroll, { passive: false });
      window.addEventListener("keydown", preventScrollKeys);
    } else {
      // Remove event listeners to allow scrolling
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
      window.removeEventListener("keydown", preventScrollKeys);
    }

    return () => {
      // Cleanup listeners when component unmounts
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
      window.removeEventListener("keydown", preventScrollKeys);
    };
  }, [isMenuOpen, preventScroll, preventScrollKeys]);

  return {
    isMenuOpen,
    onMenuOpen: () => setIsMenuOpen(true),
    onMenuClose: () => setIsMenuOpen(false),
  };
};

export default usePreventScroll;
