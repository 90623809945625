import { fDateTimeSuffixArray } from "utils/formatTime";

const Comment = ({ reviewer, isMyReview, setIsEditing, setShowInput }) => {
  const fullName = reviewer?.User
    ? `${reviewer.User.first_name} ${reviewer.User.last_name}`
    : "Anonymous";
  const profileImage =
    reviewer?.User?.profile_img || "/images/default-profile.svg";
  const stars = reviewer?.stars || 0;

  let date = "";
  let time = "";
  if (reviewer?.createdAt && !isNaN(new Date(reviewer.createdAt).getTime())) {
    [date, time] = fDateTimeSuffixArray(reviewer.createdAt) || ["", ""];
  }

  return (
    <div className="flex gap-x-4 md:w-1/2">
      <img
        loading="lazy"
        className="w-8 h-8 rounded-full"
        src={profileImage}
        alt={fullName}
      />
      <div className="flex flex-col justify-center gap-y-1">
        <h1 className="text-[#112532] text-[14px] font-semibold">{fullName}</h1>
        <div className="flex flex-col gap-y-2 gap-x-2">
          <div className=" flex gap-2">
            {/* Render stars */}
            <div className="flex">
              {Array.from({ length: stars }, (_, index) => (
                <img
                  loading="lazy"
                  key={index}
                  className="w-3"
                  src="/icons/small-star.svg"
                  alt="star"
                />
              ))}
            </div>
            {/* Render date and time if available */}
            <span className="text-[10px] text-[#A69991]">{date}</span>
            <span className="text-[10px] text-[#A69991]">{time}</span>
            {isMyReview && (
              <img
                onClick={() => {
                  setIsEditing(true);
                  setShowInput(true);
                }}
                src="/icons/edit-pen.svg"
                alt=""
                className=" cursor-pointer"
              />
            )}
          </div>
          <p className="font-semibold">{reviewer?.comment}</p>
        </div>
      </div>
    </div>
  );
};

export default Comment;
