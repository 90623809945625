import { useSelector } from "react-redux";
import CustomStepper from "components/MUI/CustomStepper";
import Header from "components/shared/Header";
import InstallerRegisterForm from "../../components/installer/auth/InstallerRegisterForm";

const InstallerRegister = () => {
  const formData = useSelector((state) => state.installerForm);

  return (
    <div className="">
      <Header />
      <div className="w-full bg-white">
        <div className="flex flex-col gap-y-10 px-4 md:px-20 py-8 w-full h-full">
          <h1 className="text-[#112532] text-3xl md:text-[55px] font-bold">
            Installer Registration
          </h1>
          <div className="w-full h-full flex flex-col lg:flex-row lg:justify-between lg:gap-x-40">
            {/* Form Container */}
            <div className="flex-1 ">
              <CustomStepper
                width={"100%"}
                steps={[
                  "Personal Details",
                  "Business Details",
                  "Equipment List",
                ]}
                activeStepSolar={formData.currentStep}
                orientation="horizontal"
              />
              <div className=" md:hidden lg:w-[600px] lg:self-start lg:mt-40 mb-8 lg:mb-0">
                <img
                  className="w-full h-full object-contain"
                  src="/images/installer-reg.png"
                  alt=""
                />
              </div>
              <h2 className="text-xl md:text-[23px] font-semibold mb-5">
                {formData.currentStep === 0 && "Personal Details"}
                {formData.currentStep === 1 && "Business Details"}
                {formData.currentStep === 2 && "Equipment List"}
              </h2>
              <InstallerRegisterForm />
            </div>

            {/* Image Container */}
            <div className="hidden md:block lg:w-[600px] lg:self-start lg:mt-40 mb-8 lg:mb-0">
              <img
                className="w-full h-full object-contain"
                src="/images/installer-reg.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallerRegister;
