import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { POSTadminChangeAuthenticatedPassword } from "services/AdminServices";
import { POSTchangeHomeOwnerAuthenticatedPassword } from "services/homeOwnerServices";
import { POSTchangeAuthenticatedPassword } from "services/installerServices";
import { authenticatedChangePasswordSchema } from "services/shared";

const ChangePassword = () => {
  const { setDialogData } = useOutletContext();
  const navigate = useNavigate();
  const userRole = localStorage.getItem("USER");
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const handleClickShowPassword = (inputName) =>
    setShowPassword({ ...showPassword, [inputName]: !showPassword[inputName] });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(authenticatedChangePasswordSchema, {
      abortEarly: false,
    }),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      if (userRole === "homeOwner") {
        const res = await POSTchangeHomeOwnerAuthenticatedPassword(
          data.currentPassword,
          data.newPassword
        );
        toast.success(res.message);
        reset();
      } else if (userRole === "installer") {
        const res = await POSTchangeAuthenticatedPassword(
          data.currentPassword,
          data.newPassword
        );
        toast.success(res.message);
        reset();
      } else if (userRole === "admin") {
        const res = await POSTadminChangeAuthenticatedPassword(
          data.currentPassword,
          data.newPassword
        );
        toast.success(res.message);
        reset();
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const password = watch("newPassword", "");
  const passwordIsValid = !errors.newPassword && password;

  useEffect(() => {
    if (!passwordIsValid) {
      setValue("confirmPassword", "");
    }
  }, [passwordIsValid]);

  return (
    <div className="min-h-full w-full">
      <div className="flex flex-col lg:flex-row bg-white p-4 lg:p-8 rounded-lg md:border border-[#D9D3CF]">
        <div className="flex-1 flex flex-col gap-y-4 lg:gap-y-6">
          <h1 className="text-lg lg:text-[19px] text-[#112532] font-semibold">
            Change Password
          </h1>

          <div className="flex flex-col gap-y-4 w-full">
            <Controller
              name="currentPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.currentPassword}
                  helperText={
                    errors.currentPassword && errors.currentPassword.message
                  }
                  sx={{
                    "& label.Mui-focused": { color: "black" },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": { borderColor: "#66a1c7" },
                    },
                  }}
                  className="w-full"
                  label="Current Password"
                  type={showPassword.current ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword.current ? (
                          <VisibilityOffIcon
                            className="cursor-pointer"
                            onClick={() => handleClickShowPassword("current")}
                          />
                        ) : (
                          <VisibilityIcon
                            className="cursor-pointer"
                            onClick={() => handleClickShowPassword("current")}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword && errors.newPassword.message}
                  sx={{
                    "& label.Mui-focused": { color: "black" },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": { borderColor: "#66a1c7" },
                    },
                  }}
                  className="w-full"
                  label="New Password"
                  type={showPassword.new ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword.new ? (
                          <VisibilityOffIcon
                            className="cursor-pointer"
                            onClick={() => handleClickShowPassword("new")}
                          />
                        ) : (
                          <VisibilityIcon
                            className="cursor-pointer"
                            onClick={() => handleClickShowPassword("new")}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>

          {!passwordIsValid ? (
            <div className="flex flex-col gap-y-2 lg:gap-y-3 p-3 text-sm lg:text-[14px] rounded border border-[#ECECEE]">
              <h1>Your password must contain:</h1>
              <p
                className={
                  password.length >= 8
                    ? "text-[#018340] flex gap-x-2"
                    : "text-[#112532] flex gap-x-2"
                }
              >
                <img
                  loading="lazy"
                  className={password.length >= 8 ? "visible" : "invisible"}
                  src="/icons/bx-check.svg"
                  alt=""
                />
                At least 8 characters
              </p>
              <p
                className={
                  /[a-z]/.test(password)
                    ? "text-[#018340] flex gap-x-2"
                    : "text-[#112532] flex gap-x-2"
                }
              >
                <img
                  loading="lazy"
                  className={/[a-z]/.test(password) ? "visible" : "invisible"}
                  src="/icons/bx-check.svg"
                  alt=""
                />
                At least one lowercase letter
              </p>
              <p
                className={
                  /[A-Z]/.test(password)
                    ? "text-[#018340] flex gap-x-2"
                    : "text-[#112532] flex gap-x-2"
                }
              >
                <img
                  loading="lazy"
                  className={/[A-Z]/.test(password) ? "visible" : "invisible"}
                  src="/icons/bx-check.svg"
                  alt=""
                />
                At least one uppercase letter
              </p>
              <p
                className={
                  /\d/.test(password)
                    ? "text-[#018340] flex gap-x-2"
                    : "text-[#112532] flex gap-x-2"
                }
              >
                <img
                  loading="lazy"
                  className={/\d/.test(password) ? "visible" : "invisible"}
                  src="/icons/bx-check.svg"
                  alt=""
                />
                At least one number
              </p>
              <p
                className={
                  /[@$!%*?&]/.test(password)
                    ? "text-[#018340] flex gap-x-2"
                    : "text-[#112532] flex gap-x-2"
                }
              >
                <img
                  loading="lazy"
                  className={
                    /[@$!%*?&]/.test(password) ? "visible" : "invisible"
                  }
                  src="/icons/bx-check.svg"
                  alt=""
                />
                At least one special character
              </p>
            </div>
          ) : (
            <p className="p-4 flex text-[#018340] rounded border border-[#ECECEE]">
              <img loading="lazy" src="/icons/bx-check.svg" alt="" />
              New password is good to go!
            </p>
          )}

          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!passwordIsValid}
                error={!!errors.confirmPassword}
                helperText={
                  errors.confirmPassword && errors.confirmPassword.message
                }
                sx={{
                  "& label.Mui-focused": { color: "black" },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": { borderColor: "#66a1c7" },
                  },
                }}
                className="w-full"
                label="Confirm New Password"
                type={showPassword.confirm ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword.confirm && passwordIsValid ? (
                        <VisibilityOffIcon
                          className="cursor-pointer"
                          onClick={() => handleClickShowPassword("confirm")}
                        />
                      ) : (
                        <VisibilityIcon
                          className="cursor-pointer"
                          onClick={() => handleClickShowPassword("confirm")}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className="hidden lg:block w-1/2"></div>
      </div>

      <div className="flex flex-col-reverse sm:flex-row justify-end gap-3 mt-4">
        <button
          className="py-3 px-6 lg:px-10 border border-[#112532] rounded w-full sm:w-auto"
          onClick={() => {
            if (!isValid)
              navigate(
                userRole === "installer"
                  ? "/dashboard-installer"
                  : userRole === "homeOwner"
                    ? "/dashboard"
                    : "/admin-dashboard"
              );
            setDialogData({
              isDialogOpen: true,
              title: "Discard Changes?",
              subTitle:
                "Changes will not be saved, are you sure you want to continue?",
              onConfirm: () =>
                navigate(
                  userRole === "installer"
                    ? "/dashboard-installer"
                    : userRole === "homeOwner"
                      ? "/dashboard"
                      : "/admin-dashboard"
                ),
            });
          }}
        >
          Discard
        </button>
        <button
          className={`py-3 px-6 lg:px-10 text-white bg-[#112532] rounded w-full sm:w-auto ${
            !isValid ? "opacity-50" : ""
          }`}
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          Change Password
        </button>
      </div>
      <div className="h-[50px]"></div>
    </div>
  );
};

export default ChangePassword;
