import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Box,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ExcelExport from "components/installer/ExcelExport";
import ExportStyledPDF from "components/installer/ExportStyledPDF";
import TableSkeleton from "components/skeleton/shared/TableSkeleton";
import dayjs from "dayjs";
import { useDebounce } from "hooks/useDebounce";
import useResponsive from "hooks/useResponsive";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { POSTallHomeownersData } from "services/AdminServices";
import { customDateValidate, dealStatus } from "utils/constant";

// Styled Components
const StyledTableCell = styled(TableCell)(
  ({ theme, "data-frozen": frozen, index }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      color: "black",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: "500",
    },
    ...(frozen && {
      position: "sticky",
      left: index === 0 ? "-2px" : "100.08px",
      zIndex: 2,
      backgroundColor: "white",
      filter: "drop-shadow(8px 0 6px rgba(203, 197, 180, 0.21))",
      borderRight: "1px solid rgba(203, 197, 180, 0.15)",
    }),
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderWidth: "8px 0px",
  border: "8px solid white",
}));

const TableWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  overflowX: "auto",
  [theme.breakpoints.down("sm")]: {
    "& table": {
      minWidth: "100%",
    },
    "& th, & td": {
      width: { sm: "75px", md: "150px" },
    },
    "& th:first-of-type, & td:first-of-type, & th:nth-of-type(2), & td:nth-of-type(2)":
      {
        position: "sticky",
        backgroundColor: "white",
      },
  },
}));

const columns = [
  {
    header: "ID",
    key: "homeOwner_id",
    width: 10,
  },
  {
    header: "Name",
    key: "User.first_name",
    width: 20,
    transform: (value, row) => `${value} ${row.User.last_name}`,
  },
  {
    header: "Installer Name",
    key: "Installer.business_name",
    width: 25,
  },
  {
    header: "Deal Type",
    key: "pricing_method",
    width: 15,
  },
  {
    header: "Deal Status",
    key: "status",
    width: 15,
  },
  {
    header: "System Type",
    key: "system_type",
    width: 15,
  },
  {
    header: "Location",
    key: "User.location",
    width: 30,
  },
  {
    header: "Date",
    key: "createdAt",
    width: 15,
    format: "date",
  },
  {
    header: "Price",
    key: "User_solar_insight.finalPrice",
    width: 15,
    format: "currency",
  },
];

const filterLabels = {
  deal_type: {
    Contract: "Contract",
    Lead: "Lead",
  },
  status: {
    0: "Active",
    1: "Closed Won",
    2: "Closed Lost",
  },
  system_type: {
    Basic: "Basic",
    Standard: "Standard",
    Premium: "Premium",
  },
};

const AllHomeownersTable = () => {
  const [filters, setFilters] = useState({
    used: false,
    deal_type: [],
    status: [],
    system_type: [],
    from_date: "",
    to_date: "",
    search: "",
  });
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [sortedRows, setSortedRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allHomeownersData, setAllHomeownersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRadio, setDateRadio] = useState();
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const homeownersData = await POSTallHomeownersData(filters);
      setAllHomeownersData(homeownersData);
    } catch (error) {
      console.error("Error fetching homeowners data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("homeownersFilters");
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      setFilters({ ...parsedFilters, search: "" });
      setAppliedFilters(parsedFilters);
    }
  }, []);

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("homeownersFilters");
    if (appliedFilters.used || filters.search || !savedFilters) {
      fetchData();
    }
  }, [appliedFilters, filters.search]);

  const searchDebounce = useDebounce(searchData, 1500);

  const handleSearchChange = (e) => {
    searchDebounce(e);
  };

  function searchData(e) {
    sessionStorage.removeItem("allHomeownersPageNumber");
    updateFilters({
      search: e.target.value === "" ? undefined : e.target.value,
    });
  }

  const updateFilters = (newFilterValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilterValues,
      used: true,
    }));
  };

  const handleCheckboxChange = (e, filterKey, value) => {
    const { checked } = e.target;
    updateFilters({
      [filterKey]: checked
        ? [...filters[filterKey], value]
        : filters[filterKey].filter((item) => item !== value),
    });
  };

  const getActiveFilters = () => {
    const activeFilters = [];

    if (appliedFilters.deal_type.length > 0) {
      appliedFilters.deal_type.forEach((type) => {
        activeFilters.push(filterLabels.deal_type[type] || type);
      });
    }

    if (appliedFilters.status.length > 0) {
      appliedFilters.status.forEach((status) => {
        activeFilters.push(filterLabels.status[status] || status);
      });
    }

    if (appliedFilters.system_type.length > 0) {
      appliedFilters.system_type.forEach((system_type) => {
        activeFilters.push(
          filterLabels.system_type[system_type] || system_type
        );
      });
    }

    if (
      appliedFilters.date === "custom" &&
      appliedFilters.from_date &&
      appliedFilters.to_date
    ) {
      activeFilters.push(
        `${appliedFilters.from_date} - ${appliedFilters.to_date}`
      );
    } else if (appliedFilters.date !== "custom" && appliedFilters.date) {
      activeFilters.push(appliedFilters.date.split("_").join(" "));
    }

    return activeFilters;
  };

  const removeFilter = (filterToRemove) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters.deal_type = updatedFilters.deal_type.filter(
        (item) => filterLabels.deal_type[item] !== filterToRemove
      );
      updatedFilters.status = updatedFilters.status.filter(
        (item) => filterLabels.status[item] !== filterToRemove
      );
      updatedFilters.system_type = updatedFilters.system_type.filter(
        (item) => filterLabels.system_type[item] !== filterToRemove
      );
      if (
        `${updatedFilters.from_date} - ${updatedFilters.to_date}` ===
        filterToRemove
      ) {
        updatedFilters.from_date = "";
        updatedFilters.to_date = "";
        updatedFilters.date = "";
      }
      if (updatedFilters.date?.split("_").join(" ") === filterToRemove) {
        updatedFilters.date = "";
      }
      sessionStorage.setItem(
        "homeownersFilters",
        JSON.stringify(updatedFilters)
      );
      return updatedFilters;
    });

    setAppliedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters.deal_type = updatedFilters.deal_type.filter(
        (item) => filterLabels.deal_type[item] !== filterToRemove
      );
      updatedFilters.status = updatedFilters.status.filter(
        (item) => filterLabels.status[item] !== filterToRemove
      );
      updatedFilters.system_type = updatedFilters.system_type.filter(
        (item) => filterLabels.system_type[item] !== filterToRemove
      );
      if (
        `${updatedFilters.from_date} - ${updatedFilters.to_date}` ===
        filterToRemove
      ) {
        updatedFilters.from_date = "";
        updatedFilters.to_date = "";
        updatedFilters.date = "";
      }
      if (updatedFilters.date?.split("_").join(" ") === filterToRemove) {
        updatedFilters.date = "";
      }
      sessionStorage.setItem(
        "homeownersFilters",
        JSON.stringify(updatedFilters)
      );
      return updatedFilters;
    });
  };

  const clearAllFilters = () => {
    const emptyFilters = {
      used: false,
      deal_type: [],
      status: [],
      system_type: [],
      date: "",
      from_date: "",
      to_date: "",
      search: "",
    };
    setFilters(emptyFilters);
    setAppliedFilters(emptyFilters);
    setDateRadio(null);
    sessionStorage.removeItem("homeownersFilters");
    sessionStorage.removeItem("allHomeownersPageNumber");
  };

  const list = () => (
    <Box
      sx={{
        width: { xs: "100vw", sm: "400px", md: "480px" },
      }}
      role="presentation"
    >
      <div className="h-full p-4 md:p-10 flex flex-col gap-y-5 pb-[120px] md:pb-10">
        <div className="sticky top-0 bg-white z-10 py-3 border-b border-[#C7CACE]">
          <div className="flex justify-between items-center">
            <h1 className="text-[23px] text-[#112532] font-semibold py-2 md:py-4">
              Apply Filters
            </h1>
            <CloseIcon
              className="cursor-pointer md:hidden"
              onClick={toggleDrawer("right", false)}
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-6">
          <FormGroup>
            <h1 className="mb-1 text-[#112532] font-semibold">Deal Type</h1>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.deal_type.includes("contract")}
                  onChange={(e) =>
                    handleCheckboxChange(e, "deal_type", "contract")
                  }
                />
              }
              label="Contract"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.deal_type.includes("lead")}
                  onChange={(e) => handleCheckboxChange(e, "deal_type", "lead")}
                />
              }
              label="Lead"
            />
          </FormGroup>

          <FormGroup>
            <h1 className="mb-1 text-[#112532] font-semibold">Deal Status</h1>
            {Object.entries(filterLabels.status).map(([value, label]) => (
              <FormControlLabel
                key={value}
                control={
                  <Checkbox
                    checked={filters.status.includes(value)}
                    onChange={(e) => handleCheckboxChange(e, "status", value)}
                  />
                }
                label={label}
              />
            ))}
          </FormGroup>

          <FormGroup>
            <h1 className="mb-1 text-[#112532] font-semibold">Tier</h1>
            {Object.entries(filterLabels.system_type).map(([value, label]) => (
              <FormControlLabel
                key={value}
                control={
                  <Checkbox
                    checked={filters.system_type.includes(value)}
                    onChange={(e) =>
                      handleCheckboxChange(e, "system_type", value)
                    }
                  />
                }
                label={label}
              />
            ))}
          </FormGroup>

          <FormControl>
            <h1 className="mb-1 text-[#112532] font-semibold">Date</h1>
            <RadioGroup
              onChange={(e) => {
                setDateRadio(e.target.value);
                updateFilters({
                  date: e.target.value,
                  from_date: "",
                  to_date: "",
                });
              }}
              value={filters.date || null}
            >
              <FormControlLabel
                value="last_7_days"
                control={<Radio />}
                label="Last 7 Days"
              />
              <FormControlLabel
                value="last_30_days"
                control={<Radio />}
                label="Last Month"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </FormControl>

          <div
            className={`flex flex-col md:flex-row gap-3 ${dateRadio === "custom" ? "" : "opacity-50"}`}
          >
            <div className="flex-1">
              <h1 className="mb-2 text-sm md:text-base">From</h1>
              <DatePicker
                disabled={dateRadio !== "custom"}
                value={filters.from_date ? dayjs(filters.from_date) : null}
                onChange={(newValue) => {
                  updateFilters({
                    from_date: newValue ? newValue.format("YYYY-MM-DD") : "", // Changed from from_date
                  });
                }}
                sx={{ width: "100%" }}
                maxDate={filters.to_date ? dayjs(filters.to_date) : dayjs()}
              />
            </div>
            <div className="flex-1">
              <h1 className="mb-2 text-sm md:text-base">To</h1>
              <DatePicker
                disabled={dateRadio !== "custom"}
                value={filters.to_date ? dayjs(filters.to_date) : null}
                onChange={(newValue) => {
                  updateFilters({
                    to_date: newValue ? newValue.format("YYYY-MM-DD") : "", // Changed from to_date
                  });
                }}
                sx={{ width: "100%" }}
                minDate={filters.from_date ? dayjs(filters.from_date) : null}
                maxDate={dayjs()}
              />
            </div>
          </div>

          <div className="fixed md:relative bottom-0 left-0 right-0 bg-white border-t border-gray-200 md:border-0 pb-5">
            <div className="flex justify-center md:justify-end gap-3 p-6 md:p-0 md:mt-4">
              <button
                onClick={(event) => {
                  toggleDrawer("right", false)(event);
                  clearAllFilters();
                }}
                className="py-3 px-10 border text-[#112532] font-medium border-[#112532] rounded text-sm md:text-base w-full lg:w-auto"
              >
                Cancel
              </button>
              <button
                disabled={
                  !customDateValidate(
                    dateRadio,
                    filters.from_date,
                    filters.to_date
                  )
                }
                onClick={(event) => {
                  setAppliedFilters(filters);
                  sessionStorage.removeItem("allHomeownersPageNumber");
                  sessionStorage.setItem(
                    "homeownersFilters",
                    JSON.stringify(filters)
                  );
                  toggleDrawer("right", false)(event);
                }}
                className="py-3 px-10 text-[#fffefb] bg-[#112532] font-bold rounded text-sm md:text-base w-full lg:w-auto disabled:opacity-50"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <div className="flex flex-col gap-y-4 p-6">
      <div className="flex justify-between">
        <h1 className="text-black text-[28px] font-semibold">All Homeowners</h1>
        {isLoading ? (
          <div>LOADING...</div>
        ) : (allHomeownersData?.length ?? 0) === 0 ? (
          <div className="text-[#767F89] font-bold border-1 border-[#767F89] py-2 px-4 rounded opacity-50 cursor-not-allowed w-[25%] md:w-[9%] text-center">
            Export
          </div>
        ) : (
          <FormControl className="w-[25%] md:w-[6.5%] rounded" size="small">
            <InputLabel
              shrink={false}
              id="export-select-label"
              sx={{
                color: "#112532",
                fontWeight: "bold",
                "&.Mui-focused": {
                  color: "#112532",
                },
              }}
            >
              Export
            </InputLabel>
            <Select
              IconComponent={KeyboardArrowDownOutlinedIcon}
              labelId="export-select-label"
              id="export-select"
              sx={{
                color: "#112532",
                borderColor: "#112532",
                "& .MuiSelect-icon": {
                  color: "#112532",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#112532",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#112532",
                  borderWidth: "1px",
                },
              }}
            >
              <div className="flex flex-col">
                <hr />
                <ExcelExport
                  data={
                    selectedRows.length > 0
                      ? selectedRows
                      : sortedRows.length > 0
                        ? sortedRows
                        : allHomeownersData
                  }
                  columns={columns}
                  filename="homeowners_export"
                  filters={getActiveFilters()}
                />
              </div>
            </Select>
          </FormControl>
        )}
      </div>

      {/* Search and Filter Section */}
      <div className="flex flex-col gap-y-1">
        <div className="flex flex-col gap-y-3 justify-between gap-x-6 mb-3 bg-white border border-[#d9d3cf]">
          <TextField
            sx={{
              backgroundColor: "white",
              "& .MuiOutlinedInput-root": {
                border: "none",
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            fullWidth
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    loading="lazy"
                    src={
                      filters.search
                        ? "/icons//search-active.svg"
                        : "/icons/search.svg"
                    }
                    alt="search icon"
                    className="w-5 md:w-auto"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    loading="lazy"
                    onClick={toggleDrawer("right", true)}
                    className="cursor-pointer w-auto"
                    src={
                      getActiveFilters().length > 0
                        ? "/icons/Filter-active.svg"
                        : "/icons/Filter.svg"
                    }
                    alt="filter icon"
                  />
                </InputAdornment>
              ),
            }}
            placeholder="Search ID, name or location..."
          />

          <Drawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            sx={{
              "& .MuiBackdrop-root": {
                background: "rgba(17, 37, 50, 0.5)",
              },
            }}
            PaperProps={{
              sx: {
                width: { xs: "100%", sm: "400px", md: 500 },
                height: { xs: "85%", md: "100%" },
                bottom: 0,
                top: "auto",
                "& > div:first-of-type": {
                  height: "100%",
                },
              },
            }}
          >
            {list()}
          </Drawer>

          {getActiveFilters().length > 0 && (
            <div className="flex justify-between pb-4 px-2 md:px-4">
              <ul className="flex flex-wrap gap-2 mb-2 md:mb-0">
                {getActiveFilters().map((filter, index) => (
                  <li
                    key={index}
                    className="px-2 md:px-3 py-1 bg-[#FFF8F1] border border-[#FBB667] rounded text-xs md:text-sm flex items-center font-semibold"
                  >
                    {filter}
                    <CloseIcon
                      sx={{ fontSize: { xs: "16px", md: "20px" } }}
                      className="ml-1 md:ml-2 cursor-pointer"
                      onClick={() => removeFilter(filter)}
                    />
                  </li>
                ))}
              </ul>
              <button
                className="min-w-[100px] flex items-center justify-end gap-x-1 rounded text-[#244255] font-semibold text-sm md:text-base mt-2 md:mt-0"
                onClick={clearAllFilters}
              >
                <CloseIcon sx={{ fontSize: { xs: "16px", md: "20px" } }} />
                Clear All
              </button>
            </div>
          )}
        </div>

        {isLoading ? (
          <TableSkeleton />
        ) : (
          <CustomizedTables
            recordsArray={allHomeownersData}
            setSortedRows={setSortedRows}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
          />
        )}
      </div>
    </div>
  );
};

const CustomizedTables = ({
  recordsArray,
  setSelectedRows,
  selectedRows,
  setSortedRows,
}) => {
  const { isSm } = useResponsive();

  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("allHomeownersPageNumber");
    return savedPage ? parseInt(savedPage) : 0;
  });

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(sortedRows.slice(page * 10, page * 10 + 10));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRequestSort = (event, property) => {
    let newOrder;
    if (orderBy !== property) {
      newOrder = "asc";
      setOrder("asc");
      setOrderBy(property);
    } else {
      if (order === "asc") {
        newOrder = "desc";
        setOrder("desc");
      } else if (order === "desc") {
        newOrder = null;
        setOrder(null);
      } else {
        newOrder = "asc";
        setOrder("asc");
      }
    }

    const newSortedRows = recordsArray
      .slice()
      .sort(getComparator(newOrder, property));
    setSortedRows(newSortedRows);
  };

  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
      );
    }
  };

  const descendingComparator = (a, b, orderBy) => {
    const valueA = orderBy.includes(".")
      ? orderBy.split(".").reduce((o, i) => o[i], a)
      : a[orderBy];
    const valueB = orderBy.includes(".")
      ? orderBy.split(".").reduce((o, i) => o[i], b)
      : b[orderBy];

    if (valueB < valueA) return -1;
    if (valueB > valueA) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    if (order === null) {
      return (a, b) => 0;
    }

    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const formatPrice = (price) => {
    return parseFloat(price).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const rows = recordsArray;
  const emptyRows = Math.max(0, (page + 1) * 10 - rows?.length);
  const sortedRows = rows?.slice().sort(getComparator(order, orderBy));

  useEffect(() => {
    sessionStorage.setItem("allHomeownersPageNumber", page.toString());
  }, [page]);

  return (
    <TableWrapper>
      <TableContainer component={"div"} sx={{ paddingBottom: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                data-frozen={isSm || undefined}
                index={0}
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
                align="left"
              >
                <div className="flex">
                  <Checkbox
                    size="small"
                    checked={
                      sortedRows?.length > 0 &&
                      selectedRows?.length ===
                        sortedRows?.slice(page * 10, page * 10 + 10)?.length
                    }
                    indeterminate={
                      selectedRows?.length > 0 &&
                      selectedRows?.length <
                        sortedRows?.slice(page * 10, page * 10 + 10)?.length
                    }
                    sx={{
                      visibility:
                        selectedRows?.length > 0 ? "visible" : "hidden",
                      "&:hover": {
                        visibility: "visible",
                      },
                      ".MuiTableRow-root:hover &": {
                        visibility: "visible",
                      },
                    }}
                    onChange={handleSelectAllClick}
                  />
                  <TableSortLabel
                    active={orderBy === "id"}
                    direction={order !== null ? order : undefined}
                    onClick={(event) => handleRequestSort(event, "id")}
                    sx={{
                      "&:hover .MuiTableSortLabel-icon": {
                        visibility: "visible !important",
                      },
                      ".MuiTableSortLabel-icon": {
                        visibility: order !== null ? "visible" : "hidden",
                      },
                    }}
                  >
                    ID
                  </TableSortLabel>
                </div>
              </StyledTableCell>
              <StyledTableCell
                data-frozen={isSm || undefined}
                index={1}
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "User.first_name"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) =>
                    handleRequestSort(event, "User.first_name")
                  }
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "Installer.business_name"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) =>
                    handleRequestSort(event, "Installer.business_name")
                  }
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Installer Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "homeOwner_contract_signed"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) =>
                    handleRequestSort(event, "homeOwner_contract_signed")
                  }
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Deal Type
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "status"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "status")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Deal Status
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "system_type"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "system_type")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Tier
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "User.location"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "User.location")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Location
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "createdAt")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Date
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "User_solar_insight.finalPrice"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) =>
                    handleRequestSort(event, "User_solar_insight.finalPrice")
                  }
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Price
                </TableSortLabel>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows?.slice(page * 10, page * 10 + 10).map((row) => (
              <StyledTableRow
                key={row.id}
                sx={{
                  bgcolor: "white",
                  border: "0px solid #F9F8F8",
                  borderWidth: "4px 2px",
                }}
              >
                <StyledTableCell
                  data-frozen={isSm || undefined}
                  index={0}
                  component="th"
                  scope="row"
                >
                  <div className="flex items-center">
                    <Checkbox
                      size="small"
                      checked={selectedRows.some(
                        (selectedRow) => selectedRow.id === row.id
                      )}
                      sx={{
                        visibility: selectedRows.some(
                          (selectedRow) => selectedRow.id === row.id
                        )
                          ? "visible"
                          : "hidden",
                        "&:hover": {
                          visibility: "visible",
                        },
                        ".MuiTableRow-root:hover &": {
                          visibility: "visible",
                        },
                      }}
                      onChange={(event) => handleSelectRow(event, row)}
                    />
                    {console.log(row)}
                    {row.homeOwner_id}
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  data-frozen={isSm || undefined}
                  index={1}
                  align="left"
                  sx={{ color: "#D0680F" }}
                >
                  <Link to={`${row.homeOwner_id}`}>
                    {`${row.User.first_name} ${row.User.last_name}`}
                  </Link>
                </StyledTableCell>
                <StyledTableCell
                  data-frozen={isSm || undefined}
                  index={1}
                  align="left"
                  sx={{ color: "#D0680F" }}
                >
                  <Link to={`installer/${row.installer_id}`}>
                    {row.Installer.business_name}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.homeOwner_contract_signed ? "Contract" : "Lead"}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {dealStatus(row.status)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.system_type}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.User.location}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {dayjs(row.createdAt).format("YYYY-MM-DD")}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ bgcolor: "#FCEEC9" }}>
                  ${formatPrice(row?.User_solar_insight?.finalPrice || 0)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="flex justify-center mt-3">
          <Pagination
            size="large"
            sx={{
              "& .MuiPaginationItem-root": {
                height: "25px", // Adjusted height
                fontSize: "0.75rem", // Optional: Adjust font size for proportion
                minWidth: "auto", // Ensures no default min width
                padding: "4px 8px",
                "&.Mui-selected": {
                  backgroundColor: "#112532", // Your desired color
                  color: "white", // Ensure contrast
                },
              },
            }}
            count={Math.ceil(rows?.length / 10)}
            shape="rounded"
            // color="#112532"
            page={page + 1}
            onChange={(e, value) => setPage(value - 1)}
          />
        </div>
      </TableContainer>
    </TableWrapper>
  );
};

export default AllHomeownersTable;
