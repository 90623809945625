import PartnersRegisterForm from "components/partners/auth/PartnersRegisterForm";
import Header from "components/shared/Header";
import AuthLayout from "layout/AuthLayout";

const PartnersRegister = () => {
  return (
    <>
      <Header />
      <AuthLayout imagePath={"/images/partners-backdrop.png"}>
        <PartnersRegisterForm />
      </AuthLayout>
    </>
  );
};

export default PartnersRegister;
