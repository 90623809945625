import React from "react";
import Footer from "./Footer";
import { Helmet } from "react-helmet-async";

const WhyGoSolar = () => {
  return (
    <div>
      <Helmet>
        <title>Why Go Solar?- Spark Solar</title>
        <link rel="canonical" href="https://sparksolar.io/why-go-solar" />
        <meta
          name="description"
          content="Discover the benefits of switching to solar energy with Spark Solar!"
        />
        <meta
          name="keywords"
          content="why go solar, solar energy benefits, reduce energy bills, increase property value, how to increase property value, sustainable energy, renewable energy, government incentives, energy independence, how to reduce energy bills, solar power for home, solar panel installation, solar energy solutions"
        />
      </Helmet>
      {/*HERO SECTION*/}
      <div
        className="w-full h-[420px] grid place-content-center gap-y-5"
        style={{
          backgroundImage: "url(/images/why-go-solar-hero.png)",
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1 className="text-white text-3xl md:text-5xl font-semibold text-center">
          Why <span className="text-amber-500">go solar?</span>
        </h1>
        <p className="text-white text-base md:text-xl text-center px-4">
          Choosing solar energy isn't just about saving money,
          <br /> it's about investing in the future of our planet.
        </p>
      </div>

      {/*TABLET SECTION*/}
      <div className="flex flex-col gap-y-4 md:gap-y-12 p-4 py-10 md:p-12 bg-white">
        <div className="flex flex-col gap-y-4">
          <h1 className="text-[#112532] text-3xl md:text-4xl font-semibold text-center">
            Now is the <span className="text-[#D97706]">perfect time!</span>
          </h1>
          <p className="text-[#112532] text-base md:text-xl text-center px-4">
            we help you harness the power of the sun with seamless <br />
            solutions designed to fit your unique energy needs.
          </p>
        </div>

        {/* Top Row Cards */}
        <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-x-8">
          <div className="flex flex-col gap-y-3 p-6 bg-[#fef8eb] rounded-lg shadow border border-[#ffe9b8] md:flex-1">
            <img className="w-12 h-12" src="/icons/money-bag.svg" alt="" />
            <h2 className="text-[#112532] text-2xl font-medium">
              Save on Energy Bills
            </h2>
            <p className="text-[#112532] text-base md:text-xl">
              Solar energy cuts reliance on traditional grids, lowering your
              monthly electricity bills.
            </p>
          </div>
          <div className="flex flex-col gap-y-3 p-6 bg-[#fef8eb] rounded-lg shadow border border-[#ffe9b8] md:flex-1">
            <img
              className="w-12 h-12"
              src="/icons/increase-stepper.svg"
              alt=""
            />
            <h2 className="text-[#112532] text-2xl font-medium">
              Increase Your Property Value
            </h2>
            <p className="text-[#112532] text-base md:text-xl">
              Homes equipped with solar panels often see an increase in property
              value, making it a smart investment.
            </p>
          </div>
          <div className="flex flex-col gap-y-3 p-6 bg-[#fef8eb] rounded-lg shadow border border-[#ffe9b8] md:flex-1">
            <img className="w-12 h-12" src="/icons/leaf-stepper.svg" alt="" />
            <h2 className="text-[#112532] text-2xl font-medium">
              Clean, Renewable Energy
            </h2>
            <p className="text-[#112532] text-base md:text-xl">
              Solar power is renewable, reducing your carbon footprint and
              promoting a healthier environment.
            </p>
          </div>
        </div>

        {/* Bottom Row Cards */}
        <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-8">
          <div className="flex flex-col gap-y-3 p-6 bg-[#fef8eb] rounded-lg shadow border border-[#ffe9b8] md:w-1/3">
            <img className="w-12 h-12" src="/icons/power-stepper.svg" alt="" />
            <h2 className="text-[#112532] text-2xl font-medium">
              Energy Independence
            </h2>
            <p className="text-[#112532] text-base md:text-xl">
              Solar lets you control energy usage and costs, avoiding
              unpredictable utility price hikes.
            </p>
          </div>
          <div className="flex flex-col gap-y-3 p-6 bg-[#fef8eb] rounded-lg shadow border border-[#ffe9b8] md:w-1/3">
            <img
              className="w-12 h-12"
              src="/icons/percent-stepper.svg"
              alt=""
            />
            <h2 className="text-[#112532] text-2xl font-medium">
              Government Incentives
            </h2>
            <p className="text-[#112532] text-base md:text-xl">
              Incentives, tax credits, and financing options make going solar
              more affordable than ever.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WhyGoSolar;
