import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useMotionValueEvent,
  useScroll,
  useTransform,
  motion,
} from "framer-motion";
import NextComponent from "./NextComponent";
import useResponsive from "hooks/useResponsive";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(1);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const navigate = useNavigate();

  const { isSm } = useResponsive();
  const imagesPath = isSm ? "intro-animation-mobile" : "intro-animation";

  // Disable scroll when not fully loaded
  useEffect(() => {
    if (!isFullyLoaded) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isFullyLoaded]);

  const { scrollYProgress, scrollY } = useScroll({
    target: containerRef,
  });

  const fadeStartProgress = 140 / 200;
  const fadeEndProgress = 1;

  const opacity = useTransform(
    scrollYProgress,
    [fadeStartProgress, fadeEndProgress],
    [0, 1]
  );

  useMotionValueEvent(scrollY, "change", (latest) => {
    setIsScrolled(latest > 100);
  });

  const images = useMemo(() => {
    const loadedImages = [];
    const totalImages = 130; // 170 - 32

    for (let i = 32; i < 170; i++) {
      const img = new Image();
      img.src = `/assets/${imagesPath}/${i}.webp`;

      img.onload = () => {
        setImagesLoaded((prev) => {
          const newCount = prev + 1;
          if (newCount === totalImages) {
            setIsFullyLoaded(true);
          }
          return newCount;
        });
      };

      loadedImages.push(img);
    }
    return loadedImages;
  }, [imagesPath]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      const setCanvasSize = () => {
        if (images[0] && images[0].complete) {
          canvas.width = images[0].width;
          canvas.height = images[0].height;
          render(1);
        }
      };

      setCanvasSize();
      images[0].onload = setCanvasSize;
    }
  }, [images]);

  const render = useCallback(
    (index) => {
      if (images[index - 1] && canvasRef.current) {
        const context = canvasRef.current.getContext("2d");
        context?.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        context?.drawImage(images[index - 1], 0, 0);
      }
    },
    [images]
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, [isSm]);

  const currentIndex = useTransform(scrollYProgress, [0, 1], [1, 170]);

  useMotionValueEvent(currentIndex, "change", (latest) => {
    const frame = +latest.toFixed();
    setCurrentFrame(frame);
    render(frame);
    if (frame >= 170) {
      setIsAnimationComplete(true);
    } else {
      setIsAnimationComplete(false);
    }
  });

  return (
    <div className="z-10">
      <Helmet>
        <link rel="canonical" href="https://sparksolar.io" />
      </Helmet>
      {!isFullyLoaded && (
        <div className=" fixed inset-0 bg-[#112532] z-50 flex items-center justify-center overflow-hidden">
          <div className="text-white text-center w-full flex flex-col items-center">
            <div className=" text-xl flex justify-center">
              <img className="w-60" src="/images/Spark-white.svg" alt="" />
            </div>
            <div className="w-1/4 h-2 bg-white rounded-full overflow-hidden mt-20">
              <div
                className="h-full bg-[#F59E0B] rounded-full transition-all duration-300"
                style={{
                  width: `${(imagesLoaded / 138) * 100}%`,
                }}
              />
            </div>
            <div className="mt-2">
              {Math.round((imagesLoaded / 138) * 100)}%
            </div>
          </div>
        </div>
      )}
      <motion.div className=" fixed left-0 top-0 w-screen flex justify-center items-center">
        <canvas ref={canvasRef} className="w-screen h-screen object-cover" />
        {currentFrame < 10 && (
          <div className="absolute bottom-[10%] left-1/2 -translate-x-1/2 text-white flex flex-col items-center space-y-2">
            <h1 className="text-sm font-medium mb-2">Scroll</h1>
            <div className="border border-white w-14 h-10 rounded-full flex items-center justify-center rotate-90">
              <motion.div
                className="bg-white w-4 h-2 rounded-full flex items-center justify-center"
                animate={{
                  x: [0, -10, 0], // Moves up and down
                }}
                transition={{
                  duration: 1, // Time for one full animation cycle
                  repeat: Infinity, // Repeats infinitely
                  ease: "easeInOut", // Smooth in and out movement
                }}
              ></motion.div>
            </div>
          </div>
        )}
      </motion.div>
      <div ref={containerRef} className="h-[6000px] md:pt-[94px]" />

      <motion.div style={{ opacity }} className="relative">
        <NextComponent />
      </motion.div>
    </div>
  );
};

export default Home;
