import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Checkbox, Pagination, TableSortLabel } from "@mui/material";
import useResponsive from "hooks/useResponsive";
import { formatPrice } from "utils/formatNumber";

const StyledTableCell = styled(TableCell)(
  ({ theme, "data-frozen": frozen, index }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      color: "black",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: "500",
    },
    ...(frozen && {
      position: "sticky",
      left: index === 0 ? "-2px" : "100.08px",
      zIndex: 2,
      backgroundColor: "white",
      filter: "drop-shadow(8px 0 6px rgba(203, 197, 180, 0.21))",
      borderRight: "1px solid rgba(203, 197, 180, 0.15)",
    }),
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderWidth: "8px 0px",
  border: "8px solid white",
}));

const TableWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  overflowX: "auto",
  [theme.breakpoints.down("sm")]: {
    "& table": {
      minWidth: "100%",
    },
    "& th, & td": {
      width: { sm: "75px", md: "150px" },
    },
    "& th:first-of-type, & td:first-of-type, & th:nth-of-type(2), & td:nth-of-type(2)":
      {
        position: "sticky",
        backgroundColor: "white",
        // zIndex: 1,
      },
  },
}));

// const rows = Array.from({ length: 100 }, (v, i) => ({
//   id: i + 1,
//   name: "Alex Petter",
//   type: "Contract",
//   tiers: "Premium",
//   location: "209 Circle Slope Dr, Simpsonville, SC 29681",
//   date: "20-05-2024",
//   price: "$26,000",
// }));

const CustomizedTables = ({
  recordsArray,
  setSelectedRows,
  selectedRows,
  setSortedRows,
  admin,
}) => {
  const { isSm } = useResponsive();

  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("myDealsPageNumber");
    return savedPage ? parseInt(savedPage) : 0;
  });

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(""); // Set initial orderBy to empty string

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(sortedRows.slice(page * 10, page * 10 + 10));
    } else {
      setSelectedRows([]);
    }
  };
  const handleRequestSort = (event, property) => {
    let newOrder;
    if (orderBy !== property) {
      newOrder = "asc";
      setOrder("asc");
      setOrderBy(property);
    } else {
      if (order === "asc") {
        newOrder = "desc";
        setOrder("desc");
      } else if (order === "desc") {
        newOrder = null;
        setOrder(null);
      } else {
        newOrder = "asc";
        setOrder("asc");
      }
    }

    // Sort the entire array
    const newSortedRows = recordsArray
      .slice()
      .sort(getComparator(newOrder, property));

    // Set the entire sorted array as sorted rows
    setSortedRows(newSortedRows);
  };

  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
      );
    }
  };

  const descendingComparator = (a, b, orderBy) => {
    const valueA = orderBy.includes(".")
      ? orderBy.split(".").reduce((o, i) => o[i], a)
      : a[orderBy];
    const valueB = orderBy.includes(".")
      ? orderBy.split(".").reduce((o, i) => o[i], b)
      : b[orderBy];

    if (valueB < valueA) return -1;
    if (valueB > valueA) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    if (order === null) {
      return (a, b) => 0; // Return default, unsorted rows
    }

    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const rows = recordsArray;
  const emptyRows = Math.max(0, (page + 1) * 10 - rows.length);
  const sortedRows = rows.slice().sort(getComparator(order, orderBy));

  useEffect(() => {
    sessionStorage.setItem("myDealsPageNumber", page.toString());
  }, [page]);

  // useEffect(() => {
  //   setPage(0);
  //   sessionStorage.setItem("myDealsPageNumber", "0");
  // }, [recordsArray]);

  return (
    <TableWrapper>
      <TableContainer component={"div"} sx={{ paddingBottom: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                data-frozen={isSm || undefined}
                index={0}
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
                align="left"
              >
                <div className="flex">
                  <Checkbox
                    size="small"
                    checked={
                      sortedRows.length > 0 &&
                      selectedRows.length ===
                        sortedRows.slice(page * 10, page * 10 + 10).length
                    }
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length <
                        sortedRows.slice(page * 10, page * 10 + 10).length
                    }
                    sx={{
                      visibility:
                        selectedRows.length > 0 ? "visible" : "hidden", // Show when selected
                      "&:hover": {
                        visibility: "visible", // Show on hover
                      },
                      ".MuiTableRow-root:hover &": {
                        visibility: "visible", // Show when row is hovered
                      },
                    }}
                    onChange={handleSelectAllClick}
                  />

                  <TableSortLabel
                    active={orderBy === "id"}
                    direction={order !== null ? order : undefined}
                    onClick={(event) => handleRequestSort(event, "id")}
                    sx={{
                      "&:hover .MuiTableSortLabel-icon": {
                        visibility: "visible !important",
                      },
                      ".MuiTableSortLabel-icon": {
                        visibility: order !== null ? "visible" : "hidden",
                      },
                    }}
                  >
                    ID
                  </TableSortLabel>
                </div>
              </StyledTableCell>
              <StyledTableCell
                data-frozen={isSm || undefined}
                index={1}
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "User.first_name"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) =>
                    handleRequestSort(event, "User.first_name")
                  }
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "homeOwner_contract_signed"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) =>
                    handleRequestSort(event, "homeOwner_contract_signed")
                  }
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Deal Type&nbsp;
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "status"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "status")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Deal Status&nbsp;
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "system_type"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "system_type")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Tiers&nbsp;
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "User.location"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "User.location")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Location&nbsp;
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "createdAt")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Date&nbsp;
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "User_solar_insight.finalPrice"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) =>
                    handleRequestSort(event, "User_solar_insight.finalPrice")
                  }
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Price&nbsp;
                </TableSortLabel>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.slice(page * 10, page * 10 + 10).map((row) => (
              <StyledTableRow
                key={row.id}
                sx={{
                  bgcolor: "white",
                  border: "0px solid #F9F8F8",
                  borderWidth: "4px 2px",
                }}
              >
                <StyledTableCell
                  data-frozen={isSm || undefined}
                  index={0}
                  component="th"
                  scope="row"
                >
                  <div className=" flex items-center">
                    <Checkbox
                      size="small"
                      checked={selectedRows.some(
                        (selectedRow) => selectedRow.id === row.id
                      )}
                      sx={{
                        visibility: selectedRows.some(
                          (selectedRow) => selectedRow.id === row.id
                        )
                          ? "visible"
                          : "hidden",
                        "&:hover": {
                          visibility: "visible",
                        },
                        ".MuiTableRow-root:hover &": {
                          visibility: "visible",
                        },
                      }}
                      onChange={(event) => handleSelectRow(event, row)}
                    />

                    {row.id}
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  data-frozen={isSm || undefined}
                  index={1}
                  align="left"
                  sx={{ color: "#D0680F" }}
                >
                  {admin ? (
                    `${row.User.first_name} ${row.User.last_name}`
                  ) : (
                    <Link to={`/dashboard-installer/Mydeals/${row.id}`}>
                      {`${row.User.first_name} ${row.User.last_name}`}
                    </Link>
                  )}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.homeOwner_contract_signed ? "Contract" : "Lead"}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.status === 0
                    ? "Active"
                    : row.status === 1
                      ? "Closed Won"
                      : "Closed Lost"}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.system_type}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.User.location}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {format(new Date(row.createdAt), "yyyy-MM-dd")}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ bgcolor: "#FCEEC9" }}>
                  {`$${formatPrice(row?.User_solar_insight?.finalPrice) || 0}`}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="flex justify-center mt-3">
          <Pagination
            size="large"
            sx={{
              "& .MuiPaginationItem-root": {
                height: "25px", // Adjusted height
                fontSize: "0.75rem", // Optional: Adjust font size for proportion
                minWidth: "auto", // Ensures no default min width
                padding: "4px 8px",
                "&.Mui-selected": {
                  backgroundColor: "#112532", // Your desired color
                  color: "white", // Ensure contrast
                },
              },
            }}
            count={Math.ceil(rows?.length / 10)}
            shape="rounded"
            // color="#112532"
            page={page + 1}
            onChange={(e, value) => setPage(value - 1)}
          />
        </div>
      </TableContainer>
    </TableWrapper>
  );
};

export default CustomizedTables;
