import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";
import CustomStepper from "components/MUI/CustomStepper";
import { Autocomplete } from "@react-google-maps/api";
import { Controller } from "react-hook-form";
import { CustomSlider } from "../CustomSlider";
import usePreventScroll from "hooks/usePreventScroll";

const HomeDetailsRegistration = ({
  onLoad,
  onPlaceChanged,
  control,
  errors,
  onSubmit,
  currentStep,
  renderMap,
  isValid,
}) => {
  const { isMenuOpen, onMenuOpen, onMenuClose } = usePreventScroll();

  return (
    <div className="flex-1">
      <h1 className="text-[#112532] text-[28px] font-semibold md:text-[45px] ">
        Solar panel system customized just for you
      </h1>
      <div className="flex flex-col gap-y-6 md:gap-y-8">
        <div className="-ml-6 md:-ml-[85px]">
          <CustomStepper
            width="calc(100% + 20px)"
            steps={["Home Details", "Personal Details", "System Tiers"]}
            activeStepSolar={currentStep}
            orientation="horizontal"
          />
        </div>

        <div className="flex flex-col gap-y-2">
          <h2 className="text-[#112532] text-[19px] font-semibold">
            Home Details
          </h2>
          <p className="flex flex-col md:gap-y-8 text-[#112532] text-xs">
            Personalize your project with additional configurations
          </p>
        </div>

        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          restrictions={{ country: "us" }}
        >
          <Controller
            name="location"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className="w-full"
                label="Choose Your Location*"
                placeholder="Search for your address or drag the marker"
                error={!!errors.location}
                helperText={
                  errors.location?.message ||
                  errors.location?.placeId?.message ||
                  errors.location?.selected?.message
                }
                value={value?.address || ""}
                onChange={(e) => {
                  onChange({
                    address: e.target.value,
                    placeId: "",
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        className="w-6 h-6"
                        src="/icons/location_on.svg"
                        alt=""
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Autocomplete>

        <div className="w-full h-[208px] rounded-lg shadow overflow-hidden md:hidden">
          {renderMap()}
        </div>

        <h2 className="text-[#112532] text-[15px]">
          Average Electrical Monthly Bill (Recent bill)*
        </h2>
        <Controller
          name="bill_value"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <div className="">
                <CustomSlider
                  value={+value}
                  onChange={(e) => onChange(e.target.value.toString())}
                  valueLabelFormat={(value) => `$${value}`}
                  valueLabelDisplay="on"
                  min={100}
                  max={2000}
                />
                <div className="w-full flex justify-between text-[#244255] text-xs">
                  <span>$100</span>
                  <span>$2000</span>
                </div>
              </div>
              {errors.bill_value && (
                <FormHelperText error>
                  {errors.bill_value.message}
                </FormHelperText>
              )}
            </>
          )}
        />

        <Controller
          name="equipment"
          control={control}
          render={({ field }) => (
            <FormControl size="large" error={!!errors.equipment}>
              <InputLabel>Equipment*</InputLabel>
              <Select
                {...field}
                label="Equipment*"
                onOpen={onMenuOpen}
                onClose={onMenuClose}
              >
                <MenuItem value="solar">Solar</MenuItem>
                <MenuItem value="solar_battery">Solar + Batteries</MenuItem>
              </Select>
              {errors.equipment && (
                <FormHelperText>{errors.equipment.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />

        <button
          onClick={onSubmit}
          disabled={!isValid}
          className={
            "px-10 py-3 bg-[#112532] rounded-[5px] text-[#fffefb] font-bold disabled:opacity-50 md:w-fit"
          }
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default HomeDetailsRegistration;
