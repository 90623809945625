export const ACCESS_TOKEN = "token";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const TOKEN_TYPE = "Bearer ";

export const PASSWORD_PATTERN = /^().{8,}$/;

export const indoorSurveySteps = [
  {
    step: 0,
    images: {
      src: "/images/subPanel.jpg",
      alt: "Subpanel",
      title: "Subpanel",
      description: "Look for...",
      DB_Name: "subPanel",
    },
  },
  {
    step: 1,
    images: {
      src: "/images/subpanelLabel.jpg",
      alt: "Subpanel-Label",
      title: "Subpanel Label",
      description: "Look for...",
      DB_Name: "subPanel_Label",
    },
  },
  {
    step: 2,
    images: {
      src: "/images/subpanelLocation.jpg",
      alt: "Subpanel Location ",
      title: "Subpanel Location",
      description: "Look for...",
      DB_Name: "subPanel_Location",
    },
  },
  {
    step: 3,
    images: {
      src: "/images/attic.jpg",
      alt: "Subpanel",
      title: "Attic",
      description: "Look for above your ceiling",
      DB_Name: "attic",
    },
  },
  {
    step: 4,
    images: {
      src: "/images/attic.jpg",
      alt: "Subpanel",
      title: "Attic",
      description: "Look for above your ceiling",
      DB_Name: "attic",
    },
  },
];

export const outdoorSurveySteps = [
  {
    step: 0,
    images: {
      src: "/images/mainPanel.png",
      alt: "Subpanel",
      title: "Main Service Panel",
      description: "Look for above your ceiling",
      DB_Name: "mainService_panel",
    },
  },
  {
    step: 1,
    images: {
      src: "/images/subpanelLabel.jpg",
      alt: "Panel Label",
      title: "Main Service Panel Label",
      description: "Look for above your ceiling",
      DB_Name: "mainService_panel_label",
    },
  },
  {
    step: 2,
    images: {
      src: "/images/Main Breaker.png",
      alt: "Main Breaker",
      title: "Main Breaker",
      description: "Look for above your ceiling",
      DB_Name: "main_Breaker",
    },
  },
  {
    step: 3,
    images: {
      src: "/images/Area Surrounding Panel.png",
      alt: "Area Surrounding Panel",
      title: "Area Surrounding Panel",
      description: "Look for above your ceiling",
      DB_Name: "area_surrounding_panel",
    },
  },
  {
    step: 4,
    images: {
      src: "/images/Utility Meter.png",
      alt: "Subpanel",
      title: "Utility Meter",
      description: "Look for above your ceiling",
      DB_Name: "utlity_meter",
    },
  },
  {
    step: 5,
    images: {
      src: "/images/Meter Location.png",
      alt: "Meter Location",
      title: "Meter Location",
      description: "Look for above your ceiling",
      DB_Name: "meter_location",
    },
  },
  {
    step: 6,
    images: {
      src: "/images/Roof Location.png",
      alt: "Roof Location",
      title: "Roof Location",
      description: "Look for above your ceiling",
      DB_Name: "roof_location",
    },
  },
  {
    step: 7,
    images: {
      src: "/images/Roof Location.png",
      alt: "Roof Location",
      title: "Roof Location",
      description: "Look for above your ceiling",
      DB_Name: "roof_location",
    },
  },
];

export const classOptions = [
  {
    value: "silver",
    label: "Silver",
    icon: <img className="h-7 w-7" src="/icons/Diamond.svg" alt="" />,
  },
  {
    value: "gold",
    label: "Gold",
    icon: <img className="h-7 w-7" src="/icons/Gold.svg" alt="" />,
  },
  {
    value: "platinum",
    label: "Platinum",
    icon: <img className="h-7 w-7" src="/icons/Platinum.svg" alt="" />,
  },
];

// Options data
export const solarPanelsOptions = [
  { id: 2, modelNumber: "6MN6A270", manufacturerName: "Ablytek" },
  { id: 3, modelNumber: "6MN6A275", manufacturerName: "Ablytek" },
  { id: 4, modelNumber: "6MN6A280", manufacturerName: "Ablytek" },
  { id: 5, modelNumber: "6MN6A285", manufacturerName: "Ablytek" },
  { id: 6, modelNumber: "6MN6A290", manufacturerName: "Ablytek" },
  { id: 7, modelNumber: "API-P210", manufacturerName: "Advance Power" },
  { id: 8, modelNumber: "API-P215", manufacturerName: "Advance Power" },
  { id: 9, modelNumber: "API-P220", manufacturerName: "Advance Power" },
  { id: 10, modelNumber: "API-M225", manufacturerName: "Advance Power" },
  { id: 11, modelNumber: "API-P225", manufacturerName: "Advance Power" },
];

export const USAstates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const invertersOptions = [
  {
    id: 2,
    modelNumber: " PVI-3.0-OUTD-S-US-A [208V]",
    manufacturerName: "ABB",
  },
  {
    id: 3,
    modelNumber: " PVI-3.0-OUTD-S-US-A [240V]",
    manufacturerName: "ABB",
  },
  {
    id: 4,
    modelNumber: " PVI-3.0-OUTD-S-US-A [277V]",
    manufacturerName: "ABB",
  },
  {
    id: 5,
    modelNumber: " PVI-3.0-OUTD-S-US-Z-A [208V]",
    manufacturerName: "ABB",
  },
  {
    id: 6,
    modelNumber: " PVI-3.0-OUTD-S-US-Z-A [240V]",
    manufacturerName: "ABB",
  },
  {
    id: 7,
    modelNumber: " PVI-3.0-OUTD-S-US-Z-A [277V]",
    manufacturerName: "ABB",
  },
  {
    id: 8,
    modelNumber: " PVI-3.0-OUTD-S-US-Z-M-A [208V]",
    manufacturerName: "ABB",
  },
  {
    id: 9,
    modelNumber: " PVI-3.0-OUTD-S-US-Z-M-A [240V]",
    manufacturerName: "ABB",
  },
  {
    id: 10,
    modelNumber: " PVI-3.0-OUTD-S-US-Z-M-A [277V]",
    manufacturerName: "ABB",
  },
  {
    id: 11,
    modelNumber: " UNO-DM-3.3-TL-PLUS-US-SB-RA [208V]",
    manufacturerName: "ABB",
  },
];

export const batteryOptions = [
  {
    id: 2,
    modelNumber: " SMILE-SP7.6 [8.2kWh]",
    manufacturerName: "Alpha ESS Co., Ltd.",
  },
  {
    id: 3,
    modelNumber: " SMILE-SP9.6 [8.2kWh]",
    manufacturerName: "Alpha ESS Co., Ltd.",
  },
  {
    id: 4,
    modelNumber: " SMILE-SPB7.6 [8.2kWh]",
    manufacturerName: "Alpha ESS Co., Ltd.",
  },
  {
    id: 5,
    modelNumber: " SMILE-SPB9.6 [8.2kWh]",
    manufacturerName: "Alpha ESS Co., Ltd.",
  },
  {
    id: 6,
    modelNumber: " SMILE-SP7.6 [16.4kWh]",
    manufacturerName: "Alpha ESS Co., Ltd.",
  },
  {
    id: 7,
    modelNumber: " SMILE-SP9.6 [16.4kWh]",
    manufacturerName: "Alpha ESS Co., Ltd.",
  },
  {
    id: 8,
    modelNumber: " SMILE-SPB7.6 [16.4kWh]",
    manufacturerName: "Alpha ESS Co., Ltd.",
  },
  {
    id: 9,
    modelNumber: " SMILE-SPB9.6 [16.4kWh]",
    manufacturerName: "Alpha ESS Co., Ltd.",
  },
  {
    id: 10,
    modelNumber: " SMILE-SP7.6  [24.6kWh]",
    manufacturerName: "Alpha ESS Co., Ltd.",
  },
  {
    id: 11,
    modelNumber: " SMILE-SP9.6 [24.6kWh]",
    manufacturerName: "Alpha ESS Co., Ltd.",
  },
];

export const rackingOptions = [
  {
    id: 1,
    manufacturerName: "IronRidge",
    productName: "XR Rails",
  },
  {
    id: 2,
    manufacturerName: "IronRidge",
    productName: "XR Rails",
  },
  {
    id: 3,
    manufacturerName: "Unirac",
    productName: "SOLARMOUNT",
  },
  {
    id: 4,
    manufacturerName: "SnapNrack",
    productName: "Series 100 / Series 200",
  },
  {
    id: 5,
    manufacturerName: "Quick Mount PV",
    productName: "Classic Composition Mount / Quick Hook",
  },
  {
    id: 6,
    manufacturerName: "Pegasus Solar",
    productName: "Rail-Free Mounting System",
  },
  {
    id: 7,
    manufacturerName: "EcoFasten",
    productName: "Simple Seal / Rock-It",
  },
  {
    id: 8,
    manufacturerName: "S-5!",
    productName: "Clamp-Based Systems",
  },
  {
    id: 9,
    manufacturerName: "Roof Tech",
    productName: "RT-APEX",
  },
];

export const tierImage = (system_type) => {
  switch (system_type) {
    case "Basic":
      return "/icons/Stars1.svg";
    case "Standard":
      return "/icons/Stars2.svg";
    case "Premium":
      return "/icons/Stars3.svg";
    default:
      break;
  }
};

export const getBadgeIcon = (badge) => {
  switch (badge?.toLowerCase()) {
    case "platinum":
      return "/icons/Platinum.svg";
    case "gold":
      return "/icons/Gold.svg";
    case "silver":
      return "/icons/Diamond.svg";
    default:
      return "/icons/none-class.svg";
  }
};

export const dealStatus = (status) => {
  switch (status) {
    case 0:
      return "Active";
    case 1:
      return "Closed Won";
    case 2:
      return "Closed Lost";
    default:
      return "";
  }
};

export const getDifferenceType = (from, to) => {
  const differenceInDays = to?.diff(from, "day");

  if (differenceInDays <= 7) {
    return "day";
  } else if (differenceInDays <= 30) {
    return "week";
  } else {
    return "month";
  }
};

export const installerTags = [
  "Local",
  "Fast Installation",
  "Excellent Warranty",
  "Eco-Friendly",
  "Affordable",
  "Green Energy",
  "Seamless Integration",
  "Reliable",
  "24/7 Support",
  "Certified Installers",
  "Energy Savings",
  "Flexible Financing",
  "Maintenance Services",
  "Clean Energy Solutions",
  "Battery Storage",
];

export const customDateValidate = (dateRadio, from_date, to_date) => {
  if (dateRadio === "custom") {
    return from_date && to_date;
  }
  return true;
};
