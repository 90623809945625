import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MyDeals from "components/installer/MyDeals";
import Tier from "components/installer/Tier";
import { CustomizeTabs } from "components/MUI/CustomizeTabs";
import { IosSwitch } from "components/MUI/IosSwitch";
import BreadCrumb from "components/shared/BreadCrumb";
import Comment from "components/shared/Comment";
import GalleryCarousel from "components/shared/GalleryCarousel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DELETEinstallerTags,
  GETinstallerById,
  GETinstallerProfileById,
  GEToffersByInstallerId,
  POSTinstallerTags,
  PUTupdateInstallerData,
} from "services/AdminServices";
import { classOptions, installerTags } from "utils/constant";

const InstallerConfig = () => {
  const params = useParams();
  const [installerFrameType, setInstallerFrameType] = useState("offers");
  const [offers, setOffers] = useState([]);
  const [installerData, setInstallerData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [indexToStart, setIndexToStart] = useState(null);
  const [portfolioData, setPortfolioData] = useState({
    portfolio: [],
    reviews: [],
  });
  const [visibleReviews, setVisibleReviews] = useState(10);
  const [installerConfig, setInstallerConfig] = useState({
    activation: false,
    spark_fees: "",
    className: "",
  });
  const [isTagsDialogOpen, setIsTagsDialogOpen] = useState(false);
  const [tagInputValue, setTagInputValue] = useState("");

  const installerGeneralData = installerData?.installer?.[0];

  const tagsArray = portfolioData?.installerTags?.map(
    (tagObj) => Object.values(tagObj)[1]
  );

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const deleteTagById = async (tagId) => {
    try {
      const res = await DELETEinstallerTags(params.userId, tagId);
      toast.success(res.message);
    } catch (error) {
      console.error(error);
      toast.error("Error fetching data");
    } finally {
      fetchData();
    }
  };

  const addTagById = async (tag) => {
    try {
      const res = await POSTinstallerTags(params.userId, tag);
      toast.success(res.message);
    } catch (error) {
      console.error(error);
      toast.error("Error fetching data");
    } finally {
      fetchData();
    }
  };
  const fetchData = async () => {
    setIsLoading(true);

    // Function to safely execute requests
    const safeRequest = async (request, errorMessage) => {
      try {
        return await request;
      } catch (error) {
        console.error(error);
        // toast.error(errorMessage);
        return null;
      }
    };

    try {
      const [offers, portfolioData, installerData] = await Promise.allSettled([
        safeRequest(GEToffersByInstallerId(params.userId), "No Offers Found"),
        safeRequest(
          GETinstallerProfileById(params.userId),
          "Error fetching portfolio"
        ),
        safeRequest(
          GETinstallerById(params.userId),
          "Error fetching installer data"
        ),
      ]);

      // Set data only if the requests were fulfilled
      if (offers.status === "fulfilled" && offers.value) {
        setOffers(offers.value?.offers);
      }

      if (portfolioData.status === "fulfilled" && portfolioData.value) {
        setPortfolioData(portfolioData.value);
      }

      if (installerData.status === "fulfilled" && installerData.value) {
        setInstallerData(installerData.value);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateInstallerById = async (id, data) => {
    try {
      const res = await PUTupdateInstallerData(id, data);
      toast.success(res.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (installerGeneralData) {
      setInstallerConfig((prev) => ({
        ...prev,
        activation: installerGeneralData.activation ?? false,
      }));
    }
  }, [installerGeneralData]);

  const handleViewMore = () => {
    setVisibleReviews((prev) => prev + 10);
  };

  const onCloseAfter = () => setIndexToStart(null);

  const renderPortfolioSection = () => (
    <div className="flex flex-col gap-y-4 px-8 py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
      <div className="flex justify-between gap-4 md:gap-0 mb-2">
        <h2 className="text-[#112532] text-xl md:text-[23px] font-semibold">
          Projects
        </h2>
      </div>

      {portfolioData.portfolio_images?.length > 0 ? (
        <>
          <GalleryCarousel
            onCloseAfter={onCloseAfter}
            indexToStart={indexToStart}
            imageData={portfolioData.portfolio_images}
            variant="portfolio"
            isRight={false}
          />
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-5">
            {portfolioData?.portfolio_images?.map((project, index) => (
              <div key={project.id} className="group relative">
                <img
                  loading="lazy"
                  src={project.img}
                  alt=""
                  className="h-48 md:h-64 w-full object-contain"
                />
                <div className="p-1 w-8 h-8 flex md:hidden group-hover:flex flex-col gap-y-2 justify-center items-center absolute right-0 top-[20px]">
                  <img
                    loading="lazy"
                    onClick={() => setIndexToStart(index)}
                    src="/icons/full-screen.svg"
                    className="w-full h-full cursor-pointer"
                    alt="icon"
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="w-full flex flex-col items-center gap-y-5 py-4 md:py-6 px-4 md:px-8">
          <div className="w-full flex flex-col items-center gap-y-4">
            <img
              className="w-12 h-12 md:w-14 md:h-14"
              src="/icons/gallery-library.svg"
              alt=""
            />
            <h2 className="text-[#112532] text-lg md:text-[19px] font-semibold text-center">
              No projects yet!
            </h2>
          </div>
        </div>
      )}
    </div>
  );
  const renderReviewsSection = () => (
    <div className="flex flex-col gap-y-4 px-8 py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
      <div className="flex gap-x-4 md:gap-x-20 gap-y-6">
        <div className="flex flex-col justify-evenly items-start md:items-center gap-y-4">
          <h1 className="text-[#112532] text-xl md:text-[23px] font-semibold">
            Reviews
          </h1>
          {portfolioData?.reviewSummary?.totalReviews === 0 ? (
            <div className="flex items-center gap-x-2">
              <h1 className="text-[#112532] text-lg md:text-xl">Not Rated</h1>
              <img className="h-6 md:h-8" src="/icons//off-star.svg" alt="" />
            </div>
          ) : (
            <div className="flex gap-x-1">
              <span className="text-2xl md:text-[39px] font-bold text-[#112532]">
                {portfolioData?.reviewSummary?.averageRating}
              </span>
              <img
                loading="lazy"
                className="w-6 md:w-9"
                src="/icons/star.svg"
                alt=""
              />
            </div>
          )}
          <span className="text-[#5e514e] text-sm md:text-base">
            ({portfolioData?.reviewSummary?.totalReviews} Reviews)
          </span>
        </div>
        <div className="w-full md:w-auto space-y-2">
          {[5, 4, 3, 2, 1].map((rating) => (
            <div key={rating} className="flex items-center">
              <span className="text-gray-600 w-6 md:w-8 text-sm md:text-base">
                {rating}
              </span>
              <div className="w-full md:w-60 h-1 bg-gray-300 relative rounded">
                <span
                  className="block absolute h-full bg-[#F3B33E] z-10 rounded"
                  style={{
                    width: `${portfolioData?.reviewSummary?.starPercentages[rating]}%`,
                  }}
                ></span>
              </div>
              <span className="text-[#C0B8B1] ml-2 min-w-[40px] text-sm md:text-base">
                {Math.round(
                  portfolioData?.reviewSummary?.starPercentages[rating]
                ) || 0}
                %
              </span>
            </div>
          ))}
        </div>
      </div>
      <hr className="my-4 md:my-6 border-[2px] border-[#ECECEE]" />
      <div className="flex flex-col gap-y-8 md:gap-y-14">
        <h1 className="text-[#112532] text-xl md:text-[23px] font-semibold">
          All Reviews ({portfolioData?.reviewSummary?.totalReviews})
        </h1>
        {portfolioData?.InstallerReviews?.slice(0, visibleReviews).map(
          (review) => (
            <Comment
              key={review.id}
              reviewer={{
                ...review,
                name: `${review?.User?.first_name} ${review?.User?.last_name}`,
                review: review?.comment,
                rating: review?.stars,
                created_at: review?.created_at,
              }}
            />
          )
        )}
        {portfolioData.reviews &&
          visibleReviews < portfolioData.reviews.length && (
            <div className="flex justify-center mt-4">
              <button
                onClick={handleViewMore}
                className="px-6 py-2 bg-[#112532] text-white rounded-[5px] hover:bg-opacity-90 transition-colors"
              >
                View More Reviews
              </button>
            </div>
          )}
      </div>
    </div>
  );

  const tabs = [
    {
      label: "First Tier",
      icon: (
        <img
          loading="lazy"
          className="w-8 md:w-10"
          src="/icons/Stars1.svg"
          alt=""
        />
      ),
      value: 0,
      sx: {
        color: "#000",
        fontSize: { xs: 14, md: 16 },
        minHeight: { xs: "auto", md: "48px" },
        padding: { xs: "8px", md: "12px 16px" },
      },
      disableRipple: true,
      ariaLabel: "First tab",
    },
    {
      label: "Second Tier",
      icon: (
        <img
          loading="lazy"
          className="w-8 md:w-10"
          src="/icons/Stars2.svg"
          alt=""
        />
      ),
      value: 1,
      sx: {
        color: "#000",
        fontSize: { xs: 14, md: 16 },
        minHeight: { xs: "auto", md: "48px" },
        padding: { xs: "8px", md: "12px 16px" },
      },
      disableRipple: true,
      ariaLabel: "Second tab",
      disabled: !offers[0],
    },
    {
      label: "Third Tier",
      icon: (
        <img
          loading="lazy"
          className="w-8 md:w-10"
          src="/icons/Stars3.svg"
          alt=""
        />
      ),
      value: 2,
      sx: {
        color: "#000",
        fontSize: { xs: 14, md: 16 },
        minHeight: { xs: "auto", md: "48px" },
        padding: { xs: "8px", md: "12px 16px" },
      },
      disableRipple: true,
      ariaLabel: "Third tab",
      disabled: !offers[1],
    },
  ];
  const tabPanels = [
    {
      content: (
        <Tier
          data={offers[0]}
          tierName="First Tier"
          loadingState={isLoading}
          admin={true}
        />
      ),
    },
    {
      content: (
        <Tier
          data={offers[1]}
          tierName="Second Tier"
          loadingState={isLoading}
          admin={true}
        />
      ),
    },
    {
      content: (
        <Tier
          data={offers[2]}
          tierName="Third Tier"
          loadingState={isLoading}
          admin={true}
        />
      ),
    },
  ];

  return (
    <div className="p-6 pt-0">
      <div className="flex flex-col gap-y-4">
        <BreadCrumb
          paths={[
            { name: "All Installers", pathLink: "/admin-dashboard/installers" },
            {
              name: "Installer Details",
              pathLink: `/admin-dashboard/installers/${params.userId}`,
            },
          ]}
        />
        <div className="flex flex-col gap-y-4 ">
          <div className="flex flex-col gap-y-4 px-8 py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
            <div className="flex justify-between">
              <div className="flex items-center gap-x-2">
                <img
                  src={installerGeneralData?.logo || "/images/defaultLogo.svg"}
                  alt=""
                  className="w-16 h-16 rounded-full"
                />
                <h2 className="text-[#112532] text-[33px] font-semibold">
                  {installerGeneralData?.business_name}
                </h2>
              </div>
              <div className="flex items-center gap-x-3 min-w-[300px]">
                <FormControl className="w-full">
                  {!(
                    installerGeneralData?.className || installerConfig.className
                  ) && (
                    <InputLabel id="demo-simple-select-helper-label">
                      <div className="flex items-center gap-x-2">
                        <img
                          className="h-7 w-7"
                          src="/icons/none-class.svg"
                          alt=""
                        />
                        Select
                      </div>
                    </InputLabel>
                  )}

                  <Select
                    labelId="class-select-label"
                    label="Select"
                    onChange={(e) => {
                      setInstallerConfig((prev) => ({
                        ...prev,
                        className: e.target.value,
                      }));
                      updateInstallerById(params.userId, {
                        className: e.target.value,
                      });
                    }}
                    value={
                      installerConfig.className ||
                      installerGeneralData?.className ||
                      ""
                    }
                    renderValue={(selected) => {
                      const selectedItem = classOptions.find(
                        (item) => item.value === selected
                      );
                      return (
                        <div className="flex items-center gap-x-2">
                          {selectedItem?.icon}
                          <span className="text-[13px] font-medium">
                            {selectedItem?.label}
                          </span>
                        </div>
                      );
                    }}
                    sx={{
                      "& .MuiSelect-select": {
                        backgroundColor: "#FEF9EC",
                        borderRadius: "6px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    {classOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                  sx={{ color: "#272a2e", fontWeight: 500 }}
                  label="Activation"
                  labelPlacement="start"
                  control={
                    <IosSwitch
                      sx={{ m: 1 }}
                      checked={installerConfig.activation}
                      onChange={(e) => {
                        const newActivationState = e.target.checked;

                        setInstallerConfig((prev) => ({
                          ...prev,
                          activation: newActivationState,
                        }));

                        updateInstallerById(params.userId, {
                          activation: newActivationState.toString(),
                        }).then(() => {
                          fetchData(); // Ensure latest data is fetched
                        });
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className="flex items-center gap-x-2 relative">
              {portfolioData?.reviewSummary?.totalReviews === 0 ? (
                <div className="flex items-center gap-x-2">
                  <h1 className="text-[#112532] text-lg md:text-xl">
                    Not Rated
                  </h1>
                  <img src="/icons//off-star.svg" alt="" />
                </div>
              ) : (
                <div className="flex gap-x-1">
                  <h1 className="text-[#112532] text-xl md:text-[28px] font-bold">
                    {portfolioData?.reviewSummary?.averageRating}
                  </h1>
                  <img loading="lazy" src="/icons/star.svg" alt="" />
                </div>
              )}
              <span className="text-[#5e514e] text-sm md:text-base">
                ({portfolioData?.reviewSummary?.totalReviews} Reviews)
              </span>

              {portfolioData?.installerTags?.map((tag) => (
                <span
                  key={tag.id}
                  className="flex gap-x-2 items-center px-3 md:px-4 py-1 cursor-pointer  bg-[#F0FAFB] text-black border-1 border-[#D8F0F5] text-sm md:text-base rounded"
                >
                  {tag.tag}
                </span>
              ))}
              <div
                className="ml-auto flex items-center gap-2 text-[#3881b0] cursor-pointer"
                onClick={() => setIsTagsDialogOpen(true)}
              >
                <img src="/Admin-assets/plus.svg" alt="" /> Add tags
              </div>
              {isTagsDialogOpen && (
                <div className="flex flex-col gap-y-5 w-[395px] h-auto px-4 py-6 bg-white rounded-lg shadow absolute right-0 -top-[80px] z-50">
                  <div className="flex justify-between">
                    <h1 className="text-[#272a2e] text-xl">Select your Tags</h1>
                    <img
                      src="/Admin-assets/close.svg"
                      alt=""
                      className=" cursor-pointer"
                      onClick={() => setIsTagsDialogOpen(false)}
                    />
                  </div>
                  <h2 className="text-black/40 text-[15px]">
                    Select up to 2 tags.
                  </h2>
                  <TextField
                    fullWidth
                    disabled={tagsArray.length > 1}
                    label="Add your tags..."
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      setTagInputValue(e.target.value);
                    }}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      "& .MuiFormHelperText-root": {
                        backgroundColor: "white",
                        padding: "3px 14px 0px",
                        margin: 0,
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <button
                            className="ml-auto mr-4 gap-x-1 flex items-center text-[#3881b0] cursor-pointer text-[14px] disabled:opacity-50 disabled:cursor-default"
                            onClick={() => addTagById(tagInputValue)}
                            disabled={tagsArray.length > 1}
                          >
                            <img
                              className="w-5"
                              src="/Admin-assets/plus.svg"
                              alt=""
                            />
                            Add tag
                          </button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {tagsArray.length > 1 && (
                    <p className="text-red-700 text-[15px]">
                      You can select up to 2 tags only
                    </p>
                  )}
                  <div className="flex flex-wrap gap-3">
                    {tagsArray.map((tag) => {
                      if (!installerTags.includes(tag)) {
                        const tagId =
                          portfolioData?.installerTags?.[tagsArray.indexOf(tag)]
                            ?.id;
                        if (tagsArray.length > 1) {
                          return (
                            <span
                              key={tag}
                              className="flex gap-x-2 items-center px-3 md:px-4 py-1 cursor-pointer bg-[#b5e0ec] text-black  border border-[#dedddd] text-sm md:text-base rounded"
                            >
                              {tag}
                              <img
                                src="/Admin-assets/close.svg"
                                alt=""
                                className=" cursor-pointer"
                                onClick={() => deleteTagById(tagId)}
                              />
                            </span>
                          );
                        }
                        return (
                          <span
                            key={tag}
                            className="flex gap-x-2 items-center px-3 md:px-4 py-1 cursor-pointer bg-[#e8e8e8] text-black  border border-[#dedddd] text-sm md:text-base rounded"
                          >
                            {tag}
                            <img
                              src="/Admin-assets/close.svg"
                              alt=""
                              className=" cursor-pointer"
                              onClick={() => deleteTagById(tagId)}
                            />
                          </span>
                        );
                      }
                    })}
                    {installerTags?.map((tag) => {
                      if (tagsArray.length > 1) {
                        if (tagsArray?.includes(tag)) {
                          const tagId =
                            portfolioData?.installerTags?.[
                              tagsArray.indexOf(tag)
                            ]?.id;

                          return (
                            <span
                              key={tag}
                              className="flex gap-x-2 items-center px-3 md:px-4 py-1 cursor-pointer bg-[#b5e0ec] text-black  border border-[#dedddd] text-sm md:text-base rounded"
                            >
                              {tag}
                              <img
                                src="/Admin-assets/close.svg"
                                alt=""
                                className=" cursor-pointer"
                                onClick={() => deleteTagById(tagId)}
                              />
                            </span>
                          );
                        }
                        return (
                          <span
                            key={tag}
                            className="flex gap-x-2 items-center px-3 md:px-4 py-1  bg-[#e8e8e8] text-black  border border-[#dedddd] text-sm md:text-base rounded"
                          >
                            {tag}
                          </span>
                        );
                      }
                      if (tagsArray?.includes(tag)) {
                        const tagId =
                          portfolioData?.installerTags?.[tagsArray.indexOf(tag)]
                            ?.id;

                        return (
                          <span
                            key={tag}
                            className="flex gap-x-2 items-center px-3 md:px-4 py-1 cursor-pointer bg-[#e8e8e8] text-black  border border-[#dedddd] text-sm md:text-base rounded"
                          >
                            {tag}
                            <img
                              src="/Admin-assets/close.svg"
                              alt=""
                              className=" cursor-pointer"
                              onClick={() => deleteTagById(tagId)}
                            />
                          </span>
                        );
                      }
                      return (
                        <span
                          key={tag}
                          className="flex gap-x-2 items-center px-3 md:px-4 py-1 cursor-pointer  bg-[#F0FAFB] text-black border-1 border-[#D8F0F5] text-sm md:text-base rounded"
                        >
                          {tag}{" "}
                          <img
                            src="/Admin-assets/plus.svg"
                            alt=""
                            onClick={() => addTagById(tag)}
                          />
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <hr className="border-[#D9D3CF]" />
            <div className="space-y-2">
              <p className="flex gap-2 py-2 text-sm text-[#265D78] underline">
                <img loading="lazy" src="/icons/Email.svg" alt="mail icon" />
                {installerGeneralData?.email}
              </p>
              <p className="flex gap-2 py-2 text-sm">
                <img loading="lazy" src="/icons/Call.svg" alt="phone icon" />
                {installerGeneralData?.mobile}
              </p>
              <p className="flex gap-2 py-2 text-sm">
                <img
                  loading="lazy"
                  src="/icons/Location.svg"
                  alt="location icon"
                />
                {installerGeneralData?.business_address}
              </p>
            </div>
          </div>

          <div className="">
            <div className="flex gap-x-5">
              <div
                className={`text-[#112532] cursor-pointer ${
                  installerFrameType === "offers"
                    ? "font-semibold border-b-4 border-[#F59E0B]"
                    : ""
                }`}
                onClick={() => setInstallerFrameType("offers")}
              >
                Offers
              </div>
              <div
                className={`text-[#112532] cursor-pointer ${
                  installerFrameType === "Deals"
                    ? "font-semibold border-b-4 border-[#F59E0B]"
                    : ""
                }`}
                onClick={() => setInstallerFrameType("Deals")}
              >
                Deals
              </div>
              <div
                className={`text-[#112532] cursor-pointer ${
                  installerFrameType === "Portfolio"
                    ? "font-semibold border-b-4 border-[#F59E0B]"
                    : ""
                }`}
                onClick={() => setInstallerFrameType("Portfolio")}
              >
                Portfolio
              </div>
            </div>
            <hr className="border-[#D9D3CF]" />
          </div>

          <div className="flex flex-col gap-y-5  ">
            {installerFrameType === "offers" && (
              <>
                <h1 className="text-[#112532] text-[23px] font-semibold">
                  Offers
                </h1>
                <div className="flex flex-col gap-y-2">
                  <h2 className="text-[#272a2e] text-[15px] font-medium">
                    Spark Fees ($/Watt)
                  </h2>
                  <div className="flex justify-start gap-x-3 w-1/2">
                    <TextField
                      fullWidth
                      size="large"
                      value={
                        installerConfig.spark_fees ||
                        installerGeneralData?.spark_fees
                      }
                      onChange={(e) => {
                        setInstallerConfig((prev) => ({
                          ...prev,
                          spark_fees: e.target.value,
                        }));
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              loading="lazy"
                              className="cursor-pointer w-auto"
                              src="/icons/bi_currency-dollar.svg"
                              alt="filter icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Price/Watt"
                    />
                    <button
                      className="px-6 py-3 bg-[#112532] rounded text-white font-semibold"
                      onClick={() =>
                        updateInstallerById(params.userId, {
                          spark_fees: installerConfig.spark_fees,
                        })
                      }
                    >
                      Apply
                    </button>
                  </div>
                </div>
                <CustomizeTabs
                  tabPanels={tabPanels}
                  value={tabValue}
                  onChange={handleTabChange}
                  tabs={tabs}
                  tabsWidth="100%"
                />
              </>
            )}
            {installerFrameType === "Deals" && (
              <MyDeals admin={true} userId={params.userId} />
              // <DealsTable userId={params.userId} />
            )}
            {installerFrameType === "Portfolio" && (
              <>
                {renderPortfolioSection()}
                {renderReviewsSection()}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallerConfig;
