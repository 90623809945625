import AuthenticatedHeader from "components/shared/AuthenticatedHeader";
import Header from "components/shared/Header";
import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";

const AdminDashboardLayout = () => {
  const location = useLocation();
  return (
    <div className="bg-[#f9f8f8] min-h-screen">
      <div>
        <AuthenticatedHeader isAdmin={true} />
        <div className="md:px-6 md:pb-4">
          <div className="bg-white md:rounded-b-[10px] shadow border border-[#ececee] -mt-4 overflow-hidden">
            <nav className="lock">
              <ul className="flex items-center">
                <li>
                  <NavLink
                    to={"/admin-dashboard"}
                    className={({ isActive }) =>
                      `flex gap-x-2 px-8 py-5 ${
                        isActive && location.pathname === "/admin-dashboard"
                          ? "#F7F6F5 text-[#d0680f] font-semibold border-b-4 border-[#d0680f] bg-[#f7f6f5]"
                          : "white"
                      }`
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <ReactSVG
                          src="/icons/team_dashboard.svg"
                          beforeInjection={(svg) => {
                            svg.querySelectorAll("path").forEach((path) => {
                              path.setAttribute(
                                "fill",
                                isActive &&
                                  location.pathname === "/admin-dashboard"
                                  ? "#D0680F"
                                  : "#6B7280"
                              );
                            });
                          }}
                          className="w-6 h-6"
                        />
                        <span>Dashboard</span>
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"installers"}
                    className={({ isActive }) =>
                      `flex gap-x-2 px-8 py-5 ${isActive ? "#F7F6F5 text-[#d0680f] font-semibold border-b-4 border-[#d0680f] bg-[#f7f6f5]" : "white"}`
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <ReactSVG
                          src="/Admin-assets/installers.svg"
                          beforeInjection={(svg) => {
                            svg.querySelectorAll("path").forEach((path) => {
                              path.setAttribute(
                                "stroke",
                                isActive ? "#D0680F" : "#6B7280"
                              );
                            });
                          }}
                          className="w-6 h-6"
                        />
                        <span>All Installers</span>
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"homeowners"}
                    className={({ isActive }) =>
                      `flex gap-x-2 px-8 py-5 ${isActive ? "#F7F6F5 text-[#d0680f] font-semibold border-b-4 border-[#d0680f] bg-[#f7f6f5]" : "white"}`
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <ReactSVG
                          src="/Admin-assets/roof.svg"
                          beforeInjection={(svg) => {
                            svg.querySelectorAll("path").forEach((path) => {
                              path.setAttribute(
                                "fill",
                                isActive ? "#D0680F" : "#6B7280"
                              );
                            });
                          }}
                          className="w-6 h-6"
                        />
                        <span>All Homeowners</span>
                      </>
                    )}
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminDashboardLayout;
