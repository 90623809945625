// import "bootstrap/dist/css/bootstrap.min.css";
// import "react-toastify/dist/ReactToastify.css";
// import "./App.css";
// import "./components/scss/themes.scss";
// import "./styles/main.css";

import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export default function App({ children }) {
  // console.log("App useEffect triggered");
  // useEffect(() => {
  //   const disableContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   const disableKeys = (event) => {
  //     if (
  //       event.key === "F12" ||
  //       (event.ctrlKey &&
  //         event.shiftKey &&
  //         (event.key === "I" || event.key === "J" || event.key === "C")) ||
  //       (event.ctrlKey && event.key === "U")
  //     ) {
  //       event.preventDefault();
  //     }
  //   };

  //   let devToolsDetected = false; // Prevent infinite reloads

  //   const detectDevTools = () => {
  //     const devtools = /./;
  //     devtools.toString = () => {
  //       if (!devToolsDetected) {
  //         devToolsDetected = true;
  //         console.log("DevTools detected");
  //         // Reload the page to disrupt debugging
  //         // window.location.reload();
  //       }
  //     };
  //     console.log(devtools);
  //   };

  //   document.addEventListener("contextmenu", disableContextMenu);
  //   document.addEventListener("keydown", disableKeys);

  //   const interval = setInterval(detectDevTools, 1000);

  //   return () => {
  //     document.removeEventListener("contextmenu", disableContextMenu);
  //     document.removeEventListener("keydown", disableKeys);
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <>
      <Helmet>
        <title>Spark Solar</title>
        <meta
          name="description"
          content="Personalize your solar panel installation journey with ease!"
        />
        <meta
          name="keywords"
          content="solar panel installation, solar energy solutions, rooftop solar panels, solar power systems, solar panel, solar panels, solar panels and battery systems, solar panel intaller, solar installer, solar panel installer in north carolina, solar panel installer in south carolina, solar panel installer in georgia, solar equipment provider, solar setup design, renewable energy ai, renewable energy AI, solar investment, home solar investment"
        />
      </Helmet>
      {children}
    </>
  );
}
