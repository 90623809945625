import { useEffect, useState } from "react";
import ConfirmationDialog from "components/shared/DeleteConfirmationDialog";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { Keyboard, Navigation, Zoom } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

export const GalleryCarousel = ({
  indexToStart = 0,
  imageData,
  URLsFields,
  onCloseAfter,
  variant = "dealOverview",
  isRight = false,
  deleteProjectById = () => {},
  isDelete = false,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  // Check for mobile viewport
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Helper function to transform data
  const transformData = (input) => {
    const generateResponsiveUrls = (url) => {
      const baseUrl = url.split("?")[0];
      return `${baseUrl}?w=480&q=80 480w, ${baseUrl}?w=800&q=80 800w`;
    };

    const generateThumbnailUrl = (url) => {
      return `${url.split("?")[0]}?w=240&q=80`;
    };

    if (variant === "dealOverview") {
      return URLsFields.filter((field) => input[field.key]).map((field) => ({
        src: input[field.key],
        responsive: generateResponsiveUrls(input[field.key]),
        thumb: generateThumbnailUrl(input[field.key]),
        field: field.label.replace(/_/g, " "),
      }));
    } else if (variant === "chat") {
      return input.map((item) => ({
        src: item.file_url,
        responsive: generateResponsiveUrls(item.file_url),
        thumb: generateThumbnailUrl(item.file_url),
        id: item.id,
        fileName: item.file_name,
        fileType: item.file_type,
      }));
    } else {
      return input.map((field) => ({
        src: field.img,
        responsive: generateResponsiveUrls(field.img),
        thumb: generateThumbnailUrl(field.img),
        id: field.id,
      }));
    }
  };

  const transformedImages = transformData(imageData);

  const openGallery = () => {
    setIsFullScreen(true);
  };

  const closeGallery = () => {
    setIsFullScreen(false);
    if (onCloseAfter) onCloseAfter();
  };

  const downloadImage = (src) => {
    fetch(src, {
      method: "GET",
      mode: "cors",
      headers: {
        "Cache-Control": "no-cache",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary URL for the Blob
        const blobURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobURL;
        link.download = "image.jpg";

        // Trigger the download
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(blobURL);
      })
      .catch((error) => {
        console.error("Error downloading the image:", error);
      });
  };

  useEffect(() => {
    if (indexToStart !== null) {
      openGallery(indexToStart);
    }
  }, [indexToStart]);

  return (
    <div>
      {variant !== "chat" && (
        <button
          className={`text-[#265D78] font-bold cursor-pointer ${isRight && "text-right w-full"}`}
          disabled={transformedImages.length === 0}
          onClick={openGallery}
        >
          View All
        </button>
      )}

      {isFullScreen && (
        <div className="fixed inset-0 bg-[#112532E5] flex justify-center items-center z-50">
          <ConfirmationDialog
            onConfirm={() => {
              deleteProjectById(imageToDelete);
              setImageToDelete(null);
            }}
            onClose={() => setImageToDelete(null)}
            open={!!imageToDelete}
            title="Delete Image"
            subTitle="Are you sure you want to delete this image?"
            cancelText="Cancel"
            confirmText="Delete"
          />
          <div className="relative w-full max-w-screen-lg h-full">
            <Swiper
              navigation={{
                prevEl: "#customPrev",
                nextEl: "#customNext",
              }}
              initialSlide={indexToStart}
              keyboard={{
                enabled: true,
              }}
              zoom
              modules={[Navigation, Zoom, Keyboard]}
              className="mySwiper"
              style={{ height: "100%" }}
              rewind
            >
              {transformedImages.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="relative flex flex-col justify-center gap-y-3 md:gap-y-5 h-full px-4 md:px-0">
                    <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center px-0 md:px-3 self-end">
                      <div className="text-2xl md:text-[34px] text-white font-semibold mb-2 md:mb-0">
                        {image.field}
                      </div>
                      <div className="flex gap-x-4 items-center">
                        {isDelete && (
                          <button
                            className="z-10 w-8 h-8 md:w-10 md:h-10"
                            onClick={() => {
                              setImageToDelete(image.id);
                            }}
                          >
                            <img
                              loading="lazy"
                              className="w-full h-full"
                              src="/icons/trash.svg"
                              alt=""
                            />
                          </button>
                        )}
                        <button
                          className="z-10"
                          onClick={() => downloadImage(image.src)}
                        >
                          <img
                            loading="lazy"
                            className="w-6 h-6 md:w-auto md:h-auto"
                            src="/icons/download.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                    <div className="swiper-zoom-container h-fit z-20">
                      <img
                        loading="lazy"
                        className="w-full max-h-[500px] md:max-h-[700px] object-contain"
                        src={image.src}
                        srcSet={image.responsive}
                        sizes="(max-width: 768px) 100vw, 800px"
                        alt={`Slide ${index}`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <button
            id="customPrev"
            className="absolute left-2 md:left-4 top-1/2 translate-y-[-50%] scale-75 md:scale-100 hidden md:block"
          >
            <img loading="lazy" src="/icons/leftImage.svg" alt="" />
          </button>
          <button
            id="customNext"
            className="absolute right-2 md:right-4 top-1/2 translate-y-[-50%] scale-75 md:scale-100 hidden md:block"
          >
            <img loading="lazy" src="/icons/rightImage.svg" alt="" />
          </button>
          <button
            className="absolute top-2 md:top-[5%] right-2 md:right-[3%] z-50 scale-75 md:scale-100"
            onClick={closeGallery}
          >
            <img loading="lazy" src="/icons/closeIcon.svg" alt="" />
          </button>
        </div>
      )}
    </div>
  );
};

export default GalleryCarousel;
