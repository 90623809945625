import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ReactDropZone from "components/shared/ReactDropZone";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { getBadgeIcon, tierImage } from "utils/constant";
import { formatPrice } from "utils/formatNumber";
import { CustomSlider } from "../CustomSlider";
import usePreventScroll from "hooks/usePreventScroll";

const AdditionalInformation = ({
  onBack,
  control,
  onSubmit,
  errors,
  isValid,
  selectedOffer,
  watch,
  isLoading,
}) => {
  const { isMenuOpen, onMenuOpen, onMenuClose } = usePreventScroll();
  const [showPassword, setShowPassword] = useState({
    new: false,
    confirm: false,
  });
  const [imagesArray, setImagesArray] = useState([]);

  const roofTypeOptions = [
    {
      value: "Metal Roof",
      label: "Metal Roof",
      icon: <img className="h-5 w-5" src="/images/metal.png" alt="" />,
    },
    {
      value: "Asphalt Shingles Roof",
      label: "Asphalt Shingles Roof",
      icon: <img className="h-5 w-5" src="/images/asphalt.png" alt="" />,
    },
    {
      value: "Tile Roof",
      label: "Tile Roof",
      icon: <img className="h-5 w-5" src="/images/tile.png" alt="" />,
    },
    {
      value: "Flat Roof",
      label: "Flat Roof",
      icon: <img className="h-5 w-5" src="/images/flat.png" alt="" />,
    },
    {
      value: "Not Sure",
      label: "Not Sure",
      icon: <img className="h-5 w-5" src="/icons/not-sure.svg" alt="" />,
    },
  ];

  const roofConditionOptions = [
    {
      value: "Recently Installed",
      label: "Recently Installed",
      icon: <img className="h-5 w-5" src="/images/metal.png" alt="" />,
    },
    {
      value: "Decent Condition (1-10 years old)",
      label: "Decent Condition (1-10 years old)",
      icon: <img className="h-5 w-5" src="/images/asphalt.png" alt="" />,
    },
    {
      value: "Needs to be Replaced (10-20 years old)",
      label: "Needs to be Replaced (10-20 years old)",
      icon: <img className="h-5 w-5" src="/images/tile.png" alt="" />,
    },
    {
      value: "Not Sure",
      label: "Not Sure",
      icon: <img className="h-5 w-5" src="/images/flat.png" alt="" />,
    },
  ];

  const password = watch("password");

  const isPasswordValid =
    password.length >= 8 &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password) &&
    /\d/.test(password) &&
    /[@$!%*?&#]/.test(password);

  return (
    <>
      <div
        className="flex items-center gap-x-2 py-4 w-fit cursor-pointer lg:hidden"
        onClick={onBack}
      >
        <img src="/icons/arrow_back.svg" alt="" />
        <h1 className="text-sm font-semibold">Back to Your Solar Design</h1>
      </div>
      <div className="flex flex-col-reverse lg:flex-row lg:justify-between w-full min-h-screen">
        {/* Mobile and Left Column for Desktop */}
        <div className="w-full lg:w-3/5 px-4 py-6 flex flex-col gap-y-6">
          <div
            className="hidden lg:flex items-center gap-x-2 py-4 w-fit cursor-pointer"
            onClick={onBack}
          >
            <img src="/icons/arrow_back.svg" alt="" />
            <h1 className="text-sm font-semibold">Back to Your Solar Design</h1>
          </div>
          <h1 className="text-[#112532] text-lg font-bold lg:text-[32px]">
            Additional Information
          </h1>
          <p className="text-[#112532] text-[13px] lg:text-lg">
            Fill the information below to continue your purchase
          </p>

          <div className="flex flex-col gap-y-6">
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Roof Type
              </InputLabel>
              <Controller
                name="roof_type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Roof Type"
                    onOpen={onMenuOpen}
                    onClose={onMenuClose}
                    renderValue={(selected) => {
                      const selectedItem = roofTypeOptions.find(
                        (item) => item.value === selected
                      );
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {selectedItem?.icon}
                          <span>{selectedItem?.label}</span>
                        </div>
                      );
                    }}
                  >
                    {roofTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>Select your roof type</FormHelperText>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Roof Condition
              </InputLabel>
              <Controller
                name="roof_condition"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Roof Condition"
                    onOpen={onMenuOpen}
                    onClose={onMenuClose}
                    renderValue={(selected) => {
                      const selectedItem = roofConditionOptions.find(
                        (item) => item.value === selected
                      );
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <span>{selectedItem?.label}</span>
                        </div>
                      );
                    }}
                  >
                    {roofConditionOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>Select your roof condition</FormHelperText>
            </FormControl>

            <Controller
              name="credit_score"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <h1 className="text-[#112532] text-[15px] font-normal mb-2">
                    Estimated Credit Score
                  </h1>
                  <CustomSlider
                    value={+value}
                    onChange={(e) => onChange(e.target.value)}
                    valueLabelFormat={(value) => `${value}`}
                    valueLabelDisplay="on"
                    min={400}
                    max={850}
                  />
                  {errors.credit_score && (
                    <FormHelperText error>
                      {errors.credit_score.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />

            <h2 className="text-[#272a2e] text-[15px]">
              Most Recent Electrical Bill
            </h2>
            {imagesArray.length > 0 ? (
              <div className="w-full">
                <img
                  className="mx-auto my-2 block object-cover w-full max-h-36"
                  src={imagesArray[0].preview}
                  alt=""
                />
                <div
                  className="flex justify-center items-center py-4 bg-white cursor-pointer shadow"
                  onClick={() => setImagesArray([])}
                >
                  <img src="/icons/tabler_upload.svg" alt="upload" />
                  <p className="text-[#112532] text-sm font-semibold">
                    Change Photo
                  </p>
                </div>
              </div>
            ) : (
              <ReactDropZone
                variant="profile"
                setImagesArray={setImagesArray}
              />
            )}

            <div className="relative">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.password && true}
                    {...field}
                    // helperText={errors.password && errors.password.message}
                    sx={{
                      "& label.Mui-focused": {
                        color: "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#66a1c7",
                        },
                      },
                    }}
                    className="w-full"
                    label="Password"
                    id="outlined-start-adornment"
                    type={showPassword.new ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPassword.new ? (
                            <VisibilityOffIcon
                              className="cursor-pointer"
                              onClick={() => {
                                setShowPassword({
                                  ...showPassword,
                                  new: false,
                                });
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              className="cursor-pointer"
                              onClick={() => {
                                setShowPassword({ ...showPassword, new: true });
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {!isPasswordValid && password !== "" && (
                <>
                  <div
                    className="flex flex-col gap-y-3 p-3 absolute bg-[#f1f6f6] rounded-md border border-[#ececee] text-sm
                          left-0 right-0 mt-2 md:left-auto md:right-[-320px] bottom-24 md:bottom-[-28px] md:mt-0 
                          w-full md:w-[269px] h-auto md:h-[232px] z-50"
                  >
                    <h1>Your password must contain:</h1>
                    <p
                      className={
                        password.length >= 8
                          ? "text-[#018340] flex gap-x-2"
                          : "text-[#112532] flex gap-x-2"
                      }
                    >
                      <img
                        loading="lazy"
                        className={
                          password.length >= 8
                            ? "visible w-6 h-6"
                            : "invisible w-6 h-6"
                        }
                        src="/icons/bx-check.svg"
                        alt=""
                      />{" "}
                      At least 8 characters
                    </p>
                    <p
                      className={
                        /[a-z]/.test(password)
                          ? "text-[#018340] flex gap-x-2"
                          : "text-[#112532] flex gap-x-2"
                      }
                    >
                      <img
                        loading="lazy"
                        className={
                          /[a-z]/.test(password)
                            ? "visible w-6 h-6"
                            : "invisible w-6 h-6"
                        }
                        src="/icons/bx-check.svg"
                        alt=""
                      />
                      At least one lowercase letter
                    </p>
                    <p
                      className={
                        /[A-Z]/.test(password)
                          ? "text-[#018340] flex gap-x-2"
                          : "text-[#112532] flex gap-x-2"
                      }
                    >
                      <img
                        loading="lazy"
                        className={
                          /[A-Z]/.test(password)
                            ? "visible w-6 h-6"
                            : "invisible w-6 h-6"
                        }
                        src="/icons/bx-check.svg"
                        alt=""
                      />
                      At least one uppercase letter
                    </p>
                    <p
                      className={
                        /\d/.test(password)
                          ? "text-[#018340] flex gap-x-2"
                          : "text-[#112532] flex gap-x-2"
                      }
                    >
                      <img
                        loading="lazy"
                        className={
                          /\d/.test(password)
                            ? "visible w-6 h-6"
                            : "invisible w-6 h-6"
                        }
                        src="/icons/bx-check.svg"
                        alt=""
                      />
                      At least one number
                    </p>
                    <p
                      className={
                        /[@$!%*?&#]/.test(password)
                          ? "text-[#018340] flex gap-x-2"
                          : "text-[#112532] flex gap-x-2"
                      }
                    >
                      <img
                        loading="lazy"
                        className={
                          /[@$!%*?&#]/.test(password)
                            ? "visible w-6 h-6"
                            : "invisible w-6 h-6"
                        }
                        src="/icons/bx-check.svg"
                        alt=""
                      />
                      At least one special character
                    </p>
                  </div>
                  {/* Arrow for password requirements */}
                  <div
                    className="hidden md:block w-0 h-0 absolute right-[-52px] top-[10px] 
                          border-t-[20px] border-b-[20px] border-r-[30px] 
                          border-transparent border-r-[#f1f6f6]"
                  ></div>
                  <div
                    className="md:hidden block w-0 h-0 absolute left-0 bottom-16
             border-l-[44px] border-r-[44px] border-t-[35px] 
             border-transparent border-t-[#f1f6f6] z-50"
                  ></div>
                </>
              )}
            </div>

            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  error={errors.confirmPassword && true}
                  {...field}
                  helperText={
                    errors.confirmPassword && errors.confirmPassword.message
                  }
                  sx={{
                    "& label.Mui-focused": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#66a1c7",
                      },
                    },
                  }}
                  className="w-full"
                  label="Confirm Password"
                  id="outlined-start-adornment1"
                  type={showPassword.confirm ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword.confirm ? (
                          <VisibilityOffIcon
                            className="cursor-pointer"
                            onClick={() => {
                              setShowPassword({
                                ...showPassword,
                                confirm: false,
                              });
                            }}
                          />
                        ) : (
                          <VisibilityIcon
                            className="cursor-pointer"
                            onClick={() => {
                              setShowPassword({
                                ...showPassword,
                                confirm: true,
                              });
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>

          <div className="w-full lg:w-fit">
            <button
              onClick={onSubmit}
              disabled={!isValid || isLoading}
              className="px-10 py-3 w-full bg-[#112532] rounded-[5px] text-[#fffefb] font-bold disabled:opacity-50"
            >
              Join spark
            </button>
          </div>
        </div>

        {/* Right Column for Desktop */}
        <div className=" lg:w-1/3 lg:mt-24">
          <h1 className="py-6 text-black text-lg lg:text-[23px] font-semibold">
            Your Solar Offer
          </h1>
          <div className="flex flex-col gap-y-4 rounded-t-md border border-b-0 border-[#c7cace] px-3 py-4 lg:p-8">
            <div className="flex justify-between">
              <h1 className="text-[23px] font-semibold">
                {selectedOffer.businessOperation}
              </h1>
              <div className="flex items-center gap-x-1">
                <img src={getBadgeIcon(selectedOffer.className)} alt="" />
                <span className="text-[13px] capitalize font-medium">
                  {" "}
                  {selectedOffer.className}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between ">
              <div className="flex items-center gap-x-2">
                <img
                  loading="lazy"
                  className="w-14 h-14 rounded-full"
                  src={tierImage(selectedOffer.tier)}
                  alt=""
                />
                <h1 className="bg-clip-text text-transparent bg-custom-gradient text-lg md:text-[19px] font-semibold">
                  {selectedOffer.tier}
                </h1>
              </div>
              {/* <div className="px-4 py-1 bg-[#f0fafb] rounded border border-[#d8f0f5] text-[#112532] text-sm font-semibold">
                $2.9-$3.1/w
              </div> */}
            </div>
            <p className="text-slate-500 text-sm">
              Opt for maximum efficiency and long-term savings
            </p>
            <div className="flex flex-col flex-wrap gap-3 mb-4">
              <span className="flex items-center text-sm">
                <img
                  loading="lazy"
                  src="/icons/step3.png"
                  alt=""
                  className="w-6 h-6 mr-1"
                />
                REC panels or similar
              </span>
              <span className="flex items-center text-sm">
                <img
                  loading="lazy"
                  src="/icons/shield.svg"
                  alt=""
                  className="mr-1"
                />
                <span className="font-bold">25</span> years warranty
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-3 px-3 py-4 lg:px-8 lg:py-6 bg-[#fef9ec] -mt-6 rounded-b-md border border-t-0 border-[#c7cace]">
            <h1 className="text-lg lg:text-[34px] text-[#112532] font-semibold">
              ${formatPrice(selectedOffer.systemPrice)}
            </h1>
            <p className="text-xs font-semibold">{selectedOffer.description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalInformation;
