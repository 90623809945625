const environment = process.env.REACT_APP_ENV;

const apiUrl =
  environment === "staging"
    ? process.env.REACT_APP_API_URL_STAGING
    : process.env.REACT_APP_API_URL_PRODUCTION;

const socketUrl =
  environment === "staging"
    ? process.env.REACT_APP_SOCKET_URL_STAGING
    : process.env.REACT_APP_SOCKET_URL_PRODUCTION;

const config = {
  environment,
  apiUrl,
  socketUrl,
};

export default config;
