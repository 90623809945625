import React, { forwardRef, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fade,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "components/shared/Header";
import { GETdashboardInfo } from "services/homeOwnerServices";
import { CustomizeTabs } from "components/MUI/CustomizeTabs";
import HomeOwnerDashboardSkeleton from "../skeleton/home-owner/HomeOwnerDashboardSkeleton ";
import useResponsive from "hooks/useResponsive";
import { tierImage } from "utils/constant";
import { formatPrice } from "utils/formatNumber";

const HomeOwnerDashboard = () => {
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardDealInfo, setDashboardDealInfo] = useState([]);
  const { isSm } = useResponsive();
  const [expanded, setExpanded] = useState();

  useEffect(() => {
    setExpanded(!isSm);
  }, [isSm]);

  const navigate = useNavigate();

  const isHomeOwnerSigned =
    dashboardDealInfo?.allOffersDeals?.homeOwner_contract_signed;
  const isBothSigned =
    dashboardDealInfo?.allOffersDeals?.homeOwner_contract_signed &&
    dashboardDealInfo?.allOffersDeals?.installer_contract_signed;

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await GETdashboardInfo();
      setDashboardDealInfo(res);
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const CustomTransition = forwardRef((props, ref) => {
    const { children, in: inProp, ...other } = props;
    return (
      <Fade ref={ref} in={inProp} timeout={300} {...other}>
        {children}
      </Fade>
    );
  });

  const tabs = [
    {
      label: "To Do",
      value: 0,
      sx: { color: "#000", fontSize: isSm ? 14 : 16 },
      disableRipple: true,
      ariaLabel: "First tab",
    },
    {
      label: "Pending",
      value: 1,
      sx: { color: "#000", fontSize: isSm ? 14 : 16 },
      disableRipple: true,
      ariaLabel: "Second tab",
    },
    {
      label: "Done",
      value: 2,
      sx: { color: "#000", fontSize: isSm ? 14 : 16 },
      disableRipple: true,
      ariaLabel: "Third tab",
    },
  ];

  const tabPanels = [
    {
      content: (
        <>
          {!isHomeOwnerSigned && (
            <div
              onClick={() => navigate("/dashboard/contract")}
              className="flex flex-col gap-y-4 cursor-pointer bg-[#F7F6F5] rounded-lg shadow"
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex gap-y-3 gap-x-2">
                  <img
                    loading="lazy"
                    className="self-start"
                    src="/icons/contract.svg"
                    alt=""
                  />
                  <div className="flex flex-col gap-y-2">
                    <h1 className="text-[#112532] text-base font-semibold">
                      Sign the contract
                    </h1>
                    <p className="text-sm">
                      Please read the contract and sign it to move to the next
                      step
                    </p>
                  </div>
                </div>
                <img loading="lazy" src="/icons/right-pag.svg" alt="" />
              </div>
            </div>
          )}
          {!dashboardDealInfo?.site_survy && (
            <div
              className="flex flex-col gap-y-4 cursor-pointer bg-[#F7F6F5] rounded-lg shadow"
              onClick={() => {
                if (!isHomeOwnerSigned)
                  return toast.error(
                    "You cannot access the site survey until you sign the contract. Please sign the contract first to proceed"
                  );
                navigate("/dashboard/site-survey");
              }}
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex gap-y-3 gap-x-2">
                  <img
                    loading="lazy"
                    className="self-start"
                    src="/icons/ph_camera.svg"
                    alt=""
                  />
                  <div className="flex flex-col gap-y-2">
                    <h1 className="text-[#112532] text-base font-semibold">
                      Site survey
                    </h1>
                    <p className="text-sm">
                      Complete your site survey by uploading indoor and outdoor
                      photo requirements.
                    </p>
                  </div>
                </div>
                <img loading="lazy" src="/icons/right-pag.svg" alt="" />
              </div>
            </div>
          )}
          {true && (
            <div
              className="flex flex-col gap-y-4 cursor-pointer bg-[#F7F6F5] rounded-lg shadow"
              onClick={() => {
                if (!isBothSigned)
                  return toast.error(
                    "You cannot access the refer a friend until you and the installer sign the contract."
                  );
                navigate("/dashboard/refer-friend");
              }}
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex gap-y-3 gap-x-2">
                  <img
                    loading="lazy"
                    className="self-start w-6"
                    src="/icons/money.svg"
                    alt=""
                  />
                  <div className="flex flex-col gap-y-2">
                    <h1 className="text-[#112532] text-base font-semibold">
                      Refer a friend
                    </h1>
                    <p className="text-sm">
                      We give your friend $100 off their deal, and you get $100
                      gift card
                    </p>
                  </div>
                </div>
                <img loading="lazy" src="/icons/right-pag.svg" alt="" />
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      content: (
        <>
          {isHomeOwnerSigned && !isBothSigned && (
            <div className="flex flex-col gap-y-4 cursor-pointer bg-[#F7F6F5] rounded-lg shadow">
              <div className="flex items-center justify-between p-4">
                <div className="flex gap-y-3 gap-x-2">
                  <img
                    loading="lazy"
                    className="self-start"
                    src="/icons/contract.svg"
                    alt=""
                  />
                  <div className="flex flex-col gap-y-2">
                    <h1 className="text-[#112532] text-base font-semibold">
                      Awaiting Installer Signature
                    </h1>
                    <p className="text-sm">
                      The installer has not signed the contract yet.
                    </p>
                  </div>
                </div>
                <img loading="lazy" src="/icons/right-pag.svg" alt="" />
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      content: (
        <>
          {isBothSigned && (
            <div className="flex flex-col gap-y-4 cursor-pointer bg-[#F7F6F5] rounded-lg shadow">
              <div className="flex items-center justify-between p-4">
                <div className="flex gap-y-3 gap-x-2">
                  <img
                    loading="lazy"
                    className="self-start"
                    src="/icons/contract.svg"
                    alt=""
                  />
                  <div className="flex flex-col gap-y-2">
                    <h1 className="text-[#112532] text-base font-semibold">
                      Contract Signed
                    </h1>
                    <p className="text-sm">
                      The contract has been successfully signed.
                    </p>
                  </div>
                </div>
                <img loading="lazy" src="/icons/right-pag.svg" alt="" />
              </div>
            </div>
          )}
          {dashboardDealInfo?.site_survy && (
            <div
              className="flex flex-col gap-y-4 cursor-pointer bg-[#F7F6F5] rounded-lg shadow"
              onClick={() => navigate("/dashboard/site-survey")}
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex gap-y-3 gap-x-2">
                  <img
                    loading="lazy"
                    className="self-start"
                    src="/icons/ph_camera.svg"
                    alt=""
                  />
                  <div className="flex flex-col gap-y-2">
                    <h1 className="text-[#112532] text-base font-semibold">
                      Site survey
                    </h1>
                    <p className="text-sm">
                      Complete your site survey by uploading indoor and outdoor
                      photo requirements.
                    </p>
                  </div>
                </div>
                <img loading="lazy" src="/icons/right-pag.svg" alt="" />
              </div>
            </div>
          )}
        </>
      ),
    },
  ];

  if (isLoading)
    return (
      <>
        <Header />
        <HomeOwnerDashboardSkeleton />
      </>
    );
  return (
    <>
      <Header />
      <div className="flex flex-col md:flex-row min-h-screen bg-gray-50">
        {/* Main Content */}
        <div className="w-full md:w-3/4 p-4 md:p-6 md:pt-0 space-y-4">
          {/* Solar Journey Accordion */}
          <Accordion
            expanded={expanded}
            onChange={handleExpansion}
            className="rounded-lg overflow-hidden"
            components={{ Transition: CustomTransition }}
            sx={{
              backgroundImage: isSm
                ? "url('/images/accordion-mobile.png')"
                : `url('/images/accordion.png')`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              transition: "height 300ms ease-out",
              "& .MuiAccordion-region": {
                height: expanded ? "auto" : 0,
              },
              "& .MuiAccordionDetails-root": {
                display: expanded ? "block" : "none",
                opacity: expanded ? 1 : 0,
                transition: "opacity 300ms ease-out",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ fill: "white", width: "24px", height: "24px" }}
                />
              }
              aria-controls="panel1-content"
              id="panel1-header"
              className="p-4"
            >
              <h1 className="text-lg md:text-[23px] text-white font-semibold">
                My Solar Journey
              </h1>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1 p-4 bg-[#FFFFFF0A] rounded-md">
                  <img
                    loading="lazy"
                    className="w-6 h-6 mb-2"
                    src="/icons/Step Success.svg"
                    alt=""
                  />
                  <h1 className="font-semibold text-white">Solar Assessment</h1>
                  <p className="text-sm text-[#F7F6F5]">
                    Explore your solar potential and activate account.
                  </p>
                </div>
                <div className="flex-1 p-4 bg-[#FFFFFF0A] rounded-md">
                  <img
                    loading="lazy"
                    className="w-6 h-6 mb-2"
                    src={
                      isHomeOwnerSigned
                        ? "/icons/Step Success.svg"
                        : "/icons/Step Shape.svg"
                    }
                    alt=""
                  />
                  <h1 className="font-semibold text-white">
                    Contract/ Schedule
                  </h1>
                  <p className="text-sm text-[#F7F6F5]">
                    Sign the contract and schedule with your installer.
                  </p>
                </div>
                <div className="flex-1 p-4 bg-[#FFFFFF0A] rounded-md">
                  <img
                    loading="lazy"
                    className="w-6 h-6 mb-2"
                    src={
                      dashboardDealInfo?.site_survy
                        ? "/icons/Step Success.svg"
                        : isHomeOwnerSigned
                          ? "/icons/Step Shape.svg"
                          : "/icons/notactivestep.svg"
                    }
                    alt=""
                  />
                  <h1 className="font-semibold text-white">Site Survey</h1>
                  <p className="text-sm text-[#F7F6F5]">
                    Save up to $250 by completing your site survey.
                  </p>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Mobile What's Next Section */}
          <div className="md:hidden bg-white rounded-lg p-4 shadow-sm">
            <h1 className="text-lg font-semibold mb-4">What's Next?</h1>
            <CustomizeTabs
              value={value}
              onChange={handleChange}
              tabs={tabs}
              tabPanels={tabPanels}
            />
          </div>

          {/* Deal Overview Section */}
          <div className="space-y-4">
            <h1 className="text-xl md:text-[23px] text-[#112532] font-semibold">
              Deal Overview
            </h1>
            <div className="flex flex-col md:flex-row gap-4">
              {/* Installer Details Card */}
              <div className="flex-1 bg-white p-4 md:p-6 rounded-lg border border-[#D9D3CF]">
                <Link
                  to={"/dashboard/installer-profile"}
                  className="block text-lg md:text-[19px] font-semibold mb-4 underline cursor-pointer hover:text-[#D0680F]"
                >
                  Installer Details
                </Link>
                <div className="flex gap-3 flex-nowrap">
                  <img
                    loading="lazy"
                    className="w-14 h-14 rounded-full"
                    src={
                      dashboardDealInfo?.allOffersDeals?.Installer?.logo ||
                      "/images/bxs_business.svg"
                    }
                    alt=""
                  />
                  <div className="flex flex-col gap-y-3">
                    <h2 className="text-base md:text-lg font-semibold w-fit">
                      {
                        dashboardDealInfo?.allOffersDeals?.Installer
                          ?.business_name
                      }
                    </h2>
                    <div className="flex items-center">
                      <img
                        loading="lazy"
                        src="/icons/star.svg"
                        alt="star icon"
                      />
                      <h1 className="text-[#112532] text-xs font-semibold">
                        {dashboardDealInfo?.data?.reviewSummary?.averageRating}
                        <span className="font-normal"> /</span>
                        <span className="font-normal">5</span>
                      </h1>
                    </div>
                    <div className="flex gap-2 -ml-16 md:ml-0">
                      <span className="px-3 py-1 text-sm bg-[#F0FAFB] border border-[#D8F0F5] font-semibold rounded">
                        Fast installation
                      </span>
                      <span className="px-3 py-1 text-sm bg-[#F0FAFB] border border-[#D8F0F5] font-semibold rounded">
                        Excellent Warranty
                      </span>
                    </div>
                    <div className="text-sm -ml-16 ">
                      <p className="flex gap-x-2 items-center py-1">
                        <img
                          loading="lazy"
                          src="/icons/location_on.svg"
                          alt=""
                        />
                        {
                          dashboardDealInfo?.allOffersDeals?.Installer
                            ?.business_address
                        }
                      </p>
                      <p className="flex gap-x-2 items-center">
                        <img loading="lazy" src="/icons/home_work.svg" alt="" />
                        Covers{" "}
                        {
                          dashboardDealInfo?.allOffersDeals?.Installer?.state
                        }{" "}
                        area
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* System Details Card */}
              <div className="flex-1 bg-white p-4 md:p-6 rounded-lg border border-[#D9D3CF]">
                <Link
                  to={"/dashboard/deal-overview"}
                  className="block text-lg md:text-[19px] font-semibold mb-4 underline cursor-pointer hover:text-[#D0680F]"
                >
                  System Details
                </Link>
                <div className="flex gap-3">
                  <img
                    loading="lazy"
                    className="w-14 h-14 rounded-full"
                    src={tierImage(
                      dashboardDealInfo?.allOffersDeals?.system_type
                    )}
                    alt=""
                  />
                  <div className="flex flex-col gap-y-3 flex-1">
                    <div className="flex justify-between flex-wrap gap-2">
                      <h1 className="bg-clip-text text-transparent bg-custom-gradient text-lg md:text-[19px] font-semibold">
                        {dashboardDealInfo?.allOffersDeals?.system_type}
                      </h1>
                    </div>
                    <p className="text-sm text-[#64748B] -ml-16 mt-6 md:m-0">
                      Opt for maximum efficiency and long-term savings
                    </p>
                    <div className="flex flex-wrap gap-3 -ml-16 md:m-0">
                      <span className="flex items-center text-sm">
                        <img
                          loading="lazy"
                          src="/icons/step3.png"
                          alt=""
                          className="w-6 h-6 mr-1"
                        />
                        REC panels or similar
                      </span>
                      <span className="flex items-center text-sm">
                        <img
                          loading="lazy"
                          src="/icons/shield.svg"
                          alt=""
                          className="mr-1"
                        />
                        <span className="font-bold">25</span> years warranty
                      </span>
                    </div>
                    <div className="flex flex-col gap-y-3 mt-2 -ml-16 md:m-0">
                      <h1 className="text-[#112532] font-semibold">
                        System Cost
                      </h1>
                      <div className="py-3 px-4 bg-[#FEF9EC] flex flex-wrap justify-between items-center gap-2">
                        <h1 className="text-lg md:text-[19px] text-[#112532] font-semibold">
                          $
                          {formatPrice(
                            dashboardDealInfo?.allOffersDeals
                              ?.User_solar_insight?.finalPrice
                          )}
                        </h1>
                        <p className="text-red-700 text-xs font-semibold">
                          $19,000 Total Savings (over 25 years)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Desktop What's Next Section */}
        <div className="hidden md:block border-1 px-8 py-6 h-screen rounded-lg w-[27%] bg-white mr-6">
          <h1 className="text-[19px] text-[#112532] font-semibold mb-4">
            What's Next?
          </h1>
          <CustomizeTabs
            value={value}
            onChange={handleChange}
            tabs={tabs}
            tabPanels={tabPanels}
          />
        </div>
      </div>
    </>
  );
};

export default HomeOwnerDashboard;
