import ReactDropZone from "components/shared/ReactDropZone";
import SurveyHasBeenSubmitted from "components/home-owner/SurveyHasBeenSubmitted";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getUploadedSurvey,
  uploadSurveyImage,
} from "services/homeOwnerServices";
import {
  setIndoorStep,
  setOutdoorStep,
  setSurveyCompleted,
} from "../../redux/actions";
import CustomStepper from "../MUI/CustomStepper";
import BreadCrumb from "components/shared/BreadCrumb";
import { useNavigate } from "react-router-dom";

const SiteSurveySteps = ({
  handleBack,
  handleNextFromStep,
  setShouldRenderSteps,
  steps,
  images = [],
  showSubmittedDialog,
}) => {
  const [formData, setFormData] = useState(null);
  const [imagesArray, setImagesArray] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSubmitted, setShowSubmitted] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isSurveyCompleted = useSelector(
    (state) => state.siteSurvey.isSurveyCompleted
  );
  const currentSurveyType = useSelector(
    (state) => state.siteSurvey.currentSurveyType
  );
  const indoorActiveStep = useSelector(
    (state) => state.siteSurvey.indoorActiveStep
  );
  const outdoorActiveStep = useSelector(
    (state) => state.siteSurvey.outdoorActiveStep
  );

  const surveyTypeIncrementStep = () => {
    if (currentSurveyType) {
      if (outdoorActiveStep >= 6) {
        setShowSubmitted(true);
        dispatch(setSurveyCompleted("outdoor", true));
        return;
      }

      return dispatch(setOutdoorStep(outdoorActiveStep + 1));
    } else {
      if (indoorActiveStep >= 3) {
        setShowSubmitted(true);
        dispatch(setSurveyCompleted("indoor", true));
        return;
      }

      return dispatch(setIndoorStep(indoorActiveStep + 1));
    }
  };
  const surveyTypeDecrementStep = () => {
    setShowSubmitted(false);
    if (currentSurveyType) {
      if (outdoorActiveStep === 0) return setShouldRenderSteps(false);
      return dispatch(setOutdoorStep(outdoorActiveStep - 1));
    } else {
      if (indoorActiveStep === 0) return setShouldRenderSteps(false);
      return dispatch(setIndoorStep(indoorActiveStep - 1));
    }
  };

  const stepperActiveStep =
    currentSurveyType === 0 ? indoorActiveStep : outdoorActiveStep;

  const uploadImage = async () => {
    if (!imagesArray[0].file) return;

    const formData = new FormData();
    formData.append("img", imagesArray[0].file);
    formData.append("image_type", images.DB_Name);

    try {
      setIsLoading(true);
      // Await the upload request
      const res = await uploadSurveyImage(formData);
      // Reset imagesArray only after successful upload
      setImagesArray([]);
    } catch (err) {
      // Fallback in case err.response or err.response.data are undefined
      const errorMessage =
        err.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  const handleImageDelete = (index) => {
    const updatedImages = imagesArray.filter((_, i) => i !== index);
    setImagesArray(updatedImages);
  };

  // Fetch the uploaded survey images on component mount if the variant is default
  const fetchData = async () => {
    try {
      const res = await getUploadedSurvey();
      if (res?.survey[images.DB_Name]) {
        setImagesArray([res.survey[images.DB_Name]]); // Set the fetched image to the images array in parent
      }
    } catch (err) {
      setError("Failed to fetch image data.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="ml-8">
        <BreadCrumb
          paths={[
            { name: "Dashboard", pathLink: "/dashboard" },
            {
              name: "Site Survey",
              pathLink: `/dashboard/site-survey`,
            },
          ]}
          isShowConfirmationDialog={
            indoorActiveStep >= 0 || outdoorActiveStep >= 0
          }
          onConfirm={() => {
            navigate("/dashboard");
          }}
          title="Return to Dashboard?"
          subTitle={
            imagesArray.length > 0
              ? "Some changes have not been saved. Do you wish to proceed?"
              : "You have not uploaded any images. Do you wish to return to the dashboard?"
          }
          confirmText="Yes"
          cancelText="No"
        />
      </div>
      <div className="mx-8 my-6 rounded-[10px] md:border border-[#d9d3cf]">
        <div className="flex flex-col md:items-center gap-y-2 md:pt-10">
          <h1 className="font-semibold text-sm lg:text-[28px]">{`Site Survey/ ${
            currentSurveyType === 0 ? "Indoor" : "Outdoor"
          } Photos`}</h1>
        </div>
        <div
          className={` mx-auto ${currentSurveyType === 0 ? "lg:w-1/3" : "lg:w-2/5"}`}
        >
          <CustomStepper
            width={"100%"}
            steps={steps}
            activeStepSolar={stepperActiveStep}
            orientation="horizontal"
          />
        </div>
        <div className="flex justify-center">
          <div className="flex justify-evenly items-center gap-x-5 gap-y-5 flex-col md:flex-row md:w-[735px] md:h-[383px] lg:w-[1000px]">
            <div className="w-full h-full flex flex-col items-center gap-y-5 md:self-start bg-white p-4 rounded-lg shadow border border-[#d9d3cf]">
              <div className=" md:w-[256.18px] md:h-[268.76px] lg:w-[400px]">
                <img
                  loading="lazy"
                  className="object-contain w-full h-full"
                  src={images.src}
                  alt={images.alt}
                />
              </div>
              <div className="self-start">
                <h1 className="font-semibold">{images.title}</h1>
                <p className="text-[#8D98AA] text-[14px]">
                  {images.description}
                </p>
              </div>
            </div>

            {imagesArray.length === 0 ? (
              <div className="w-full h-full bg-[#f9f9f9] rounded-lg shadow">
                <ReactDropZone
                  setFormData={setFormData}
                  setImagesArray={setImagesArray}
                />
              </div>
            ) : (
              <div className="flex flex-col justify-center relative w-full h-full text-center rounded-lg shadow border border-[#d9d3cf] p-[14px] bg-white">
                <img
                  loading="lazy"
                  src={imagesArray[0]?.preview || imagesArray}
                  alt="preview"
                  className="object-contain max-h-[327px]"
                />
                <div
                  className="flex justify-center items-center py-2 bg-white cursor-pointer"
                  onClick={() => handleImageDelete(0)}
                >
                  <img src="/icons/tabler_upload.svg" alt="upload" />
                  <p className="text-[#112532] text-sm font-semibold">
                    Change Photo
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {error && imagesArray.length === 0 && (
          <p className="text-red-500 text-center mt-5">
            Please upload an image before clicking Next
          </p>
        )}
        <div className="flex flex-col-reverse md:flex-row justify-center gap-x-6 gap-y-3 py-8">
          <button
            className="px-10 py-3 rounded-[5px] border border-[#112532] text-[#112532] hover:border-[#D97706] hover:text-[#D97706]"
            onClick={surveyTypeDecrementStep}
          >
            Back
          </button>
          <button
            className="px-10 py-3 bg-[#112532] rounded-[5px] text-white font-bold hover:bg-[#D97706]"
            type="button"
            onClick={() => {
              if (imagesArray.length === 1) {
                surveyTypeIncrementStep();
                uploadImage();
              } else {
                setError("Please upload an image before clicking Next");
              }
            }}
          >
            {(indoorActiveStep === 3 && !isSurveyCompleted.indoor) ||
            (outdoorActiveStep === 6 && !isSurveyCompleted.outdoor)
              ? "Submit"
              : "Next"}
          </button>
        </div>

        {showSubmittedDialog &&
          !(isSurveyCompleted.indoor && isSurveyCompleted.outdoor) && (
            <SurveyHasBeenSubmitted
              setShowSubmitted={setShowSubmitted}
              showSubmitted={true}
            />
          )}
        {showSubmitted &&
          !(isSurveyCompleted.indoor && isSurveyCompleted.outdoor) && (
            <SurveyHasBeenSubmitted
              setShowSubmitted={setShowSubmitted}
              showSubmitted={showSubmitted}
            />
          )}

        <SurveyHasBeenSubmitted
          both={isSurveyCompleted.indoor && isSurveyCompleted.outdoor}
          showSubmitted={showSubmitted}
          setShowSubmitted={setShowSubmitted}
        />
      </div>
    </>
  );
};

export default SiteSurveySteps;
