import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { GETavailableStates } from "services/installerServices";
import { USAstates } from "utils/constant";
import { handleKeyDown } from "utils/formHelpers";

const BusinessRegisterDetails = ({ errors, control }) => {
  // const [availableStates, setAvailableStates] = useState([]);
  // const fetchData = async () => {
  //   try {
  //     const res = await GETavailableStates();
  //     setAvailableStates(res.states);
  //   } catch (error) {}
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <div className="flex flex-col gap-y-6">
      <Controller
        name="business_name"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              height: "fit-content",
            }}
            {...field}
            error={errors.business_name && true}
            helperText={errors.business_name && errors.business_name.message}
            label="Business Name"
            variant="outlined"
          />
        )}
      />
      <Controller
        name="mobile"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.mobile}
            helperText={errors.mobile && errors.mobile.message}
            onKeyDown={handleKeyDown}
            onWheel={(e) => e.target.blur()}
            type="number"
            id="outlined-basic"
            label="Phone Number"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <div className="pl-2 pr-3 mr-[10px] border-r-[1px] border-[#C7CACE]">
                  +1
                </div>
              ),
            }}
          />
        )}
      />
      <Controller
        name="country"
        control={control}
        render={({ field }) => (
          <TextField
            disabled
            {...field}
            error={!!errors.country}
            helperText={errors.country && errors.country.message}
            id="outlined-basic"
            label="Country"
            variant="outlined"
            value={"United States of America (USA)"}
            sx={{ backgroundColor: "#E0E4EA", borderRadius: "10px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img loading="lazy" src="/icons/search.svg" alt="" />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Controller
        name="state"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            fullWidth
            value={value}
            onChange={(event, newValue) => {
              onChange(newValue);
            }}
            id="controllable-states-demo"
            options={USAstates}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                ],
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                error={!!errors.state}
                helperText={errors.state && errors.state.message}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img loading="lazy" src="/icons/search.svg" alt="" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}
      />
      <div className=" flex gap-x-3">
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.city}
              helperText={errors.city && errors.city.message}
              id="outlined-basic"
              label="City"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="post_code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.post_code}
              helperText={errors.post_code && errors.post_code.message}
              onKeyDown={(e) => {
                if (
                  !/[0-9]/.test(e.key) &&
                  e.key !== "Backspace" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight" &&
                  e.key !== "Delete" &&
                  e.key !== "Tab"
                ) {
                  e.preventDefault();
                }
              }}
              inputProps={{
                maxLength: 9,
              }}
              type="text"
              id="outlined-basic"
              label="Post Code"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      <h2 className="text-[#244255] text-[19px] font-semibold">
        Business Operations
      </h2>
      <FormControl>
        <Controller
          name="business_operation"
          control={control}
          render={({ field }) => (
            <RadioGroup
              aria-labelledby="controlled-radio-buttons-group"
              {...field} // Spread field props to manage the value and onChange automatically
            >
              <FormControlLabel
                value="Local"
                control={<Radio />}
                label="Local"
              />
              <p className="ml-7 text-[#244255] text-sm">
                Your business operates within a specific, limited area, such as
                a city or a small geographic region.
              </p>

              <FormControlLabel
                value="Regional"
                control={<Radio />}
                label="Regional"
              />
              <p className="ml-7 text-[#244255] text-sm">
                Your business operates across a larger area that spans multiple
                cities or a significant part of a state or province.
              </p>

              <FormControlLabel
                value="National"
                control={<Radio />}
                label="National"
              />
              <p className="ml-7 text-[#244255] text-sm">
                Your business operates across the entire country.
              </p>
            </RadioGroup>
          )}
        />
        {errors.business_operation && (
          <p style={{ color: "red" }}>{errors.business_operation.message}</p>
        )}
      </FormControl>
    </div>
  );
};

export default BusinessRegisterDetails;
