import PartnersLoginForm from "components/partners/auth/PartnersLoginForm";

import Header from "components/shared/Header";
import AuthLayout from "layout/AuthLayout";

const PartnersLogin = () => {
  return (
    <>
      <Header />
      <AuthLayout imagePath={"/images/partners-backdrop.png"}>
        <PartnersLoginForm />
      </AuthLayout>
    </>
  );
};

export default PartnersLogin;
