import { useState, useEffect } from "react";

const getWindowWidth = () => {
  // Check if window is defined (for SSR compatibility)
  if (typeof window === "undefined") {
    return 1024; // Default fallback width
  }
  return window.innerWidth;
};

const useResponsive = () => {
  const [width, setWidth] = useState(getWindowWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    isSm: width <= 640,
    md: width <= 768,
    lg: width <= 1024,
    xl: width <= 1280,
    "2xl": width <= 1536,
  };
};

export default useResponsive;
