import { getDifferenceType } from "utils/constant";
import ApiService from "./api";
import dayjs from "dayjs";

const baseURL = "/admin";

export const adminLogin = async (email: string, password: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-managment/login-admin`,
    method: "POST",
    data: {
      email,
      password,
    },
  });
  return response;
};

export const GETadminPersonalDetails = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-profile/admin-info`,
    method: "GET",
  });
  return response;
};

export const POSTadminChangeAuthenticatedPassword = async (
  currentPassword: string,
  newPassword: string
) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-profile/change-password`,
    method: "POST",
    data: { currentPassword, newPassword },
  });
  return response;
};

export const PATCHadminPersonalDetailsUpdate = async (formData: {
  first_name: string;
  last_name: string;
}) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-profile/update-admin-field`,
    method: "PATCH",
    data: formData,
  });
  return response;
};

export const PATCHadminProfileImageUpdate = async (formData: {
  first_name: string;
  last_name: string;
}) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-profile/update-profile-img`,
    method: "PATCH",
    data: formData,
  });
  return response;
};

export const DELETEadminProfilePic = async () => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/admin-profile/delete-profile-img`,
    method: "DELETE",
  });
  return response;
};

export const POSThomeowenersDashboardData = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/filter/homeowners`,
    method: "POST",
    data,
  });
  return response;
};
// export const GEThomeowenersDashboardData = async (dateFilter: {
//   from: string;
//   to: string;
// }) => {
//   const response = await ApiService.fetchData({
//     url: `${baseURL}/dashboard/filter/homeowners-contracts-leads?fromDate=${dayjs(dateFilter.from).format("YYYY-MM-DD")}&toDate=${dayjs(dateFilter.to).format("YYYY-MM-DD")}&period=${getDifferenceType(dateFilter.from, dateFilter.to)}`,
//     method: "GET",
//   });
//   return response;
// };

export const POSTinstallersDashboardData = async (data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/filter/installers`,
    method: "POST",
    data,
  });
  return response;
};
// export const GETinstallersDashboardData = async (dateFilter: {
//   from: string;
//   to: string;
// }) => {
//   const response = await ApiService.fetchData({
//     url: `${baseURL}/dashboard//filter/installers-and-deals?fromDate=${dayjs(dateFilter.from).format("YYYY-MM-DD")}&toDate=${dayjs(dateFilter.to).format("YYYY-MM-DD")}&period=${getDifferenceType(dateFilter.from, dateFilter.to)}`,
//     method: "GET",
//   });
//   return response;
// };

export const POSTallInstallersData = async (filters: any, page: number) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/all-installers`,
    method: "POST",
    data: { ...filters, page },
  });
  return response;
};

export const PUTupdateInstallerData = async (id: string, data: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/dashboard/installer/update/${id}`,
    method: "PUT",
    data,
  });
  return response;
};

export const POSTallHomeownersData = async (filters: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/homeowner/list`,
    method: "POST",
    data: filters,
  });
  return response;
};

export const GEToffersByInstallerId = async (id: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/installer/offers/${id}`,
    method: "GET",
  });
  return response;
};

export const POSTdealsByInstallerId = async (id: string, filters: any) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/installer/deals/${id}`,
    method: "POST",
    data: filters,
  });
  return response;
};

export const GETinstallerProfileById = async (id: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/installer/portfolio/${id}`,
    method: "GET",
  });
  return response;
};

export const DELETEinstallerTags = async (id: string, tagId: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/installer/tags/${id}`,
    method: "DELETE",
    data: { tagId: tagId },
  });
  return response;
};

export const POSTinstallerTags = async (id: string, tag: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/installer/tags/${id}`,
    method: "POST",
    data: { tag: tag },
  });
  return response;
};

export const GETinstallerById = async (id: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/installer/summary/${id}`,
    method: "GET",
  });
  return response;
};

export const GEThomeownerDealById = async (id: string) => {
  const response = await ApiService.fetchData({
    url: `${baseURL}/homeowner/overview/${id}`,
    method: "GET",
  });
  return response;
};
