import { Modal } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const AreaIsUnsupported = ({ showModal, setShowModal }) => {
  const handleClose = () => {
    setShowModal(false);
  };
  const modalStyle =
    "flex flex-col items-center justify-center gap-3 text-center bg-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 px-16 pt-8 pb-6 w-11/12 sm:w-5/6 md:w-[40%]";
  return (
    <Modal
      open={showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiBackdrop-root": {
          background: "rgba(17, 37, 50, 0.5)",
        },
      }}
    >
      <div className={`${modalStyle} md:h-[324px] bg-white rounded `}>
        <img
          loading="lazy"
          className="absolute top-0 right-0 pr-3 pt-3 cursor-pointer"
          src="/icons/close-survey.svg"
          alt=""
          onClick={handleClose}
        />
        <div>
          <img
            loading="lazy"
            className="max-w-full"
            src="/images/unsupportedArea.png"
            alt="drop zone"
          />
        </div>
        <div className="flex flex-col gap-y-3">
          <h1 className="font-semibold text-[16px] md:text-[19px] text-[#112532]">
            Selected Area is Unsupported!
          </h1>
          <p className="text-sm text-[#112532]">
            Please be patient, Spark is constantly trying to cover more areas.
            Need help?
          </p>
        </div>
        <Link to="/contact-us" className="w-full">
          <button className="text-center text-slate-50 text-sm font-bold p-2 w-full md:w-auto px-10 py-3 bg-[#112532] rounded-[5px]">
            Contact Us
          </button>
        </Link>
      </div>
    </Modal>
  );
};

export default AreaIsUnsupported;
