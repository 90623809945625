// EquipmentList.js
import { Drawer } from "@mui/material";
import EquipmentListForm from "components/shared/EquipmentListForm";
import { CustomizeTabs } from "components/MUI/CustomizeTabs";
import { useEffect, useState } from "react";
import { getOffersList } from "services/installerServices";
import Tier from "./Tier";
import { useOutletContext } from "react-router-dom";

const EquipmentList = ({ adminOffers }) => {
  const dashboardSidebar = useOutletContext();
  const [tabValue, setTabValue] = useState(0);
  const [allOffers, setallOffers] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("");
  const [offerId, setOfferId] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [state, setState] = useState({
    right: false,
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleDrawer = (anchor, open, formType, offerId) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open) {
      setFormType(formType);
      setOfferId(offerId);
    }

    setState({ ...state, [anchor]: open });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await getOffersList();
      setallOffers(res);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [trigger]);

  useEffect(() => {
    if (adminOffers) {
      setallOffers(adminOffers);
    }
  }, []);

  const tabs = [
    {
      label: "First Tier",
      icon: (
        <img
          loading="lazy"
          className="w-8 md:w-10"
          src="/icons/Stars1.svg"
          alt=""
        />
      ),
      value: 0,
      sx: {
        color: "#000",
        fontSize: { xs: 14, md: 16 },
        minHeight: { xs: "auto", md: "48px" },
        padding: { xs: "8px", md: "12px 16px" },
      },
      disableRipple: true,
      ariaLabel: "First tab",
    },
    {
      label: "Second Tier",
      icon: (
        <img
          loading="lazy"
          className="w-8 md:w-10"
          src="/icons/Stars2.svg"
          alt=""
        />
      ),
      value: 1,
      sx: {
        color: "#000",
        fontSize: { xs: 14, md: 16 },
        minHeight: { xs: "auto", md: "48px" },
        padding: { xs: "8px", md: "12px 16px" },
      },
      disableRipple: true,
      ariaLabel: "Second tab",
      disabled: !allOffers[0],
    },
    {
      label: "Third Tier",
      icon: (
        <img
          loading="lazy"
          className="w-8 md:w-10"
          src="/icons/Stars3.svg"
          alt=""
        />
      ),
      value: 2,
      sx: {
        color: "#000",
        fontSize: { xs: 14, md: 16 },
        minHeight: { xs: "auto", md: "48px" },
        padding: { xs: "8px", md: "12px 16px" },
      },
      disableRipple: true,
      ariaLabel: "Third tab",
      disabled: !allOffers[1],
    },
  ];

  const tabPanels = [
    {
      content: (
        <Tier
          toggleDrawer={toggleDrawer}
          data={allOffers[0]}
          tierName="First Tier"
          loadingState={isLoading}
          setTrigger={setTrigger}
        />
      ),
    },
    {
      content: (
        <Tier
          toggleDrawer={toggleDrawer}
          data={allOffers[1]}
          tierName="Second Tier"
          loadingState={isLoading}
          setTrigger={setTrigger}
        />
      ),
    },
    {
      content: (
        <Tier
          toggleDrawer={toggleDrawer}
          data={allOffers[2]}
          tierName="Third Tier"
          loadingState={isLoading}
          setTrigger={setTrigger}
        />
      ),
    },
  ];

  return (
    <div className="px-4 md:px-8 pb-6 flex-1">
      <div className="flex flex-col gap-y-4">
        <div className="flex items-center gap-x-2 p-6 pl-0">
          <div className="lg:hidden">{dashboardSidebar}</div>
          <h1 className="text-[23px] md:text-[28px] font-semibold">
            Equipment List
          </h1>
        </div>
        <h2 className="text-[#244255] text-lg md:text-xl font-semibold">
          System Tiers
        </h2>
        <div className="w-full overflow-x-auto">
          <CustomizeTabs
            value={tabValue}
            onChange={handleTabChange}
            tabs={tabs}
            tabPanels={tabPanels}
            tabsWidth="100%"
          />
        </div>
      </div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        sx={{
          "& .MuiBackdrop-root": {
            background: "rgba(17, 37, 50, 0.5)",
          },
        }}
        PaperProps={{
          sx: {
            width: { xs: "100%", md: 500 },
            height: { xs: "85%", md: "100%" },
            overflowX: "hidden",
            bottom: 0,
            top: "auto",
            "& > div:first-of-type": {
              height: "100%",
            },
          },
        }}
      >
        <EquipmentListForm
          toggleDrawer={toggleDrawer}
          formType={formType}
          formData={allOffers}
          offerId={offerId}
          setTrigger={setTrigger}
        />
      </Drawer>
    </div>
  );
};

export default EquipmentList;
