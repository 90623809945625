import BreadCrumb from "components/shared/BreadCrumb";
import GalleryCarousel from "components/shared/GalleryCarousel";
import PanelMap from "PanelMap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GEThomeownerDealById } from "services/AdminServices";
import { dealStatus } from "utils/constant";
import { formatPrice } from "utils/formatNumber";

const HomeownerDeal = () => {
  const params = useParams();
  const [dealData, setDealData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [indexToStart, setIndexToStart] = useState(null);
  const onCloseAfter = () => {
    setIndexToStart(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GEThomeownerDealById(params.userId);
        setDealData(response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [params.userId]);

  if (loading) {
    return <div></div>;
  }

  const deal = dealData?.Deal_details;
  const solarInsights = deal?.User_solar_insight;
  const homeOwnerFullName = `${deal?.User?.first_name} ${deal?.User?.last_name}`;

  const IndoorOutdoorPhotos = ({ surveyData }) => {
    const indoorPhotos = [
      { key: "subPanel", label: "Sub Panel" },
      { key: "subPanel_Label", label: "Sub Panel Label" },
      { key: "subPanel_Location", label: "Sub Panel Location" },
      { key: "attic", label: "Attic" },
    ];

    const outdoorPhotos = [
      { key: "mainService_panel", label: "Main Service Panel" },
      { key: "mainService_panel_label", label: "Main Service Panel Label" },
      { key: "main_Breaker", label: "Main Breaker" },
      { key: "area_surrounding_panel", label: "Area Surrounding Panel" },
      { key: "utlity_meter", label: "Utility Meter" },
      { key: "meter_location", label: "Meter Location" },
      { key: "roof_location", label: "Roof Location" },
    ];

    const renderImageBlock = (key, label, src, index) => {
      return src ? (
        <div className="relative group w-[200px] h-[200px]" key={key}>
          <img
            loading="lazy"
            className="w-full h-full rounded"
            src={src}
            alt={label}
          />
          <div className="absolute inset-0 z-10 flex items-start justify-end p-2 md:opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <p className="text-white bg-black bg-opacity-60 rounded p-1 text-sm">
              {label}
            </p>
            <img
              loading="lazy"
              onClick={() => setIndexToStart(index)}
              src="/icons/full-screen.svg"
              className="w-4 h-4 ml-2 cursor-pointer"
              alt="icon"
            />
          </div>
        </div>
      ) : (
        <div
          className="w-[200px] h-[200px] bg-gray-200 flex flex-col items-center justify-center rounded"
          key={key}
        >
          <img loading="lazy" src="/icons/noimage.svg" alt={label} />
          <p className="text-gray-500 text-center">{label}</p>
        </div>
      );
    };

    let validIndex = -1;

    return (
      <div className="flex flex-col gap-y-5">
        <h1 className="text-[#112532] font-semibold">Indoor Photos</h1>
        <div className="flex gap-4">
          {indoorPhotos.map(({ key, label }) => {
            const src = surveyData?.[key];
            if (src) validIndex++;
            return renderImageBlock(key, label, src, validIndex);
          })}
        </div>

        <h1 className="text-[#112532] font-semibold">Outdoor Photos</h1>
        <div className="flex gap-4 flex-wrap">
          {outdoorPhotos.map(({ key, label }) => {
            const src = surveyData?.[key];
            if (src) validIndex++;
            return renderImageBlock(key, label, src, validIndex);
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col p-4 gap-4">
      <BreadCrumb
        paths={[
          { name: "All homeowners", pathLink: "/admin-dashboard/homeowners" },
          {
            name: "Deal Details",
            pathLink: `/admin-dashboard/homeowners/${params.userId}`,
          },
        ]}
      />
      <div className="px-4 lg:px-8 py-6 flex flex-col gap-4 rounded-lg shadow border border-[#d9d3cf] bg-white">
        <div className="flex items-center gap-4">
          <img
            loading="lazy"
            className="w-16 h-16 rounded-full"
            src={deal?.User?.profile_img || "/images/default-profile.svg"}
            alt=""
          />
          <h1 className="text-[#112532] text-2xl lg:text-[33px] font-semibold">
            {homeOwnerFullName}
          </h1>
        </div>
        <div className="flex justify-between gap-4">
          <p className="text-[#112532] font-bold">
            <span className="text-[#244255] font-normal">Deal ID: </span>
            {deal?.id} / {deal?.homeOwner_contract_signed ? "Contract" : "Lead"}
          </p>
          <div
            className={
              deal?.status === 2
                ? "text-red-600 text-[13px] font-bold p-1 bg-red-100"
                : "text-emerald-900 text-[13px] font-bold p-1 bg-emerald-50"
            }
          >
            {dealStatus(deal?.status)}
          </div>
        </div>
        <hr />
        <div className="space-y-2">
          <p className="flex gap-2 py-2 text-[#265D78] underline">
            <img loading="lazy" src="/icons/Email.svg" alt="mail icon" />
            {deal?.User?.email}
          </p>
          <p className="flex gap-2 py-2">
            <img loading="lazy" src="/icons/Call.svg" alt="phone icon" />
            {deal?.User?.mobile}
          </p>
          <p className="flex gap-2 py-2">
            <img loading="lazy" src="/icons/Location.svg" alt="location icon" />
            {deal?.User?.location}
          </p>
        </div>
      </div>
      <h1 className="text-[#112532] text-[23px] font-semibold">Deal Details</h1>
      <div className="px-4 lg:px-8 py-6 flex flex-col gap-4 rounded-lg shadow border border-[#d9d3cf] bg-white">
        <h2 className="text-[#112532] text-[19px] font-semibold">
          Installer Details
        </h2>
        <div className="flex items-center gap-x-2">
          <img
            loading="lazy"
            className="w-16 h-16 rounded-full"
            src={deal?.Installer?.logo || "/images/defaultLogo.svg"}
            alt=""
          />
          <div className="flex flex-col items-start gap-y-3">
            <h1 className="text-black text-lg font-semibold">
              {deal?.Installer?.business_name}
            </h1>
            <div className="flex items-center gap-x-1">
              <img
                loading="lazy"
                src="/icons/star.svg"
                alt="star icon"
                className="w-4"
              />
              <h1 className="text-[#112532] text-sm font-bold">
                {dealData?.rating_Data?.averageRating}
                <span className="text-base font-semibold">/</span>
                <span className="text-xs font-normal">5</span>
              </h1>
            </div>
          </div>
        </div>
        <h2 className="text-[#112532] text-[19px] font-semibold">
          System Tier
        </h2>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-x-2">
            <img
              loading="lazy"
              className="self-start"
              src="/icons/Stars.svg"
              alt=""
            />
            <div className="">
              <h1 className="bg-clip-text text-transparent bg-custom-gradient text-[19px] font-semibold">
                {deal?.system_type}
              </h1>
              <div className="flex flex-col gap-y-2">
                <p className="text-[#64748B]">
                  Opt for maximum efficiency and long-term savings
                </p>
                <p className="flex flex-col sm:flex-row gap-3">
                  <span className="flex items-center">
                    <img
                      loading="lazy"
                      src="/icons/step3.png"
                      alt=""
                      width={"24px"}
                    />{" "}
                    REC panels or similar
                  </span>
                  <span className="flex items-center">
                    <img loading="lazy" src="/icons/shield.svg" alt="" /> 25
                    years warranty
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 lg:px-8 py-6 flex flex-col gap-y-5 rounded-lg shadow border border-[#d9d3cf] bg-white">
        <div className="flex justify-between gap-x-5 items-center">
          <div className="">
            {" "}
            <div className="px-4 py-4">
              <div className="flex items-center gap-2 mb-2">
                <img
                  loading="lazy"
                  src="/icons/step3.png"
                  alt="step3"
                  width={30}
                />
                <span className="text-[#112532] text-lg font-semibold">
                  Solar design
                </span>
              </div>
              <div className="flex lg:grid lg:grid-cols-3 gap-4 overflow-x-auto pb-4 lg:overflow-visible">
                <div className="md:border-0 border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    {deal?.User_solar_insight?.userRequestedPanels}
                  </h1>
                  <p className="text-[#112532] text-sm font-normal">
                    solar panels
                  </p>
                </div>
                <div className="md:border-0  border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    {solarInsights?.system_size} <sub>kW</sub>
                  </h1>
                  <p className="text-[#112532] text-sm font-normal">
                    System size
                  </p>
                </div>
                <div className="md:border-0  border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    {formatPrice(solarInsights?.Estimated_Annual_production)}
                    <sub>kWh</sub>
                  </h1>
                  <p className="text-[#112532] text-sm font-normal">
                    Estimate annual production
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="px-4 py-2">
              <div className="flex items-center gap-2 mb-2">
                <img
                  loading="lazy"
                  src="/icons/bill.png"
                  alt="step3"
                  width={30}
                />
                <span className="text-[#112532] text-lg font-semibold">
                  Bills Coverage
                </span>
              </div>
              <div className="flex lg:grid lg:grid-cols-3 gap-4 overflow-x-auto pb-4 lg:overflow-visible">
                <div className="md:border-0  border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    {formatPrice(solarInsights?.estimated_offset * 100)}%
                  </h1>
                  <p className="text-[#112532] text-sm font-normal">
                    Estimated offset
                  </p>
                </div>
                <div className="md:border-0  border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    ${formatPrice(deal?.User?.avg_bill)}
                  </h1>
                  <p className="text-[#112532] text-sm font-normal">Old bill</p>
                </div>
                <div className="md:border-0  border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    ${formatPrice(solarInsights?.left_over_elec_bill)}
                  </h1>
                  <p className="text-[#112532] text-sm font-normal">
                    Leftover electricity bill amount
                  </p>
                </div>
              </div>
            </div>
            <h1 className="py-2 text-[#112532] text-base font-semibold">
              System Cost
            </h1>
            <div className="px-6 py-4 bg-[#fef9ec] text-[#112532] text-[19px] font-semibold">
              ${formatPrice(solarInsights?.finalPrice)}
            </div>
          </div>

          <div className="w-1/2 h-[400px] overflow-hidden rounded">
            {" "}
            {solarInsights?.panels_data && (
              <PanelMap
                buildingInsights={JSON.parse(solarInsights?.panels_data)}
                staticPanelsNumber={solarInsights?.userRequestedPanels}
              />
            )}
          </div>
        </div>
      </div>
      {/* Site Survey Section */}
      <div className="site-survey-section flex flex-col gap-y-5 px-4 lg:px-8 py-6 rounded-lg shadow border border-[#d9d3cf] bg-white">
        <div className="flex justify-between">
          <h1 className="text-[19px] text-[#112532] font-semibold">
            Site Survey
          </h1>
          {dealData?.Survey_Details?.survey && (
            <GalleryCarousel
              onCloseAfter={onCloseAfter}
              indexToStart={indexToStart}
              imageData={dealData?.Survey_Details?.survey || []}
              URLsFields={[
                { key: "subPanel", label: "Sub Panel" },
                { key: "subPanel_Label", label: "Sub Panel Label" },
                { key: "subPanel_Location", label: "Sub Panel Location" },
                { key: "attic", label: "Attic" },
                { key: "mainService_panel", label: "Main Service Panel" },
                {
                  key: "mainService_panel_label",
                  label: "Main Service Panel Label",
                },
                { key: "main_Breaker", label: "Main Breaker" },
                {
                  key: "area_surrounding_panel",
                  label: "Area Surrounding Panel",
                },
                { key: "utlity_meter", label: "Utility Meter" },
                { key: "meter_location", label: "Meter Location" },
                { key: "roof_location", label: "Roof Location" },
              ]}
            />
          )}
        </div>
        {dealData?.Survey_Details?.survey ? (
          <IndoorOutdoorPhotos surveyData={dealData?.Survey_Details?.survey} />
        ) : (
          <div className="flex flex-col items-center gap-y-5">
            <img
              loading="lazy"
              className="w-14 h-14"
              src="/icons/material-symbols_photo-library.svg"
              alt=""
            />
            <p className="text-center">
              No photos of the site yet. Want to schedule an appointment with{" "}
              {homeOwnerFullName}
            </p>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-y-4 px-4 lg:px-8 py-6 rounded-lg shadow border border-[#d9d3cf] bg-white">
        <div className="flex flex-col gap-y-6">
          <h1 className="text-[19px] text-[#112532] font-semibold">
            Additional Information
          </h1>
          <div className="flex flex-col gap-y-3">
            <p className="text-[#475569]">Roof Type</p>
            <div className="flex items-center">
              <img
                loading="lazy"
                src={"/images/metal.png"}
                alt="metal"
                style={{
                  marginLeft: "10px",
                  width: "40px",
                  height: "40px",
                }}
                className="me-4"
              />
              <p className="text-[#475569] font-semibold">
                {dealData?.Deal_details?.User?.roof_type}
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-6">
            <div className="flex flex-col gap-y-3 w-full sm:w-1/3">
              <p className="text-[#475569]">Roof Condition</p>
              <p className="font-semibold">
                {dealData?.Deal_details?.User?.roof_condition}
              </p>
            </div>
            <div className="flex flex-col gap-y-3 w-full sm:w-1/3">
              <p className="text-[#475569]">Estimated Credit Score</p>
              <p className="font-semibold">
                {dealData?.Deal_details?.User?.credit_score}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeownerDeal;
