import React from "react";
import { Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: "500",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderWidth: "8px 0px",
  border: "8px solid white",
}));

const TableSkeleton = ({ columns = 8, rows = 10 }) => {
  // Array of different widths for skeleton cells to create visual variety
  const skeletonWidths = [40, 120, 80, 80, 80, 200, 80, 80];

  return (
    <TableContainer component={"div"} sx={{ paddingBottom: "20px" }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {[...Array(columns)].map((_, index) => (
              <StyledTableCell key={index}>
                <Skeleton
                  width={skeletonWidths[index % skeletonWidths.length]}
                />
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(rows)].map((_, rowIndex) => (
            <StyledTableRow
              key={rowIndex}
              sx={{
                bgcolor: "white",
                border: "0px solid #F9F8F8",
                borderWidth: "4px 2px",
              }}
            >
              {[...Array(columns)].map((_, colIndex) => (
                <StyledTableCell
                  key={colIndex}
                  sx={colIndex === columns - 1 ? { bgcolor: "#FCEEC9" } : {}}
                >
                  <Skeleton
                    width={skeletonWidths[colIndex % skeletonWidths.length]}
                  />
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      {/* Pagination Skeleton */}
      <div className="flex justify-center mt-3 items-center">
        <Skeleton variant="circular" width={24} height={24} className="mr-2" />
        <div className="flex justify-between gap-x-3">
          {[...Array(5)].map((_, i) => (
            <Skeleton
              key={i}
              variant="rectangular"
              width={30}
              height={30}
              className="rounded"
            />
          ))}
        </div>
        <Skeleton variant="circular" width={24} height={24} className="ml-2" />
      </div>
    </TableContainer>
  );
};

export default TableSkeleton;
