import { InputAdornment, TextField } from "@mui/material";
import useResponsive from "hooks/useResponsive";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthenticatedHeader from "../../components/shared/AuthenticatedHeader";
import Chat from "../../components/shared/Chat";
import MessageBar from "../../components/shared/MessageBar";

const Messages = () => {
  const [search, setSearch] = useState("");
  const [chatInfo, setChatInfo] = useState(null);
  const userRole = localStorage.getItem("USER");
  const { isSm } = useResponsive();
  const navigate = useNavigate();
  const chatList = useSelector((state) => state.chatList);

  const handleBackToList = () => {
    setChatInfo(null);
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="hidden md:block">
        <AuthenticatedHeader chatListSearchQuery={search} />
      </div>
      {/* {isSm && <AuthenticatedHeader />} */}
      <div className="flex flex-grow overflow-hidden">
        <div
          className={`${
            isSm && chatInfo ? "hidden" : "w-full md:w-1/4"
          } flex flex-col gap-y-6 md:border border-[#D9D3CF] py-5 rounded-t-[10px] mx-[20px] md:ml-[20px] md:mr-0`}
        >
          <h1 className="flex items-center gap-x-2 text-[23px] font-bold md:px-5">
            <img
              className=" cursor-pointer"
              src="/icons/arrow_back.svg"
              alt=""
              onClick={() =>
                navigate(
                  userRole === "installer"
                    ? "/dashboard-installer"
                    : userRole === "homeOwner"
                      ? "/dashboard"
                      : null
                )
              }
            />{" "}
            My Messages
          </h1>
          <div className="w-full flex flex-col gap-y-6 md:px-5">
            <TextField
              onChange={(e) => setSearch(e.target.value)}
              fullWidth
              size="small"
              InputLabelProps={{
                sx: { fontSize: 12 },
              }}
              InputProps={{
                sx: { fontSize: 12 },
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      loading="lazy"
                      src="/icons/search.svg"
                      alt="search icon"
                    />
                  </InputAdornment>
                ),
              }}
              label="Search for a name"
              variant="outlined"
            />
          </div>
          <div className="flex flex-col gap-y-6 overflow-y-auto flex-grow">
            <h1 className="md:pl-4">Newest</h1>
            {chatList?.updatedChatList?.map((message, index) => (
              <MessageBar
                key={index}
                message={message}
                setChatInfo={setChatInfo}
              />
            ))}
          </div>
        </div>

        <div
          className={`${
            isSm && !chatInfo ? "hidden" : "w-full md:flex-1 md:max-w-[75%]"
          } md:px-6 pb-2`}
        >
          {chatInfo ? (
            <Chat
              chatInfo={chatInfo}
              setChatInfo={setChatInfo}
              onBack={isSm ? handleBackToList : undefined}
            />
          ) : (
            <div className="h-full flex flex-col justify-center items-center gap-y-4 border border-[#D9D3CF] p-5 rounded-[10px] shadow">
              <img
                loading="lazy"
                className="w-[200px] h-[200px]"
                src="/images/noChat.png"
                alt=""
              />
              <h1 className="text-[#244255] text-xl font-semibold">
                Start Chatting Now!
              </h1>
              <p className="text-sm text-[#112532]">Select chat to view</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messages;
