import { useState } from "react";
import { getBadgeIcon } from "utils/constant";
import { formatPrice } from "utils/formatNumber";

const Offer = ({ offer, onSubmit, setValue, isLoading }) => {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <div className="flex flex-col gap-y-4 p-4 md:p-8 bg-white rounded-md border border-[#c7cace] min-w-[350px]">
      <div className="flex justify-between">
        <h1 className="text-[23px] font-semibold">
          {offer?.businessOperation}
        </h1>
        <div className="flex items-center gap-x-1">
          <img src={getBadgeIcon(offer?.className)} alt={offer?.className} />
          <span className="text-[13px] font-medium capitalize">
            {offer?.className || "No Badge"}
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-y-3 md:gap-y-4">
        <div className="flex items-center gap-x-3">
          <h1 className="flex gap-x-2 items-center text-[#112532] text-[23px] font-bold">
            {offer?.stars ? (
              <>
                {offer.stars}/5
                <img src="/icons/star.svg" alt="rating" />
              </>
            ) : (
              <>
                <span>Not Rated</span>
                <img src="/icons//off-star.svg" alt="not rated" />
              </>
            )}
          </h1>

          <p className="text-[#287192] text-sm font-semibold underline">
            Installer Reviews
          </p>
        </div>

        <div className="flex gap-x-2 flex-wrap min-h-[30px]">
          {offer?.tags.map((tag, index) => (
            <div
              key={index}
              className="px-2 py-1 bg-[#f0fafb] rounded border border-[#d8f0f5] text-[13px] font-semibold"
            >
              {tag}
            </div>
          ))}
        </div>

        <div className="text-sm">
          {/* <p className="flex gap-x-2 items-center py-1">
            <img loading="lazy" src="/icons/location_on.svg" alt="location" />
            {address}
          </p> */}
          <p className="flex gap-x-2 items-center">
            <img loading="lazy" src="/icons/home_work.svg" alt="coverage" />
            Covers {offer?.state} area
          </p>
        </div>

        <p className="text-[#112532] text-[32px] font-bold">
          ${formatPrice(offer?.systemPrice)}
        </p>
        {/* <p className="text-[#244255] text-xs font-semibold">
          ${totalSavings.toLocaleString()} Total Savings (over {savingsYears}{" "}
          years)
        </p> */}
        <p className="text-[#244255] text-xs font-semibold">
          {offer?.description}
        </p>
      </div>

      <button
        onClick={() => {
          setIsSelected(true);
          setValue("offer_id", offer?.offerId, { shouldValidate: true });
          onSubmit();
        }}
        disabled={isLoading}
        className={`text-center px-[18px] py-3 rounded-[5px] text-[#fffefb] font-bold 
          ${isSelected ? "bg-[#D0680F]" : "bg-[#112532] hover:bg-[#1a3850] disabled:opacity-50"}`}
      >
        {isSelected ? "Selected Offer" : "Lock in This Deal"}
      </button>
    </div>
  );
};

export default Offer;
