import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Box,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Pagination,
  Select,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExcelExport from "components/installer/ExcelExport";
import ExportStyledPDF from "components/installer/ExportStyledPDF";
import { IosSwitch } from "components/MUI/IosSwitch";
import TableSkeleton from "components/skeleton/shared/TableSkeleton";
import { useDebounce } from "hooks/useDebounce";
import useResponsive from "hooks/useResponsive";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  POSTallInstallersData,
  PUTupdateInstallerData,
} from "services/AdminServices";
import { classOptions, customDateValidate } from "utils/constant";
import { handleKeyDown } from "utils/formHelpers";

const StyledTableCell = styled(TableCell)(
  ({ theme, "data-frozen": frozen, index }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      color: "black",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: "500",
    },
    ...(frozen && {
      position: "sticky",
      left: index === 0 ? "-2px" : "100.08px",
      zIndex: 2,
      backgroundColor: "white",
      filter: "drop-shadow(8px 0 6px rgba(203, 197, 180, 0.21))",
      borderRight: "1px solid rgba(203, 197, 180, 0.15)",
    }),
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderWidth: "8px 0px",
  border: "8px solid white",
}));

const TableWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  overflowX: "auto",
  [theme.breakpoints.down("sm")]: {
    "& table": {
      minWidth: "100%",
    },
    "& th, & td": {
      width: { sm: "75px", md: "150px" },
    },
    "& th:first-of-type, & td:first-of-type, & th:nth-of-type(2), & td:nth-of-type(2)":
      {
        position: "sticky",
        backgroundColor: "white",
        // zIndex: 1,
      },
  },
}));

const columns = [
  {
    header: "ID",
    key: "id",
    width: 10,
  },
  {
    header: "Name",
    key: "first_name",
    width: 20,
    transform: (value, row) => `${value} ${row.last_name}`,
  },
  {
    header: "State",
    key: "state",
    width: 15,
  },
  {
    header: "Business Operation",
    key: "business_operation",
    width: 20,
  },
  {
    header: "Class",
    key: "className",
    width: 15,
  },
  {
    header: "Spark Fees",
    key: "spark_fees",
    width: 15,
    format: "currency",
  },
  {
    header: "Activation",
    key: "activation",
    width: 15,
    transform: (value) => (value ? "Active" : "Inactive"),
  },
  {
    header: "Created Date",
    key: "createdAt",
    width: 15,
    format: "date",
  },
];

const filterLabels = {
  className: {
    unclassified: "Unclassified",
    silver: "Silver",
    gold: "Gold",
    platinum: "Platinum",
  },
  business_operation: {
    local: "Local",
    regional: "Regional",
    national: "National",
  },
  status: {
    active: "Active",
    inactive: "Inactive",
  },
};

const AllInstallersTable = () => {
  const [filters, setFilters] = useState({
    used: false,
    className: [],
    business_operation: [],
    status: [],
    minFee: "",
    maxFee: "",
    search: "",
  });
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [sortedRows, setSortedRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allInstallersTableData, setAllInstallersTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerEffect, setTriggerEffect] = useState(0);

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const allInstallersTableData = await POSTallInstallersData(filters);
      setAllInstallersTableData(allInstallersTableData);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("installersFilters");
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      setFilters({ ...parsedFilters, search: "" });
      setAppliedFilters(parsedFilters);
    }
  }, []);

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("installersFilters");
    if (appliedFilters.used || filters.search || !savedFilters) {
      fetchData();
    }
  }, [appliedFilters, filters.search, triggerEffect]);

  const searchDebounce = useDebounce(searchData, 1500);

  const handleSearchChange = (e) => {
    searchDebounce(e);
  };

  function searchData(e) {
    sessionStorage.removeItem("allInstallersPageNumber");
    updateFilters({
      search: e.target.value === "" ? undefined : e.target.value,
    });
  }

  const updateFilters = (newFilterValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilterValues,
      used: true,
    }));
  };

  const handleCheckboxChange = (e, filterKey, value) => {
    const { checked } = e.target;
    updateFilters({
      [filterKey]: checked
        ? [...filters[filterKey], value]
        : filters[filterKey].filter((item) => item !== value),
    });
  };

  const getActiveFilters = () => {
    const activeFilters = [];

    if (appliedFilters.className.length > 0) {
      appliedFilters.className.forEach((type) => {
        activeFilters.push(filterLabels.className[type] || type);
      });
    }

    if (appliedFilters.business_operation.length > 0) {
      appliedFilters.business_operation.forEach((operation) => {
        activeFilters.push(
          filterLabels.business_operation[operation] || operation
        );
      });
    }

    if (appliedFilters.status.length > 0) {
      appliedFilters.status.forEach((status) => {
        activeFilters.push(filterLabels.status[status] || status);
      });
    }

    if (appliedFilters.minFee && appliedFilters.maxFee) {
      activeFilters.push(
        `$${appliedFilters.minFee} - $${appliedFilters.maxFee}`
      );
    }

    return activeFilters;
  };

  const removeFilter = (filterToRemove) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters.className = updatedFilters.className.filter(
        (item) => filterLabels.className[item] !== filterToRemove
      );
      updatedFilters.business_operation =
        updatedFilters.business_operation.filter(
          (item) => filterLabels.business_operation[item] !== filterToRemove
        );
      updatedFilters.status = updatedFilters.status.filter(
        (item) => filterLabels.status[item] !== filterToRemove
      );
      if (filterToRemove.includes("$")) {
        updatedFilters.minFee = "";
        updatedFilters.maxFee = "";
      }
      sessionStorage.setItem(
        "installersFilters",
        JSON.stringify(updatedFilters)
      );
      return updatedFilters;
    });
    setAppliedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters.className = updatedFilters.className.filter(
        (item) => filterLabels.className[item] !== filterToRemove
      );
      updatedFilters.business_operation =
        updatedFilters.business_operation.filter(
          (item) => filterLabels.business_operation[item] !== filterToRemove
        );
      updatedFilters.status = updatedFilters.status.filter(
        (item) => filterLabels.status[item] !== filterToRemove
      );
      if (filterToRemove.includes("$")) {
        updatedFilters.minFee = "";
        updatedFilters.maxFee = "";
      }
      sessionStorage.setItem(
        "installersFilters",
        JSON.stringify(updatedFilters)
      );
      return updatedFilters;
    });
  };

  const clearAllFilters = () => {
    const emptyFilters = {
      used: false,
      className: [],
      business_operation: [],
      status: [],
      minFee: "",
      maxFee: "",
      search: "",
    };
    setFilters(emptyFilters);
    setAppliedFilters(emptyFilters);
    sessionStorage.removeItem("installersFilters");
    sessionStorage.removeItem("allInstallersPageNumber");
  };

  const list = () => (
    <Box
      sx={{
        width: { xs: "100vw", sm: "400px", md: "480px" },
      }}
      role="presentation"
    >
      <div className="h-full p-4 md:p-10 flex flex-col gap-y-5 pb-[120px] md:pb-10">
        <div className="sticky top-0 bg-white z-10 py-3 border-b border-[#C7CACE]">
          <div className="flex justify-between items-center">
            <h1 className="text-[23px] text-[#112532] font-semibold py-2 md:py-4">
              Apply Filters
            </h1>
            <CloseIcon
              className="cursor-pointer md:hidden"
              onClick={toggleDrawer("right", false)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-6 h-full">
          <FormGroup>
            <h2 className="text-[#272a2e] text-lg font-semibold">
              Class Filter
            </h2>
            {Object.entries(filterLabels.className).map(([value, label]) => (
              <FormControlLabel
                key={value}
                control={
                  <Checkbox
                    checked={filters.className.includes(value)}
                    onChange={(e) =>
                      handleCheckboxChange(e, "className", value)
                    }
                  />
                }
                label={label}
              />
            ))}
          </FormGroup>

          <FormGroup>
            <h2 className="text-[#272a2e] text-lg font-semibold">
              Business Operation
            </h2>
            {Object.entries(filterLabels.business_operation).map(
              ([value, label]) => (
                <FormControlLabel
                  key={value}
                  control={
                    <Checkbox
                      checked={filters.business_operation.includes(value)}
                      onChange={(e) =>
                        handleCheckboxChange(e, "business_operation", value)
                      }
                    />
                  }
                  label={label}
                />
              )
            )}
          </FormGroup>

          <FormGroup>
            <h2 className="text-[#272a2e] text-lg font-semibold">Status</h2>
            {Object.entries(filterLabels.status).map(([value, label]) => (
              <FormControlLabel
                key={value}
                control={
                  <Checkbox
                    checked={filters.status.includes(value)}
                    onChange={(e) => handleCheckboxChange(e, "status", value)}
                  />
                }
                label={label}
              />
            ))}
          </FormGroup>

          <div className="flex gap-x-3 items-center">
            <TextField
              fullWidth
              label="Min Price"
              variant="outlined"
              value={filters.minFee}
              onChange={(e) => updateFilters({ minFee: e.target.value })}
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
              }}
            />
            -
            <TextField
              fullWidth
              label="Max Price"
              variant="outlined"
              value={filters.maxFee}
              onChange={(e) => updateFilters({ maxFee: e.target.value })}
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
              }}
            />
          </div>

          <div className="fixed md:relative bottom-0 left-0 right-0 bg-white border-t border-gray-200 md:border-0 ">
            <div className="flex justify-center md:justify-end gap-3 p-6 md:p-0 md:mt-4">
              <button
                onClick={(event) => {
                  toggleDrawer("right", false)(event);
                  clearAllFilters();
                }}
                className="py-3 px-10 border border-[#112532] text-[#112532] font-medium rounded text-sm md:text-base w-full lg:w-auto"
              >
                Cancel
              </button>
              <button
                onClick={(event) => {
                  setAppliedFilters(filters);
                  sessionStorage.removeItem("allInstallersPageNumber");
                  sessionStorage.setItem(
                    "installersFilters",
                    JSON.stringify(filters)
                  );
                  toggleDrawer("right", false)(event);
                }}
                className="py-3 px-10 text-[#fffefb] font-bold bg-[#112532] rounded text-sm md:text-base w-full lg:w-auto "
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <div className="p-6 flex flex-col gap-y-4">
      <div className="flex justify-between">
        <h1 className="text-black text-[28px] font-semibold">All Installers</h1>
        {isLoading ? (
          <div>LOADING...</div>
        ) : (allInstallersTableData?.installers?.length ?? 0) === 0 ? (
          <div className="text-[#767F89] font-bold border-1 border-[#767F89] py-2 px-4 rounded opacity-50 cursor-not-allowed w-[25%] md:w-[9%] text-center">
            Export
          </div>
        ) : (
          <FormControl className="w-[25%] md:w-[6.5%] rounded" size="small">
            <InputLabel
              shrink={false}
              id="export-select-label"
              sx={{
                color: "#112532",
                fontWeight: "bold",
                "&.Mui-focused": {
                  color: "#112532",
                },
              }}
            >
              Export
            </InputLabel>
            <Select
              IconComponent={KeyboardArrowDownOutlinedIcon}
              labelId="export-select-label"
              id="export-select"
              sx={{
                color: "#112532",
                borderColor: "#112532",
                "& .MuiSelect-icon": {
                  color: "#112532",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#112532",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#112532",
                  borderWidth: "1px",
                },
              }}
            >
              <div className="flex flex-col">
                <ExcelExport
                  data={
                    selectedRows.length > 0
                      ? selectedRows
                      : sortedRows.length > 0
                        ? sortedRows
                        : allInstallersTableData?.installers
                  }
                  columns={columns}
                  filename="installers_export"
                  filters={getActiveFilters()}
                />
              </div>
            </Select>
          </FormControl>
        )}
      </div>
      <div className="flex flex-col gap-y-1">
        <div className="flex flex-col gap-y-3 justify-between gap-x-6 mb-3 bg-white border border-[#d9d3cf]">
          <TextField
            sx={{
              backgroundColor: "white",
              "& .MuiOutlinedInput-root": {
                border: "none",
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            fullWidth
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    loading="lazy"
                    src={
                      filters.search
                        ? "/icons//search-active.svg"
                        : "/icons/search.svg"
                    }
                    alt="search icon"
                    className="w-5 md:w-auto"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    loading="lazy"
                    onClick={toggleDrawer("right", true)}
                    className="cursor-pointer w-auto"
                    src={
                      getActiveFilters().length > 0
                        ? "/icons/Filter-active.svg"
                        : "/icons/Filter.svg"
                    }
                    alt="filter icon"
                  />
                </InputAdornment>
              ),
            }}
            placeholder="Search ID, name or location..."
          />

          <Drawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            sx={{
              "& .MuiBackdrop-root": {
                background: "rgba(17, 37, 50, 0.5)",
              },
            }}
            PaperProps={{
              sx: {
                width: { xs: "100%", sm: "400px", md: 500 },
                height: { xs: "85%", md: "100%" },
                bottom: 0,
                top: "auto",
                "& > div:first-of-type": {
                  height: "100%",
                },
              },
            }}
          >
            {list()}
          </Drawer>

          {getActiveFilters().length > 0 && (
            <div className="flex justify-between pb-4 px-2 md:px-4">
              <ul className="flex flex-wrap gap-2 mb-2 md:mb-0">
                {getActiveFilters().map((filter, index) => (
                  <li
                    key={index}
                    className="px-2 md:px-3 py-1 bg-[#FFF8F1] border border-[#FBB667] rounded text-xs md:text-sm flex items-center font-semibold"
                  >
                    {filter}
                    <CloseIcon
                      sx={{ fontSize: { xs: "16px", md: "20px" } }}
                      className="ml-1 md:ml-2 cursor-pointer"
                      onClick={() => removeFilter(filter)}
                    />
                  </li>
                ))}
              </ul>
              <button
                className="min-w-[100px] flex items-center justify-end gap-x-1 rounded text-[#244255] font-semibold text-sm md:text-base mt-2 md:mt-0"
                onClick={clearAllFilters}
              >
                <CloseIcon sx={{ fontSize: { xs: "16px", md: "20px" } }} />
                Clear All
              </button>
            </div>
          )}
        </div>

        {isLoading ? (
          <TableSkeleton />
        ) : (
          <CustomizedTables
            recordsArray={allInstallersTableData?.installers}
            setSortedRows={setSortedRows}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            setTriggerEffect={setTriggerEffect}
          />
        )}
      </div>
    </div>
  );
};

export default AllInstallersTable;

const CustomizedTables = ({
  recordsArray,
  setSelectedRows,
  selectedRows,
  setSortedRows,
  setTriggerEffect,
}) => {
  const { isSm } = useResponsive();

  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("allInstallersPageNumber");
    return savedPage ? parseInt(savedPage) : 0;
  });

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [iosSwitch, setIosSwitch] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [isEditingFees, setIsEditingFees] = useState({});
  const [sparkFeesFieldValue, setSparkFeesFieldValue] = useState("");

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(sortedRows.slice(page * 10, page * 10 + 10));
    } else {
      setSelectedRows([]);
    }
  };

  const updateInstallerById = async (id, data) => {
    try {
      const res = await PUTupdateInstallerData(id, data);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      if (!!data.spark_fees) setTriggerEffect((prev) => prev + 1);
    }
  };

  const handleRequestSort = (event, property) => {
    let newOrder;
    if (orderBy !== property) {
      newOrder = "asc";
      setOrder("asc");
      setOrderBy(property);
    } else {
      if (order === "asc") {
        newOrder = "desc";
        setOrder("desc");
      } else if (order === "desc") {
        newOrder = null;
        setOrder(null);
      } else {
        newOrder = "asc";
        setOrder("asc");
      }
    }

    // Sort the entire array
    const newSortedRows = recordsArray
      .slice()
      .sort(getComparator(newOrder, property));

    // Set the entire sorted array as sorted rows
    setSortedRows(newSortedRows);
  };

  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
      );
    }
  };

  const descendingComparator = (a, b, orderBy) => {
    const valueA = orderBy.includes(".")
      ? orderBy.split(".").reduce((o, i) => o[i], a)
      : a[orderBy];
    const valueB = orderBy.includes(".")
      ? orderBy.split(".").reduce((o, i) => o[i], b)
      : b[orderBy];

    if (valueB < valueA) return -1;
    if (valueB > valueA) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    if (order === null) {
      return (a, b) => 0;
    }

    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const rows = recordsArray;
  const emptyRows = Math.max(0, (page + 1) * 10 - rows?.length);
  const sortedRows = rows?.slice().sort(getComparator(order, orderBy));

  useEffect(() => {
    sessionStorage.setItem("allInstallersPageNumber", page.toString());
  }, [page]);

  return (
    <TableWrapper>
      <TableContainer component={"div"} sx={{ paddingBottom: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                data-frozen={isSm || undefined}
                index={0}
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
                align="left"
              >
                <div className="flex">
                  <Checkbox
                    size="small"
                    checked={
                      sortedRows?.length > 0 &&
                      selectedRows?.length ===
                        sortedRows?.slice(page * 10, page * 10 + 10)?.length
                    }
                    indeterminate={
                      selectedRows?.length > 0 &&
                      selectedRows?.length <
                        sortedRows?.slice(page * 10, page * 10 + 10)?.length
                    }
                    sx={{
                      visibility:
                        selectedRows?.length > 0 ? "visible" : "hidden",
                      "&:hover": {
                        visibility: "visible",
                      },
                      ".MuiTableRow-root:hover &": {
                        visibility: "visible",
                      },
                    }}
                    onChange={handleSelectAllClick}
                  />

                  <TableSortLabel
                    active={orderBy === "id"}
                    direction={order !== null ? order : undefined}
                    onClick={(event) => handleRequestSort(event, "id")}
                    sx={{
                      "&:hover .MuiTableSortLabel-icon": {
                        visibility: "visible !important",
                      },
                      ".MuiTableSortLabel-icon": {
                        visibility: order !== null ? "visible" : "hidden",
                      },
                    }}
                  >
                    ID
                  </TableSortLabel>
                </div>
              </StyledTableCell>
              <StyledTableCell
                data-frozen={isSm || undefined}
                index={1}
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "business_name"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "business_name")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "state"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "state")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  State
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "business_operation"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) =>
                    handleRequestSort(event, "business_operation")
                  }
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Business Operation
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "className"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "className")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Class
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "spark_fees"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "spark_fees")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Spark Fees ($/Watt)
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  width: "150px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                <TableSortLabel
                  active={orderBy === "activation"}
                  direction={order !== null ? order : undefined}
                  onClick={(event) => handleRequestSort(event, "activation")}
                  sx={{
                    "&:hover .MuiTableSortLabel-icon": {
                      visibility: "visible !important",
                    },
                    ".MuiTableSortLabel-icon": {
                      visibility: order !== null ? "visible" : "hidden",
                    },
                  }}
                >
                  Activation
                </TableSortLabel>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows?.slice(page * 10, page * 10 + 10).map((row) => (
              <StyledTableRow
                key={row.id}
                sx={{
                  bgcolor: "white",
                  border: "0px solid #F9F8F8",
                  borderWidth: "4px 2px",
                }}
              >
                <StyledTableCell
                  data-frozen={isSm || undefined}
                  index={0}
                  component="th"
                  scope="row"
                >
                  <div className="flex items-center">
                    <Checkbox
                      size="small"
                      checked={selectedRows.some(
                        (selectedRow) => selectedRow.id === row.id
                      )}
                      sx={{
                        visibility: selectedRows.some(
                          (selectedRow) => selectedRow.id === row.id
                        )
                          ? "visible"
                          : "hidden",
                        "&:hover": {
                          visibility: "visible",
                        },
                        ".MuiTableRow-root:hover &": {
                          visibility: "visible",
                        },
                      }}
                      onChange={(event) => handleSelectRow(event, row)}
                    />
                    {row.id}
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  data-frozen={isSm || undefined}
                  index={1}
                  align="left"
                  sx={{ color: "#D0680F" }}
                >
                  <Link to={`${row.id}`}>{row.business_name}</Link>
                </StyledTableCell>
                <StyledTableCell align="left">{row.state}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.business_operation}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <FormControl sx={{ width: "100%" }}>
                    {!(selectedItems[row.id] || row.className) && (
                      <InputLabel id="demo-simple-select-helper-label">
                        <div className="flex items-center gap-x-2">
                          <img
                            className="h-7 w-7"
                            src="/icons/none-class.svg"
                            alt=""
                          />
                          Select
                        </div>
                      </InputLabel>
                    )}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={selectedItems[row.id] || row.className || ""}
                      onChange={(e) => {
                        setSelectedItems((prev) => ({
                          ...prev,
                          [row.id]: e.target.value,
                        }));
                        updateInstallerById(row.id, {
                          className: e.target.value,
                        });
                      }}
                      renderValue={(selected) => {
                        const selectedItems = classOptions.find(
                          (item) => item.value === selected
                        );

                        return (
                          <div className="flex items-center gap-x-2">
                            {selectedItems?.icon}
                            <span className="text-[13px] font-medium ">
                              {selectedItems?.label}
                            </span>
                          </div>
                        );
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      {classOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <ListItemIcon>{option.icon}</ListItemIcon>
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledTableCell>
                <StyledTableCell align="center" className="group">
                  {!isEditingFees[row.id] ? (
                    <div
                      className="text-[#b3b8bd] group-hover:text-[#868788] flex justify-between items-center cursor-pointer p-2"
                      onClick={() =>
                        setIsEditingFees((prev) => ({
                          ...prev,
                          [row.id]: true,
                        }))
                      }
                    >
                      <p
                        className={row.spark_fees && "text-black font-semibold"}
                      >
                        {row.spark_fees || "Enter fees"}
                      </p>
                      <img
                        src="/Admin-assets/icons/edit-installer.svg"
                        alt=""
                        className=" w-6"
                      />
                    </div>
                  ) : (
                    <div className="flex gap-2 items-center">
                      <TextField
                        disabled={!isEditingFees[row.id]}
                        size="small"
                        autoFocus
                        variant="outlined"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          setSparkFeesFieldValue(e.target.value);
                        }}
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                          "& .MuiFormHelperText-root": {
                            backgroundColor: "white",
                            padding: "3px 14px 0px",
                            margin: 0,
                          },
                        }}
                      />
                      <div className="cursor-pointer hover:bg-[#FFF8F1] rounded">
                        {isEditingFees[row.id] && (
                          <img
                            src="/Admin-assets/icons/check-icon.svg"
                            className="w-8"
                            onClick={() => {
                              updateInstallerById(row.id, {
                                spark_fees: sparkFeesFieldValue,
                              });

                              setIsEditingFees((prev) => ({
                                ...prev,
                                [row.id]: false,
                              }));
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormControlLabel
                    control={
                      <IosSwitch
                        sx={{ m: 1 }}
                        checked={
                          iosSwitch[row.id] !== undefined
                            ? iosSwitch[row.id]
                            : row.activation
                        }
                        onChange={(e) => {
                          setIosSwitch((prev) => ({
                            ...prev,
                            [row.id]: e.target.checked,
                          }));
                          updateInstallerById(row.id, {
                            activation: e.target.checked.toString(),
                          });
                        }}
                      />
                    }
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="flex justify-center mt-3">
          <Pagination
            size="large"
            sx={{
              "& .MuiPaginationItem-root": {
                height: "25px", // Adjusted height
                fontSize: "0.75rem", // Optional: Adjust font size for proportion
                minWidth: "auto", // Ensures no default min width
                padding: "4px 8px",
                "&.Mui-selected": {
                  backgroundColor: "#112532", // Your desired color
                  color: "white", // Ensure contrast
                },
              },
            }}
            count={Math.ceil(rows?.length / 10)}
            shape="rounded"
            // color="#112532"
            page={page + 1}
            onChange={(e, value) => setPage(value - 1)}
          />
        </div>
      </TableContainer>
    </TableWrapper>
  );
};
